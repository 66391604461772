var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('location'),_c('tab'),_c('div',{staticClass:"page"},[(_vm.data_yn)?_c('div',{staticClass:"row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 g-lg-4"},_vm._l((_vm.adlist),function(item,index){return _c('div',{key:index,staticClass:"col"},[_c('router-link',{staticClass:"card h-100 rounded-0 view-zoom-in",attrs:{"to":{
            name: 'AdWrite',
            params: {
              adno: item.adno,
            },
            query: {
              adtype: _vm.searchData.adtype,
            },
          }}},[_c('div',{staticClass:"card-body d-flex justify-content-center align-items-center overflow-hidden m-2 p-0"},[_c('img',{staticClass:"rounded-0",attrs:{"src":_vm.ad_img(index)}})]),_c('div',{staticClass:"card-footer p-3 border-0 bg-light"},[_c('h6',{staticClass:"mb-0 font-montserrat text-truncate"},[_vm._v(" "+_vm._s(item.adtitle)+" ")])])])],1)}),0):_c('div',{staticClass:"row g-0 border-top-dgray border-bottom text-center"},[_c('div',{staticClass:"col m-5"},[_vm._v(_vm._s(_vm.$t("text.common.no_data")))])]),(_vm.page_total > 1 && _vm.data_yn)?_c('b-pagination-nav',{attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.page_total,"use-router":"","aria-label":"Pagination","first-class":"prev","prev-class":"prev","next-class":"next","last-class":"next","hide-ellipsis":"","limit":"10"},scopedSlots:_vm._u([{key:"first-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_double_arrow_left")])]},proxy:true},{key:"prev-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("navigate_before")])]},proxy:true},{key:"next-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("navigate_next")])]},proxy:true},{key:"last-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_double_arrow_right")])]},proxy:true},{key:"page",fn:function(ref){
          var page = ref.page;
          var active = ref.active;
return [(active)?_c('a',[_vm._v(_vm._s(page))]):_c('a',[_vm._v(_vm._s(page))])]}}],null,false,3228152692),model:{value:(_vm.searchData.pageno),callback:function ($$v) {_vm.$set(_vm.searchData, "pageno", $$v)},expression:"searchData.pageno"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }