<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <div class="filter-group row">
        <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
          <b-form @submit="onSubmit">
            <div class="d-flex">
              <select
                class="w-auto"
                :aria-label="$t('title.common.choose')"
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="B.fit_brand_nm">
                  {{ $t("title.common.brand_name") }}
                </option>
              </select>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('title.common.choose')"
                  :aria-label="$t('title.common.choose')"
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </b-form>
        </div>
        <!-- <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
          <div class="d-flex">
            <select aria-label="정렬">
              <option value="0" selected>신청일순</option>
              <option value="1">상태순</option>
              <option value="2">제품명순</option>
            </select>
            <select aria-label="목록개수">
              <option value="0" selected>10개 보기</option>
              <option value="1">20개 보기</option>
              <option value="2">30개 보기</option>
            </select>
          </div>
        </div> -->
      </div>

      <table class="table board">
        <thead>
          <tr>
            <th class="col d-lg-table-cell text-center">
              {{ $t("menu.brand.brand") }}
            </th>
            <th class="col d-none d-lg-table-cell text-nowrap">
              {{ $t("page.common.basic_information") }}
            </th>
            <th class="col-1 d-lg-table-cell text-center text-nowrap">
              {{ $t("filter.common.location_count") }}
            </th>
            <th class="col-1 d-none d-lg-table-cell text-nowrap">
              {{ $t("title.location.fitting_count") }}
            </th>
            <th class="col-1 d-none d-lg-table-cell text-nowrap">
              {{ $t("title.location.sales_sum") }}
            </th>
            <th class="th-arrow"></th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in brandlist">
            <tr :key="index">
              <td class="col-auto d-lg-table-cell text-start">
                <router-link
                  :to="{
                    name: 'AdminBrandWrite',
                    params: {
                      brandno: item.brandno,
                    },
                  }"
                  class="d-flex align-items-center"
                >
                  <img
                    :src="item.brandimage | get_img"
                    class="img-thumbnail me-2"
                  />
                  <span class="text-nowrap brand-name">{{
                    item.brandname
                  }}</span>
                </router-link>
              </td>
              <td class="col-auto d-none d-lg-table-cell text-start">
                <div class="row g-2 basic-info">
                  <div class="col-12 text-black">
                    {{ item.managerpart }}
                    <span class="text-black text-opacity-25">|</span>
                    {{ item.managername }}
                  </div>
                  <div class="col-12">
                    <span class="material-icons-outlined">place</span
                    >{{ item.brandaddress }}
                  </div>
                  <div class="col-12">
                    <div class="row row-cols-auto g-3">
                      <div class="col">
                        <span class="material-icons-outlined">phone</span
                        >{{ item.brandphone }}
                      </div>
                      <div class="col">
                        <span class="material-icons-outlined"
                          >phone_android</span
                        >{{ item.brandmobile }}
                      </div>
                      <div class="col">
                        <span class="material-icons-outlined">print</span
                        >{{ item.brandfax }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <span class="material-icons-outlined">email</span
                    >{{ item.brandemail }}
                  </div>
                </div>
              </td>
              <td class="col-auto d-lg-table-cell text-black">
                <h5 class="text-center fw-semibold">
                  {{ item.brandshopcnt
                  }}<small class="text-black">{{ $t("text.common.ea") }}</small>
                </h5>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="text-center fw-semibold text-purple">
                  {{ item.brandfittingcnt
                  }}<small class="text-purple">{{
                    $t("text.common.each")
                  }}</small>
                </h5>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="text-center fw-semibold text-price">
                  {{ item.brandsalesum
                  }}<small class="text-price">{{
                    $t("text.common.unit_1000")
                  }}</small>
                </h5>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="5">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.department") }}</div>
                    <div class="col-8">{{ item.managerpart }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.manager") }}</div>
                    <div class="col-8">{{ item.managername }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.address") }}</div>
                    <div class="col-8">{{ item.brandaddress }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.phone") }}</div>
                    <div class="col-8">{{ item.brandphone }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.mobile_phone") }}
                    </div>
                    <div class="col-8">{{ item.brandmobile }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.location.fax") }}</div>
                    <div class="col-8">{{ item.brandfax }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.location.email") }}</div>
                    <div class="col-8">{{ item.brandemail }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("") }}{{ $t("title.location.fitting_count") }}
                    </div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-purple">
                        {{ item.brandfittingcnt
                        }}<small class="text-purple">{{
                          $t("text.common.each")
                        }}</small>
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.location.sales_sum") }}
                    </div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-price">
                        {{ item.brandsalesum
                        }}<small class="text-price">{{
                          $t("text.common.unit_1000")
                        }}</small>
                      </h5>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1 && data_yn"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
      <div class="row regis-btn">
        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="primary"
            :aria-label="$t('page.brand.brand_write')"
            @click="$router.push({ name: 'AdminBrandWrite' })"
            >{{ $t("page.brand.brand_write") }}</b-button
          >
        </div>
      </div>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/brand";
import "@/filter/common";

export default {
  name: "Brands",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "B.fit_brand_nm",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      brandlist: [],
      rows: 10,
      brandlistcnt: 0,
      view_hide: null,
    };
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.searchname = "";
    },

    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_adminbrandlist();
    },

    "searchData.pageno": function () {
      this.get_adminbrandlist();
    },
  },

  methods: {
    get_adminbrandlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_adminbrandlist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.brandlistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.brandlist = data.brandlist;
          this.brandlistcnt = data.pagination.brandlistcnt;
          this.total_cnt = data.pagination.brandlistcnt;
          this.page_total = Math.ceil(data.pagination.brandlistcnt / this.rows);
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      // this.searchData.pageno = 1;
      // this.$router
      //   .push({
      //     query: {
      //       searchtype: this.searchData.searchtype,
      //       searchname: this.searchData.searchname,
      //     },
      //   })
      //   .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },

    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.searchData.pageno = 1;
    this.get_adminbrandlist();
  },
};
</script>

<style></style>
