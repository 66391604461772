var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-main"},[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: _vm.tab - 1,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab == 0 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '0',
        },on:{"click":function($event){_vm.$router
            .push({
              name: ("" + ('AdList' + _vm.$route.meta.adtype)),
              params: { goodstype: '0' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.total"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 1 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '1',
        },on:{"click":function($event){_vm.$router
            .push({
              name: ("" + ('AdList' + _vm.$route.meta.adtype)),
              params: { goodstype: '1' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.driver"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 2 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '2',
        },on:{"click":function($event){_vm.$router
            .push({
              name: ("" + ('AdList' + _vm.$route.meta.adtype)),
              params: { goodstype: '2' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.wood"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 3 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '3',
        },on:{"click":function($event){_vm.$router
            .push({
              name: ("" + ('AdList' + _vm.$route.meta.adtype)),
              params: { goodstype: '3' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.utility"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 4 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '4',
        },on:{"click":function($event){_vm.$router
            .push({
              name: ("" + ('AdList' + _vm.$route.meta.adtype)),
              params: { goodstype: '4' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.iron"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 5 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '5',
        },on:{"click":function($event){_vm.$router
            .push({
              name: ("" + ('AdList' + _vm.$route.meta.adtype)),
              params: { goodstype: '5' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.wedge"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 6 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '6',
        },on:{"click":function($event){_vm.$router
            .push({
              name: ("" + ('AdList' + _vm.$route.meta.adtype)),
              params: { goodstype: '6' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.putter"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }