<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swiper mySwiper"
    >
      <swiper-slide :class="{ active: tab == 1 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsMemberTotal',
                params: { goodstype: '1' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == '1',
          }"
        >
          {{ $t("button.stats.member_total_count") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 2 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsMemberGender',
                params: { goodstype: '2' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == '2',
          }"
        >
          {{ $t("button.stats.member_gender_count") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 3 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsMemberAge',
                params: { goodstype: '3' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == '3',
          }"
        >
          {{ $t("button.stats.member_age_count") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 4 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsMemberHandicap',
                params: { goodstype: '4' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == '4',
          }"
        >
          {{ $t("button.stats.member_handicap_count") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 5 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsMemberVip',
                params: { goodstype: '5' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == '5',
          }"
        >
          {{ $t("button.stats.member_vip_count") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 6 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsMemberShop',
                params: { goodstype: '6' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == '6',
          }"
        >
          {{ $t("button.stats.member_location_count") }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "statsmembertotalTab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (this.$route.name === "StatsMemberTotal") {
        // alert(this.$route.params.goodstype);
        if (this.$route.params.goodstype) {
          return parseInt(this.$route.params.goodstype);
        } else {
          return 1;
        }
      } else if (this.$route.name === "StatsMemberGender") {
        return 2;
      } else if (this.$route.name === "StatsMemberAge") {
        return 3;
      } else if (this.$route.name === "StatsMemberHandicap") {
        return 4;
      } else if (this.$route.name === "StatsMemberVip") {
        return 5;
      } else if (this.$route.name === "StatsMemberShop") {
        return 6;
      } else {
        return 1;
      }
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>

<style scoped>
@media (max-width: 992px) {
  .swiper-slide div {
    font-size: 16px !important;
  }
}
</style>
