var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-main"},[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: _vm.tab - 1,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab == 1 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsFittingSales',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsFittingSales',
              params: { goodstype: '1' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.fitting_purchase_count"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 2 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsFittingGoods',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsFittingGoods',
              params: { goodstype: '2' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.premium_entry_count"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 3 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsFittingShop',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsFittingShop',
              params: { goodstype: '3' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.location_rank"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }