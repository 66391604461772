<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page">
      <b-form @submit="onSubmit">
        <div class="filter-group row">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                aria-label=""
                v-model="searchData.searchtype"
              >
                <option value="fit_goods_grip_nm">
                  {{ $t("filter.common.goods_name") }}
                </option>
              </select>
              <div class="input-group flex-nowrap">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.input_search')"
                  :aria-label="$t('text.common.input_search')"
                  aria-describedby="button-addon"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="" v-model="searchData.findex">
                <option value="reg_date" selected>
                  {{ $t("filter.common.sort_by_date") }}
                </option>
                <option value="fit_goods_grip_nm">
                  {{ $t("filter.common.sort_by_goods_name") }}
                </option>
              </select>
              <select aria-label="" v-model="searchData.pagesize">
                <option value="20" selected>
                  {{ $t("filter.common.sort_by_20") }}
                </option>
                <option value="40">{{ $t("filter.common.sort_by_40") }}</option>
                <option value="60">{{ $t("filter.common.sort_by_60") }}</option>
              </select>
            </div>
          </div>
        </div>
      </b-form>

      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 g-lg-4"
        v-if="data_yn"
      >
        <div class="col" :key="index" v-for="(item, index) in griplist">
          <router-link
            :to="{
              name: 'GripWrite',
              params: { gripno: item.gripno },
              query: {
                searchtype: searchData.searchtype,
                searchname: searchData.searchname,
              },
            }"
            class="card h-100 rounded-0 view-zoom-in"
          >
            <div
              class="card-body d-flex justify-content-center align-items-center overflow-hidden m-2 py-5"
            >
              <img :src="grip_img(index)" class="rounded-0" alt="Grips" />
            </div>
            <div class="card-footer p-3 border-0 bg-light">
              <h6 class="mb-0 font-montserrat text-truncate">
                {{ item.griptitle }}
              </h6>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row g-0 border-top-dgray border-bottom text-center" v-else>
        <div class="col m-5">{{ $t("text.common.no_data") }}</div>
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class=""
        v-if="page_total > 1 && data_yn"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/goods/Tab";
import ax from "@/api/goods";
import "@/filter/common";

export default {
  name: "gripList",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          20,
        findex:
          this.$route.query.findex ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].findex) ||
          "reg_date",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "fit_goods_grip_nm",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      griplist: [],
      // rows: 10,
      griplistcnt: 0,
    };
  },
  methods: {
    getGriplist() {
      ax.get_griplist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.griplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }

          this.griplist = data.griplist;
          this.griplistcnt = data.pagination.griplistcnt;
          this.total_cnt = data.pagination.griplistcnt;
          this.page_total = Math.ceil(
            data.pagination.griplistcnt / this.searchData.pagesize
          );
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.getGriplist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    grip_img(index) {
      if (
        this.griplist[index].gripimagepath !== null &&
        this.griplist[index].gripimagepath !== ""
      ) {
        return (
          process.env.VUE_APP_S3_BUCKET_URL +
          "/" +
          this.griplist[index].gripimagepath
        );
      } else {
        return "/img/no-img.jpg";
      }
    },
  },
  created() {
    this.getGriplist();
  },
  watch: {
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.getGriplist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.getGriplist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.getGriplist();
    },
    "searchData.pageno": function () {
      this.getGriplist();
    },
  },
};
</script>
