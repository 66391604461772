<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <b-form @submit="onSubmit">
        <div class="filter-group row mb-lg-3">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                aria-label=" "
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="P.nickname">
                  {{ $t("filter.common.nickname") }}
                </option>
                <!-- <option value="2">매장명</option> -->
                <option value="PSI.phone">
                  {{ $t("filter.common.phone_number") }}
                </option>
              </select>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.input_search')"
                  :aria-label="$t('text.common.input_search')"
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </div>

          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="" v-model="searchData.findex">
                <option value="FU.reg_date" selected>
                  {{ $t("filter.common.sort_by_date") }}
                </option>
                <option value="P.nickname">
                  {{ $t("filter.common.sort_by_nickname") }}
                </option>
                <option value="PSI.phone">
                  {{ $t("filter.common.sort_by_phone_number") }}
                </option>
                <option value="fittingcnt">
                  {{ $t("filter.common.sort_by_fitting_count") }}
                </option>
                <option value="buycnt">
                  {{ $t("filter.common.sort_by_purchase_count") }}
                </option>
              </select>
              <select aria-label="" v-model="searchData.pagesize">
                <option value="10" selected>
                  {{ $t("filter.common.sort_by_10") }}
                </option>
                <option value="20">{{ $t("filter.common.sort_by_20") }}</option>
                <option value="30">{{ $t("filter.common.sort_by_30") }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-3 pt-1">
          {{ $t("page.common.total") }}
          <b class="text-primary"> {{ memberlistcnt }}</b>
          {{ $t("text.common.peoples") }}
        </div>
      </b-form>

      <table class="table board last-borderless">
        <thead>
          <tr>
            <th class="col-auto w-1">
              <input
                type="checkbox"
                v-model="allSelected"
                @click="selectAll()"
              />
            </th>
            <th class="col-auto d-none d-lg-table-cell w-1">
              {{ $t("title.common.no") }}
            </th>
            <th class="col-auto d-lg-table-cell text-center">
              {{ $t("title.member.nickname") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.member.gender") }}
            </th>
            <th class="col-auto d-lg-table-cell text-center">
              {{ $t("title.member.phone") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell text-center">
              {{ $t("title.member.location") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.member.fitting_count") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.member.purchase_count") }}
            </th>
            <th class="th-arrow"></th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in memberlist">
            <tr :key="'k' + index">
              <td class="col-auto">
                <input
                  type="checkbox"
                  class="form-check-input custom-checkbox"
                  :value="index"
                  v-model="checkedPlayeridx"
                  @change="updateCheckall()"
                />
              </td>
              <td class="col-auto d-none d-lg-table-cell">{{ item.no }}</td>
              <td class="col-auto d-lg-table-cell text-center">
                <MemberInfo
                  :nickname="item.membernickname"
                  :memberno="item.memberno"
                />
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.membergender | gender }}
              </td>
              <td class="col-auto d-lg-table-cell text-center">
                {{ item.membermobile }}
              </td>
              <td class="col-auto d-none d-lg-table-cell p-0">
                <template v-for="(item1, index) in item.shoplist">
                  <div
                    class="col-auto p-2 border-bottom text-center shop-name"
                    :key="'a' + index"
                  >
                    {{ item1.nickname }}
                  </div>
                </template>
              </td>
              <td class="col-auto d-none d-lg-table-cell p-0">
                <template v-for="(item1, index) in item.shoplist">
                  <div
                    class="col-auto p-2 border-bottom text-center"
                    :key="'b' + index"
                  >
                    <h5 class="fw-semibold text-purple">
                      {{ item1.memberfittingcnt | comma("-") }}
                      <small
                        class="text-purple"
                        v-if="item1.memberfittingcnt"
                        >{{ $t("text.common.cnt") }}</small
                      >
                    </h5>
                  </div>
                </template>
              </td>
              <td class="col-auto d-none d-lg-table-cell p-0">
                <template v-for="(item1, index) in item.shoplist">
                  <div
                    class="col-auto p-2 border-bottom text-center"
                    :key="'c' + index"
                  >
                    <h5 class="fw-semibold text-orange">
                      {{ item1.membersalecnt | comma("-") }}
                      <small class="text-orange" v-if="item1.membersalecnt">{{
                        $t("text.common.cnt")
                      }}</small>
                    </h5>
                  </div>
                </template>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="8" class="p-0">
                <div class="toggle-content">
                  <table class="table table-sm border-0">
                    <tr>
                      <th class="text-black">{{ $t("title.common.no") }}</th>
                      <td>{{ item.no }}</td>
                      <th class="text-black">
                        {{ $t("title.member.gender") }}
                      </th>
                      <td>{{ item.membergender | gender }}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="p-0">
                        <template v-for="(item1, index) in item.shoplist">
                          <div class="card p-0" :key="'s' + index">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3">
                                  {{ $t("title.member.location") }}
                                </div>
                                <div class="col-9 ps-0 shop-name">
                                  {{ item1.nickname }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  {{ $t("title.member.fitting_count") }}
                                </div>
                                <div class="col ps-0">
                                  <h5 class="fw-semibold text-purple">
                                    {{ item1.memberfittingcnt | comma("-")
                                    }}<small
                                      class="text-purple"
                                      v-if="item1.memberfittingcnt"
                                    >
                                      {{ $t("text.common.each") }}</small
                                    >
                                  </h5>
                                </div>
                                <div class="col fw-bold text-black">
                                  {{ $t("title.member.purchase_count") }}
                                </div>
                                <div class="col">
                                  <h5 class="fw-semibold text-orange">
                                    {{ item1.membersalecnt | comma("-")
                                    }}<small
                                      class="text-orange"
                                      v-if="item1.membersalecnt"
                                    >
                                      {{ $t("text.common.each") }}</small
                                    >
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">{{ $t("text.common.no_data") }}</td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="primary"
          :aria-label="$t('button.common.mail_send')"
          @click="$bvModal.show('modal-email-member')"
          >{{ $t("button.common.mail_send") }}</b-button
        >
      </div>

      <b-modal
        id="modal-email-member"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("button.common.mail_send") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <div class="row g-0 table-type header-col border-1">
            <div class="col-sm-3">
              <span class="required">{{ $t("title.location.sender") }}</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('alert.common.input_sender')"
                readonly
                :value="checkedManagerEmail"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">{{ $t("title.location.receiver") }}</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('alert.common.input_receiver')"
                :value="checkedPlayerEmail"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">{{ $t("title.location.title") }}</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('alert.common.enter_title')"
                v-model="sendData.title"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">{{ $t("title.common.content") }}</span>
            </div>
            <div class="col-sm-9">
              <b-form-textarea
                :placeholder="$t('alert.common.enter_content')"
                rows="10"
                v-model="sendData.content"
              ></b-form-textarea>
            </div>
          </div>
          <p class="mt-3 small lh-sm text-start text-lightgray">
            {{ $t("alert.common.receive_email_information") }}
          </p>
        </template>

        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.cancel')"
            @click="$bvModal.hide('modal-email-member')"
          >
            {{ $t("button.common.cancel") }}
          </b-button>
          <b-button
            size="md"
            variant="secondary"
            :aria-label="$t('button.common.mail_send')"
            @click="sendEmail"
          >
            {{ $t("button.common.mail_send") }}
          </b-button>
        </template>
      </b-modal>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/member";
import "@/filter/common";

export default {
  name: "Members",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "P.nickname",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        findex:
          this.$route.query.findex ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].findex) ||
          "FU.reg_date",
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          10,
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      memberlist: [],
      rows: 10,
      memberlistcnt: 0,
      checkedPlayeridx: [],
      allSelected: false,
      checkedPlayerEmail: [],
      checkedManagerEmail: "",
      sendData: {
        title: "",
        content: "",
        memberno: [],
        email: [],
      },
      view_hide: null,
    };
  },
  methods: {
    getMemberlist() {
      ax.get_memberlist(this.searchData, (flag, data) => {
        if (flag) {
          console.log("data.memberlist", data.memberlist);
          this.memberlist = data.memberlist;
          this.memberlistcnt = data.pagination.memberlistcnt;
          this.total_cnt = data.pagination.memberlistcnt;
          this.page_total = Math.ceil(
            data.pagination.memberlistcnt / this.rows
          );
          // alert(data.pagination.memberlistcnt);
          for (let i = 0; i < data.pagination.memberlistcnt; i++) {
            for (let j = 0; j < data.memberlist[i].shoplist.length; j++) {
              // alert(data.memberlist[i].shoplist[0].shopname);
              this.memberlist[i].shoplist[j] = data.memberlist[i].shoplist[j];
              // alert(this.memberlist[i].shoplist[0].shopname);
              // this.memberlist.shoplist[i] = data.memberlist[i].shoplist;
            }
          }
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.getMemberlist();
      // this.$router
      //   .push({
      //     query: {
      //       searchtype: this.searchData.searchtype,
      //       searchname: this.searchData.searchname,
      //       pagesize: this.searchData.pagesize,
      //       findex: this.searchData.findex,
      //       brandno: this.searchData.brandno,
      //     },
      //   })
      //   .catch(() => {});
    },
    selectAll() {
      this.checkedPlayeridx = [];
      this.checkedPlayerEmail = [];
      // alert(this.allSelected);
      if (!this.allSelected) {
        for (let i = 0; i < this.memberlist.length; i++) {
          //if (this.searchData.pagesize > i) {
          this.checkedPlayeridx.push(i);
          this.checkedPlayerEmail.push(
            this.memberlist[this.checkedPlayeridx[i]].email
          );
          //}
        }
      }
    },
    updateCheckall() {
      // this.allSelected = false;
      if (this.memberlist.length == this.checkedPlayeridx.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    memberEmailSend() {
      if (this.checkedPlayeridx.length < 1) {
        /*this.$notify({
          group: 'alert',
          type: 'info',
          title: '정보',
          text: '발송하려는 회원을 선택해주세요.'
        })*/
        alert(this.$i18n.t("alert.common.select_member_to_send"));
      } else {
        this.checkedPlayerEmail = [];
        for (let i = 0; i < this.checkedPlayeridx.length; i++) {
          this.checkedPlayerEmail.push(
            this.memberlist[this.checkedPlayeridx[i]].email
          );
        }

        this.$bvModal.show("modal-email-member");
      }
    },
    sendEmail() {
      this.sendData.senderemail = this.checkedManagerEmail;
      this.sendData.email = [];
      this.sendData.memberno = [];
      for (let i = 0; i < this.checkedPlayeridx.length; i++) {
        this.sendData.email.push(
          this.memberlist[this.checkedPlayeridx[i]].email
        );
        this.sendData.memberno.push(
          this.memberlist[this.checkedPlayeridx[i]].memberno
        );
      }
      this.sendData.emailcnt = this.checkedPlayeridx.length;

      if (this.sendData.title.length < 2 || this.sendData.content.length < 2) {
        /*this.$notify({
          group: 'alert',
          type: 'info',
          title: '정보',
          text: '제목 과 내용을 입력해주세요.'
        })*/
        alert(this.$i18n.t("alert.common.input_title_contents"));
        return false;
      } else {
        ax.sendEmail(this.sendData, (flag, response) => {
          if (flag) {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '이메일이 발송 되었습니다.'
            })*/
            alert(response.message);
          } else {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
            alert(response.message);
          }
        });
      }
    },
  },
  created() {
    this.getMemberlist();
    ax.get_brandview((flag, data) => {
      if (flag) {
        this.checkedManagerEmail = data.brandinfo.email;
      } else {
        alert(data);
      }
    });
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.searchname = "";
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.getMemberlist();
    },

    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.getMemberlist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.getMemberlist();
    },
    "searchData.pageno": function () {
      this.getMemberlist();
    },
  },
};
</script>
