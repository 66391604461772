<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                type="year"
                :placeholder="$t('title.common.choose')"
                :aria-label="$t('title.common.choose')"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              {{ $t("button.stats.total_sales_sum") }}
              <span class="align-self-center ms-auto price-unit"
                >({{ $t("text.common.unit") }} :
                {{ $t("text.common.unit_1000") }})</span
              >
            </div>
            <div class="card-body">
              <GChart
                type="LineChart"
                :data="chartsData.saletotalData"
                :options="chartsData.saletotalOptions"
              />
            </div>
          </div>

          <div class="row g-4">
            <div class="col-lg">
              <div class="card h-100 rounded-0">
                <div
                  class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
                >
                  {{ $t("button.stats.sales_sum_by_club") }}
                  <span class="align-self-center ms-auto price-unit"
                    >({{ $t("text.common.unit") }} :
                    {{ $t("text.common.unit_1000") }})</span
                  >
                </div>

                <div class="card-body d-flex">
                  <GChart
                    type="LineChart"
                    :data="chartsData.saleclubData"
                    :options="chartsData.saleclubOptions"
                    class="align-self-center w-100"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg">
              <div class="card h-100 rounded-0">
                <div class="card-header d-flex align-items-center rounded-0">
                  <span class="lh-base fw-semibold text-black">{{
                    $t("button.stats.sales_sum_by_goods_type")
                  }}</span>
                  <select
                    class="text-type ms-2 border"
                    :aria-label="$t('title.common.choose')"
                    v-model="chartsData.goodstype"
                  >
                    <option :value="$t('filter.common.driver')">
                      {{ $t("filter.common.driver") }}
                    </option>
                    <option :value="$t('filter.common.wood')">
                      {{ $t("filter.common.wood") }}
                    </option>
                    <option :value="$t('filter.common.utility')">
                      {{ $t("filter.common.utility") }}
                    </option>
                    <option :value="$t('filter.common.iron')">
                      {{ $t("filter.common.iron") }}
                    </option>
                    <option :value="$t('filter.common.wedge')">
                      {{ $t("filter.common.wedge") }}
                    </option>
                    <option :value="$t('filter.common.putter')">
                      {{ $t("filter.common.putter") }}
                    </option>
                  </select>
                  <span class="align-self-center ms-auto price-unit"
                    >({{ $t("text.common.unit") }} :
                    {{ $t("text.common.unit_1000") }})</span
                  >
                </div>
                <div class="card-body d-flex">
                  <GChart
                    type="PieChart"
                    :data="chartsData.salepieData"
                    :options="chartsData.salepieOptions"
                    class="align-self-center w-100"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg">
              <div class="card h-100 rounded-0">
                <div
                  class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
                >
                  {{ $t("button.stats.sales_sum_by_location") }}
                  {{ $t("filter.common.top_5") }}
                  <span class="align-self-center ms-auto price-unit"
                    >({{ $t("text.common.unit") }} :
                    {{ $t("text.common.unit_1000") }})</span
                  >
                </div>
                <div class="card-body">
                  <div
                    class="row g-0 table-type header-col border-1 fs-14"
                    v-if="Object.keys(statsinfo.saleshop).length"
                  >
                    <template v-for="(val, index) in statsinfo.saleshop">
                      <div class="col-sm-6 shop-name" :key="'a' + index">
                        {{ val.shopname }}
                      </div>
                      <div class="col-sm-6 text-price" :key="'b' + index">
                        &#8361; {{ val.salesum | comma }}
                      </div>
                    </template>
                  </div>
                  <div class="row g-0 text-center" v-else>
                    <div class="col m-5">{{ $t("text.common.no_data") }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmainTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";
export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype: "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      chartsData: {
        saletotalData: [],
        saletotalOptions: {
          chart: {
            title: this.$i18n.t("button.stats.total_sales_sum"),
          },

          legend: { position: "bottom" },
        },
        saleclubData: [],
        saleclubOptions: {
          chart: {
            title: this.$i18n.t("button.stats.sales_sum_by_club"),
          },

          chartArea: { width: "85%", right: 0 },
          legend: { position: "top", maxLines: 3 },
        },
        salepieData: [],
        goodstype: this.$i18n.t("filter.common.driver"),
        salepieOptions: {
          chart: {
            title: this.$i18n.t("button.stats.sales_sum_by_goods_type"),
          },
          legend: "none",
          pieSliceText: "label",
          chartArea: { height: "100%" },
        },
      },
      statsinfo: { saleclub: {}, salegoods: {}, saleshop: {}, saletotal: {} },
      view_hide: null,
    };
  },
  methods: {
    get_statslist() {
      (this.searchData.goodstype =
        this.$route.params.goodstype ||
        (this.$store.state.searchData[this.$route.matched[0].name] &&
          this.$store.state.searchData[this.$route.matched[0].name]
            .goodstype) ||
        1),
        this.$store.commit("setSearchData", {
          [this.$route.matched[0].name]: this.searchData,
        });

      ax.get_statslist(this.searchData, (flag, data = "") => {
        this.statsinfo = data.statsinfo;
        this.chartsData.saletotalData = [
          [
            this.$i18n.t("title.common.period"),
            this.$i18n.t("title.common.sales_sum"),
          ],
        ];
        Object.keys(this.statsinfo.saletotal)
          .sort((a, b) => {
            return new Date(a) - new Date(b);
          })
          .forEach((k) => {
            let datekey =
              parseInt(k.substring(k.length - 2, k.length)) +
              this.$i18n.t("text.common.month");
            this.chartsData.saletotalData.push([
              datekey,
              parseInt(this.statsinfo.saletotal[k].salesum) || 0,
            ]);
          });

        this.chartsData.saleclubData = [
          [
            this.$i18n.t("title.common.period"),
            this.$i18n.t("title.common.driver"),
            this.$i18n.t("title.common.wood"),
            this.$i18n.t("title.common.utility"),
            this.$i18n.t("title.common.iron"),
            this.$i18n.t("title.common.wedge"),
            this.$i18n.t("title.common.putter"),
          ],
        ];
        Object.keys(this.statsinfo.saleclub)
          .sort((a, b) => {
            return new Date(a) - new Date(b);
          })
          .forEach((k) => {
            this.chartsData.saleclubData.push([
              parseInt(k.substring(k.length - 2, k.length)) + "",
              (this.statsinfo.saleclub[k][
                this.$i18n.t("title.common.driver")
              ] &&
                parseInt(
                  this.statsinfo.saleclub[k][
                    this.$i18n.t("title.common.driver")
                  ].salesum
                )) ||
                0,
              (this.statsinfo.saleclub[k][this.$i18n.t("title.common.wood")] &&
                parseInt(
                  this.statsinfo.saleclub[k][this.$i18n.t("title.common.wood")]
                    .salesum
                )) ||
                0,
              (this.statsinfo.saleclub[k][
                this.$i18n.t("title.common.utility")
              ] &&
                parseInt(
                  this.statsinfo.saleclub[k][
                    this.$i18n.t("title.common.utility")
                  ].salesum
                )) ||
                0,
              (this.statsinfo.saleclub[k][this.$i18n.t("title.common.iron")] &&
                parseInt(
                  this.statsinfo.saleclub[k][this.$i18n.t("title.common.iron")]
                    .salesum
                )) ||
                0,
              (this.statsinfo.saleclub[k][this.$i18n.t("title.common.wedge")] &&
                parseInt(
                  this.statsinfo.saleclub[k][this.$i18n.t("title.common.wedge")]
                    .salesum
                )) ||
                0,
              (this.statsinfo.saleclub[k][
                this.$i18n.t("title.common.putter")
              ] &&
                parseInt(
                  this.statsinfo.saleclub[k][
                    this.$i18n.t("title.common.putter")
                  ].salesum
                )) ||
                0,
            ]);
          });

        this.chartsData.salepieData = [
          [
            this.$i18n.t("button.stats.goods_type"),
            this.$i18n.t("title.common.sales_sum"),
          ],
        ];
        if (this.statsinfo.salegoods[this.chartsData.goodstype]) {
          for (let v of this.statsinfo.salegoods[this.chartsData.goodstype]) {
            this.chartsData.salepieData.push([
              v.goodsname,
              parseInt(v.salesum) || 0,
            ]);
          }
        }

        console.log(this.chartsData.salepieData);
        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });
      this.get_salelist();
    },
  },
  created() {
    this.get_statslist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_statslist();
      },
      deep: true,
    },

    "chartsData.goodstype": function () {
      this.chartsData.salepieData = [
        [
          this.$i18n.t("button.stats.goods_type"),
          this.$i18n.t("title.common.sales_sum"),
        ],
      ];
      if (this.statsinfo.salegoods[this.chartsData.goodstype]) {
        for (let v of this.statsinfo.salegoods[this.chartsData.goodstype]) {
          this.chartsData.salepieData.push([
            v.goodsname,
            parseInt(v.salesum) || 0,
          ]);
        }
      }
    },
  },
};
</script>
