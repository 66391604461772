<template>
  <div class="container-fluid">
    <location />

    <div class="page account-mng">
      <div class="d-flex flex-fill justify-content-center mt-5">
        <b-form @submit="onSubmit" class="text-center">
          <div class="d-flex flex-column mb-4">
            <div class="d-flex justify-content-center">
              <p class="icon-box rounded-circle bg-green">
                <span class="material-icons-round text-white">vpn_key</span>
              </p>
            </div>
            <h5 class="my-4">{{ $t("page.membership.confirm_password") }}</h5>
            <p>{{ $t("text.common.security_password") }}</p>
          </div>
          <input
            type="password"
            class="form-control form-control-lg mb-2"
            :placeholder="$t('text.common.careful_password')"
            aria-label=""
            v-model="password"
          />
          <input
            class="btn btn-primary btn-lg w-100 mb-4"
            type="submit"
            :value="$t('button.common.confirm')"
          />
          <p class="small text-lightgray text-start lh-sm">
            {{ $t("text.common.check_letter_8_30_s") }}
          </p>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/account";

export default {
  name: "BrandPwdCheck",
  components: {
    location,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.password === "") {
        alert(this.$i18n.t("alert.common.check_password"));
        return false;
      }

      ax.post_brandpwdcheck(this.password, (flag, data, message) => {
        if (flag) {
          // alert(message);
          this.$router.push({
            name: "BrandWrite",
            params: { password_yn: "y" },
          });
        } else {
          alert(message);
          return false;
        }
      });
    },
  },
};
</script>