<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-12 col-sm-4 mb-2 mb-sm-0">
              <select :aria-label="$t('title.common.choose')" v-model="searchData.forder">
                <option value="">{{ $t("filter.common.total") }}</option>
                <option value="DESC">{{ $t("filter.common.top_5") }}</option>
                <option value="ASC">{{ $t("filter.common.lower_5") }}</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <select :aria-label="$t('title.common.choose')" v-model="searchData.datetype">
                <option value="year">{{ $t("filter.common.yearly") }}</option>
                <option value="month">{{ $t("filter.common.monthly") }}</option>
                <option value="day">{{ $t("filter.common.daily") }}</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :disabled="searchData.datetype == 'year'"
                :type="searchData.datetype == 'month' ? 'year' : searchData.datetype == 'year' ? '' : 'month'"
                :placeholder="$t('title.common.choose')"
                :aria-label="$t('title.common.choose')"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              {{ $t("button.stats.sales_sum_by_location") }}
              <span class="align-self-center ms-auto price-unit"
                >({{ $t("text.common.unit") }} : {{ $t("text.common.unit_1000") }})</span
              >
            </div>
            <div class="card-body">
              <GChart
                :type="chartsData.chartsType"
                :data="chartsData.saleshopData"
                :options="chartsData.saleshopOptions"
              />
            </div>
          </div>
          <div class="query-block sort-by gap-3 my-1 my-sm-0">
            <div
              :class="{ active: searchData.findex == 'salesum' }"
              @click="
                searchData.findex = 'salesum';
                searchData.pageno = 1;
              "
              v-if="!searchData.forder"
            >
              {{ $t("filter.common.sort_by_sales_sum") }}<span class="material-icons-outlined"
                >arrow_drop_down</span
              >
            </div>
            <div
              :class="{ active: searchData.findex == 'shopname' }"
              @click="
                searchData.findex = 'shopname';
                searchData.pageno = 1;
              "
              v-if="!searchData.forder"
            >
              {{ $t("filter.common.sort_by_location_name") }}<span class="material-icons-outlined"
                >arrow_drop_down</span
              >
            </div>
            <div class="ms-auto fw-normal text-normal">
              {{ $t("button.common.total") }} {{ $t("menu.location.location") }}<b class="text-primary ls-n1px">
                {{ shopcount | comma }}</b
              >{{ $t("text.common.ea") }}
            </div>
          </div>
          <b-table-simple responsive table-class="tfoot-type">
            <b-thead>
              <b-tr
                v-for="(val, key) in Object.entries(
                  saleData.saleshoplist
                ).slice(0, 1)"
                :key="'a' + key"
              >
                <b-th sticky-column>{{ $t("title.member.location") }}</b-th>
                <b-th v-for="(val_, key_) in val[1]" :key="'a' + key_">
                  {{ val_[0] }}
                </b-th>
                <b-th>{{ $t("title.common.sum") }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="Object.keys(saleData.saleshoplist).length">
              <b-tr
                v-for="(val, key) in saleData.saleshoplist"
                :key="'b' + key"
              >
                <b-td sticky-column class="shop-name">
                  {{ key }}
                </b-td>
                <b-td
                  v-for="(val_, key_) in val"
                  :key="'b' + key_"
                  class="text-price"
                >
                  {{ val_[1] && val_[1].salesum | comma("-") }}
                  <!-- <span
                    v-if="val_[1]"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[key] &&
                    saleData.total_salesum[key].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum[key]"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="fw-normal bg-white">{{ $t("text.common.no_data") }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Pagination"
            class="mt-3"
            v-if="page_total > 1 && !searchData.forder"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmainTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";

export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        forder: "",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
        pagesize: 10,
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        findex: "salesum",
      },
      chartsData: {
        chartsType: "PieChart",
        saleshopData: [],
        saleshopOptions: {
          legend: { position: "bottom" },
          hAxis: { showTextEvery: "1", textPosition: "out" },
        },
      },
      page_total: 1,
      shopcount: 0,
      saleData: { saleshopinfo: [], saleshoplist: {}, total_salesum: {} },
      view_hide: null,
    };
  },
  methods: {
    get_saleshoplist() {
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });

      ax.get_saleshoplist(this.searchData, (flag, data = "") => {
        this.saleData.total_salesum = data.total_salesum;
        this.shopcount = data.shopcount;

        this.page_total = Math.ceil(data.total_rows / this.searchData.pagesize);
        this.saleData.saleshoplist = {};
        this.chartsData.saleshopData = [[this.$i18n.t("title.member.location"), this.$i18n.t("title.common.sales_sum")]];
        this.chartsData.chartsType = "PieChart";

        Object.keys(data.saleshoplist).forEach((k) => {
          let item = [];
          Object.keys(data.saleshoplist[k])
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .forEach((_k) => {
              let datekey = _k;
              if (this.searchData.datetype === "year")
                datekey =
                  parseInt(_k.substring(_k.length - 4, _k.length)) + this.$i18n.t("text.common.year");
              if (this.searchData.datetype === "month")
                datekey =
                  parseInt(_k.substring(_k.length - 2, _k.length)) + this.$i18n.t("text.common.month");
              if (this.searchData.datetype === "day")
                datekey =
                  parseInt(_k.substring(_k.length - 2, _k.length)) + this.$i18n.t("text.common.day");

              item.push([datekey, data.saleshoplist[k][_k] || ""]);
            });

          this.saleData.saleshoplist[k] = item;
        });
        console.log("saleshoplist", this.saleData.saleshoplist);

        if (this.searchData.forder) {
          if (Object.keys(data.saleshoplist).length) {
            let _key = Object.keys(data.saleshoplist).filter(
              (k) => k !== this.$i18n.t("title.common.sum")
            );
            this.chartsData.chartsType = "LineChart";

            this.chartsData.saleshopData = [[this.$i18n.t("title.common.period"), ..._key]];
            Object.keys(data.saleshopinfo)
              .sort((a, b) => {
                return new Date(a) - new Date(b);
              })
              .forEach((k) => {
                let datekey = k;
                if (this.searchData.datetype === "year")
                  datekey =
                    parseInt(k.substring(k.length - 4, k.length)) + this.$i18n.t("text.common.year");
                if (this.searchData.datetype === "month")
                  datekey =
                    parseInt(k.substring(k.length - 2, k.length)) + this.$i18n.t("text.common.month");
                if (this.searchData.datetype === "day")
                  datekey =
                    parseInt(k.substring(k.length - 2, k.length)) + this.$i18n.t("text.common.day");

                this.chartsData.saleshopData.push([
                  datekey,
                  ..._key.map((v) =>
                    data.saleshopinfo[k][v] ? data.saleshopinfo[k][v] : 0
                  ),
                ]);
              });
          }
        } else {
          if (Object.keys(data.saleshopinfo).length) {
            this.chartsData.saleshopData = [[this.$i18n.t("title.member.location"), this.$i18n.t("title.common.sales_sum")]];
            this.chartsData.chartsType = "ColumnChart";

            for (let v in data.saleshopinfo) {
              this.chartsData.saleshopData.push([
                v,
                parseInt(data.saleshopinfo[v]) || 0,
              ]);
            }
          }
        }

        console.log("saleshopData", this.chartsData.saleshopData);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });
      this.get_saleshoplist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
  },
  created() {
    this.get_saleshoplist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_saleshoplist();
      },
      deep: true,
    },
  },
};
</script>
