var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-main"},[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: _vm.tab - 1,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab == 1 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsMain',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsMain',
              params: { goodstype: '1' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.main"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 2 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsSaleTotal',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsSaleTotal',
              params: { goodstype: '2' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.total_sales_sum"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 3 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsSaleClub',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsSaleClub',
              params: { goodstype: '3' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.sales_sum_by_club"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 4 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsSaleModel',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsSaleModel',
              params: { goodstype: '4' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.sales_sum_by_goods_type"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 5 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype == 'StatsSaleShop',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'StatsSaleShop',
              params: { goodstype: '5' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.stats.sales_sum_by_location"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }