<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-12 col-sm-4 mb-2 mb-sm-0">
              <select :aria-label="$t('title.common.choose')" v-model="searchData.findex">
                <option value="1">{{ $t("filter.common.sales_sum") }}</option>
                <option value="2">{{ $t("filter.common.sales_count") }}</option>
                <option value="3">{{ $t("filter.common.fitting_count") }}</option>
                <option value="4">{{ $t("filter.common.member_count") }}</option>                
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <select :aria-label="$t('title.common.choose')" v-model="searchData.datetype">
                <option value="year">{{ $t("filter.common.yearly") }}</option>
                <option value="month">{{ $t("filter.common.monthly") }}</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :type="searchData.datetype"
                :placeholder="$t('title.common.choose')"
                :aria-label="$t('title.common.choose')"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div
            class="row justify-content-center top-rank row-cols-1 row-cols-sm-3 g-3 g-lg-4 mb-4"
          >
            <div
              class="col"
              v-for="(item, index) in statsinfo.slice(0, 3)"
              :key="'a' + index"
            >
              <div
                class="card h-100 flex-row flex-sm-column flex-lg-row rounded-0 border-0"
              >
                <div class="col-3 col-sm-12 col-lg-3 img-wrapper">
                  <div class="badge">{{ item.no }}<span>{{ $t("text.common.unit_grade") }}</span></div>
                  <img
                    :src="VUE_APP_S3_BUCKET_URL + '/' + item.filename"
                    v-if="item.filename"
                  />
                </div>
                <div class="col-9 col-sm-12 col-lg-9 card-body border border-1">
                  <h6 class="card-title shop-name">
                    <MemberInfo
                      :nickname="item.nickname"
                      :memberno="item.player_pk"
                    />

                    <p class="mt-1">{{ item.shopname }}</p>
                  </h6>
                  <h5 class="fw-semibold text-price">
                    {{ item.salesum | comma("-")
                    }}<small v-if="item.salesum" class="text-price">{{ $t("text.common.unit_1000") }}</small>
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <table class="table board">
            <thead>
              <tr>                
                <th class="col-auto col-lg-1 d-lg-table-cell">{{ $t("title.common.rank") }}</th>
                <th class="col-auto d-lg-table-cell">{{ $t("title.common.nickname") }}</th>
                <th class="col-auto d-none d-lg-table-cell">{{ $t("filter.common.sales_sum") }}</th>
                <th class="col-auto d-none d-lg-table-cell">{{ $t("filter.common.sales_count") }}</th>
                <th class="col-auto d-none d-lg-table-cell">{{ $t("filter.common.fitting_count") }}</th>
                <th class="col-auto d-none d-lg-table-cell">{{ $t("filter.common.playing_location_count") }}</th>
                <th class="col-auto d-none d-lg-table-cell">{{ $t("title.member.favorite_location") }}</th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="statsinfo.length">
              <template v-for="(item, index) in statsinfo">
                <tr :key="index">
                  <td class="col-auto d-lg-table-cell">
                    {{ item.no }}
                  </td>
                  <td class="col-auto d-lg-table-cell">
                    <MemberInfo
                      :nickname="item.nickname"
                      :memberno="item.player_pk"
                    />
                  </td>
                  <td class="col-auto d-lg-table-cell">
                    <h5 class="fw-semibold text-price text-center">
                      {{ item.salesum | comma("-")
                      }}<small v-if="item.salesum" class="text-price"
                        >{{ $t("text.common.unit_1000") }}</small
                      >
                    </h5>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <h5 class="fw-semibold text-orange">
                      {{ item.salecnt | comma("-")
                      }}<small class="text-orange">{{ $t("text.common.each") }}</small>
                    </h5>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <h5 class="fw-semibold text-purple">
                      {{ item.fittingcnt | comma("-")
                      }}<small class="text-purple">{{ $t("text.common.each") }}</small>
                    </h5>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <h5 class="fw-semibold text-primary">
                      {{ item.shopcnt | comma("-")
                      }}<small class="text-primary">{{ $t("text.common.ea") }}</small>
                    </h5>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell shop-name">
                    {{ item.shopname }}
                  </td>
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td colspan="7">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-4 align-self-center">{{ $t("filter.common.sales_count") }}</div>
                        <div class="col-8">
                          <h5 class="fw-semibold text-orange">
                            {{ item.salecnt | comma("-")
                            }}<small class="text-orange">{{ $t("text.common.each") }}</small>
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 align-self-center">{{ $t("filter.common.fitting_count") }}</div>
                        <div class="col-8">
                          <h5 class="fw-semibold text-purple">
                            {{ item.fittingcnt | comma("-")
                            }}<small class="text-purple">{{ $t("text.common.each") }}</small>
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 align-self-center">{{ $t("filter.common.playing_location_count") }}</div>
                        <div class="col-8">
                          <h5 class="fw-semibold text-primary">
                            {{ item.shopcnt | comma("-")
                            }}<small class="text-primary">{{ $t("text.common.ea") }}</small>
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 align-self-center">{{ $t("title.member.favorite_location") }}</div>
                        <div class="col-8 shop-name">
                          {{ item.shopname }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="text-center">{{ $t("text.common.no_data") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmembertotalTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";

export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        findex: "1",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      VUE_APP_S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
      statsinfo: [],
      view_hide: null,
    };
  },
  methods: {
    get_memberviplist() {
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });

      ax.get_memberviplist(this.searchData, (flag, data = "") => {
        this.statsinfo = data.statsinfo;

        console.log("statsinfo", this.statsinfo);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });
      this.get_memberviplist();
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_memberviplist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_memberviplist();
      },
      deep: true,
    },
  },
};
</script>
