<template>
  <div class="container-fluid">
    <location />
    <div class="page reg-spec">
      <b-form @submit="onSubmit">
        <button
          class="btn btn-xs ps-0 mt-lg-1 mb-3"
          type="button"
          @click="shaftDelete()"
          v-if="shaftno"
        >
          <span class="icon-delete-bin"></span>
          <span class="d-inline">&nbsp;{{ $t("button.common.delete") }}</span>
        </button>

        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th sticky-column class="col-2 p-3">
                <span class="required">{{ $t("title.common.choose") }}</span>
              </b-th>
              <b-th class="col-2" v-for="(item, index) in 5" :key="index">
                <input
                  type="checkbox"
                  name="checklist"
                  id="checklist"
                  class="m-0"
                  v-model="form.checklist[index]"
                  value="true"
                />
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th sticky-column>
                <span class="required">{{ $t("title.common.flex") }}</span>
              </b-th>
              <b-td v-for="(item, index) in 5" :key="index">
                <select
                  :placeholder="$t('title.common.flex')"
                  name="shaftflextype"
                  v-model="form.shaftflextype[index]"
                  :disabled="form.checklist[index] !== true"
                >
                  <option value="undefined">
                    {{ $t("title.common.choose") }}
                  </option>
                  <option value="1">R</option>
                  <option value="2">SR</option>
                  <option value="3">S</option>
                  <option value="4">L</option>
                </select>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>
                <span class="required"
                  >{{ $t("title.common.weight")
                  }}<small class="fw-normal">(g)</small></span
                >
              </b-th>
              <b-td v-for="(item, index) in 5" :key="index">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.weight')"
                  name="shaftweight"
                  id="shaftweight"
                  v-model="form.shaftweight[index]"
                  required
                  :disabled="form.checklist[index] !== true"
                ></b-form-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>
                <span class="required"
                  >{{ $t("title.common.torque")
                  }}<small class="fw-normal">(&deg;)</small></span
                >
              </b-th>
              <b-td v-for="(item, index) in 5" :key="index">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.torque')"
                  name="shafttorque"
                  id="shafttorque"
                  v-model="form.shafttorque[index]"
                  required
                  :disabled="form.checklist[index] !== true"
                ></b-form-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>
                <span class="required">{{ $t("title.common.kick_point") }}</span>
              </b-th>
              <b-td v-for="(item, index) in 5" :key="index">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.kick_point')"
                  name="shaftkickpoint"
                  id="shaftkickpoint"
                  v-model="form.shaftkickpoint[index]"
                  required
                  :disabled="form.checklist[index] !== true"
                ></b-form-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>
                <span class="required">{{
                  $t("title.common.goods_name")
                }}</span>
              </b-th>
              <b-td colspan="5">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.goods_name')"
                  name="shafttitle"
                  id="shafttitle"
                  v-model="form.shafttitle"
                  required
                ></b-form-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>
                <span class="required">{{ $t("title.common.material") }}</span>
              </b-th>
              <b-td colspan="5">
                <select
                  name="shaftmaterial"
                  id="shaftmaterial"
                  v-model="form.shaftmaterial"
                >
                  <option value="1">{{ $t("text.common.graphite") }}</option>
                  <option value="2">{{ $t("text.common.steel") }}</option>
                  <option value="3">{{ $t("text.common.carbon") }}</option>
                </select>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>
                <span class="required">{{
                  $t("title.common.basic_image")
                }}</span>
              </b-th>
              <b-td class="w-auto text-start" colspan="5">
                <b-form-file
                  v-model="file1"
                  ref="file1"
                  :state="Boolean(file1)"
                  :placeholder="$t('text.common.file_select')"
                  :drop-placeholder="$t('text.common.file_select')"
                  @change="fileSelect"
                >
                </b-form-file>
                <p
                  class="mt-2 text-subscript text-truncate more"
                  v-if="form.shaftimagetxt"
                  @click="$bvModal.show('modal-img-thumbnail')"
                >
                  {{ form.shaftimagetxt }}
                </p>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column class="border-bottom-0">
                <span class="required"
                  >{{ $t("title.common.goods_price")
                  }}<small class="fw-normal text-price"
                    >({{ $t("text.common.unit_won") }})</small
                  ></span
                >
              </b-th>
              <b-td colspan="5">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.goods_price')"
                  name="shaftprice"
                  id="shaftprice"
                  v-model="form.shaftprice"
                  required
                ></b-form-input>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            :aria-label="$t('button.common.cancel')"
            @click="$router.go(-1)"
            >{{ $t("button.common.cancel") }}</b-button
          >
          <b-button
            type="submit"
            size="xl"
            variant="primary"
            :aria-label="$t('button.common.write')"
          >
            {{
              shaftno ? $t("button.common.edit") : $t("button.common.write")
            }}</b-button
          >
        </div>
      </b-form>

      <b-modal
        id="modal-img-thumbnail"
        size="lg"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.basic_image") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img :src="form.shaftimagetxt | get_img" class="mw-100 h-100" />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.close')"
            @click="cancel()"
          >
            {{ $t("button.common.close") }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/goods";
import "@/filter/common";

export default {
  name: "ShaftWrite",
  data() {
    return {
      form: {
        brandno: "0",
        shaftballancecnt: 0,
        checklist: [],
        shaftflextype: [],
        shaftweight: [],
        shafttorque: [],
        shaftkickpoint: [],

        shafttitle: "",
        shaftmaterial: 1,
        shaftimage: "",
        shaftimagetxt: "",
        shaftprice: "",
      },
      file1: null,
      // targetFileName: "",
      // shaftimage: "",
      shaftno: this.$route.params.shaftno || 0,
      isVisible: [true, true, true, true, true],
      // isVisible: [1,1,1,1,1],

      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  components: {
    location,
  },
  methods: {
    getShaftview() {
      if (this.mainbrandno) {
        this.form.brandno = this.mainbrandno;
      }
      if (this.shaftno) {
        ax.get_shaftview(this.shaftno, {}, (flag, data) => {
          // this.form.shaftflextype = data.shaftinfo.shaftbalance[0].shaftflextype;
          // this.form.shaftweight = data.shaftinfo.shaftbalance[0].shaftweight;
          // this.form.shafttorque = data.shaftinfo.shaftbalance[0].shafttorque;
          // this.form.shaftkickpoint = data.shaftinfo.shaftbalance[0].shaftkickpoint;
          this.form.shaftbalancecnt = data.shaftinfo.shaftbalance.length;

          for (let i = 0; i < data.shaftinfo.shaftbalance.length; i++) {
            this.form.checklist[i] = true;

            this.form.shaftflextype[i] =
              data.shaftinfo.shaftbalance[i].shaftflextype != "0"
                ? data.shaftinfo.shaftbalance[i].shaftflextype
                : "undefined";
            this.form.shaftweight[i] =
              data.shaftinfo.shaftbalance[i].shaftweight;
            this.form.shafttorque[i] =
              data.shaftinfo.shaftbalance[i].shafttorque;
            this.form.shaftkickpoint[i] =
              data.shaftinfo.shaftbalance[i].shaftkickpoint;
          }

          // this.form.shaftballancecnt = data.shaftinfo.shaftbalance.length;
          this.form.shafttitle = data.shaftinfo.shafttitle;
          this.form.shaftmaterial = data.shaftinfo.shaftmaterial;
          this.form.shaftprice = data.shaftinfo.shaftprice;
          this.form.shaftimage = data.shaftinfo.shaftimagepath;
          this.form.shaftimagetxt = data.shaftinfo.shaftimagepath;

          if (sessionStorage.getItem("isAdmin") == "1") {
            if (
              data.shaftinfo.brandno &&
              this.mainbrandno != data.shaftinfo.brandno
            ) {
              this.$store.commit("setBrandno", data.shaftinfo.brandno);
              window.location.reload(true);
            }
            this.form.brandno = data.shaftinfo.brandno;
          }

          if (!flag) {
            alert(data);
          }
        });
      }
    },
    toggleDisabled(index) {
      this.isVisible[index] = !this.isVisible[index];
    },
    format_number(value) {
      if (value) {
        const parts = value.replace(/,/g, "").toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      } else return "";
    },
    shaftDelete() {
      ax.delete_shaft(this.shaftno, (flag, data) => {
        alert(data.message);
        if (flag) {
          this.$router.push({
            path: "/goods/shaftlist",
          });
        } else {
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();

      // if (this.form.shaftflextype === "") {
      //   alert("강도를 선택하세요.");
      //   return false;
      // }

      // alert(this.form.checklist.length);
      this.form.shaftbalancecnt = this.form.checklist.length;

      this.form.checklist.forEach((v, i) => {
        if (!v) {
          this.form.checklist.splice(i, 1);
          this.form.shaftweight.splice(i, 1);
          this.form.shaftflextype.splice(i, 1);
          this.form.shafttorque.splice(i, 1);
          this.form.shaftkickpoint.splice(i, 1);
        }
      });

      for (let i = 0; i < this.form.checklist.length; i++) {
        if (
          this.form.shaftflextype[i] === undefined ||
          this.form.shaftflextype[i] === "undefined"
        ) {
          alert(this.$i18n.t("alert.common.enter_strength"));
          return false;
        }
        if (this.form.shaftweight[i] === "") {
          alert(this.$i18n.t("alert.common.enter_weight"));
          return false;
        }
        if (this.form.shafttorque[i] === "") {
          alert(this.$i18n.t("alert.common.enter_torque"));
          return false;
        }
        if (this.form.shaftkickpoint[i] === "") {
          alert(this.$i18n.t("alert.common.enter_kickpoint"));
          return false;
        }
      }

      if (this.form.shafttitle === "") {
        alert(this.$i18n.t("alert.common.enter_goods_name"));
        return false;
      }
      // if (this.form.shaftmaterial === "") {
      //   alert("재질을 선택하세요.");
      //   return false;
      // }
      if (this.form.shaftprice === "") {
        alert(this.$i18n.t("alert.common.enter_goods_price"));
        return false;
      }

      ax.post_shaftwrite(this.form, this.shaftno, (flag, data) => {
        if (flag) {
          alert(data.message);

          this.$router.push({
            path: "/goods/shaftlist/",
          });
        } else {
          alert(data.message);
        }
      });
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.shaftimage = e.dataTransfer.files[0];
      } else {
        this.form.shaftimage = e.target.files[0];
      }
    },
    // handleFileChange(e) {
    //   this.targetFileName = e.target.files[0].name;
    //   this.form.shaftimage = this.$refs.file.files[0];
    // },
  },
  created() {
    this.getShaftview();
  },
};
</script>
