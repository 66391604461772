<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swiper mySwiper"
    >
      <swiper-slide :class="{ active: tab == 1 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsFittingSales',
                params: { goodstype: '1' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsFittingSales',
          }"
        >
          {{ $t("button.stats.fitting_purchase_count") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 2 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsFittingGoods',
                params: { goodstype: '2' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsFittingGoods',
          }"
        >
          {{ $t("button.stats.premium_entry_count") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 3 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsFittingShop',
                params: { goodstype: '3' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsFittingShop',
          }"
        >
          {{ $t("button.stats.location_rank") }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "statsfittingsalesTab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (this.$route.name === "StatsFittingSales") {
        // alert(this.$route.params.goodstype);
        if (this.$route.params.goodstype) {
          return parseInt(this.$route.params.goodstype);
        } else {
          return 1;
        }
      } else if (this.$route.name === "StatsFittingGoods") {
        return 2;
      } else if (this.$route.name === "StatsFittingShop") {
        return 3;
      } else {
        return 1;
      }
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>

<style scoped>
@media (max-width: 992px) {
  .swiper-slide div {
    font-size: 16px !important;
  }
}
</style>
