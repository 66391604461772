<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-12 col-sm-4 mb-2 mb-sm-0">
              <select :aria-label="$t('title.common.choose')" v-model="searchData.forder">
                <option value="">{{ $t("filter.common.total") }}</option>
                <option value="DESC">{{ $t("filter.common.top_5") }}</option>
                <option value="ASC">{{ $t("filter.common.lower_5") }}</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <select :aria-label="$t('title.common.choose')" v-model="searchData.datetype">
                <option value="year">{{ $t("filter.common.yearly") }}</option>
                <option value="month">{{ $t("filter.common.monthly") }}</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :disabled="searchData.datetype == 'year'"
                :type="searchData.datetype == 'month' ? 'year' : searchData.datetype == 'year' ? '' : 'month'"
                :placeholder="$t('title.common.choose')"
                :aria-label="$t('title.common.choose')"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              {{ $t("button.stats.location_rank") }}
              <span class="align-self-center ms-auto price-unit text-secondary"
                >({{ $t("text.common.unit") }} : {{ $t("text.common.people") }})</span
              >
            </div>
            <div class="card-body">
              <GChart
                :type="chartsData.chartsType"
                :data="chartsData.saleData"
                :options="chartsData.saletotalOptions"
              />
            </div>
          </div>

          <div class="query-block sort-by gap-3 my-1 my-sm-0">
            <div
              :class="{ active: searchData.findex == 'membercnt' }"
              @click="
                searchData.findex = 'membercnt';
                searchData.pageno = 1;
              "
              v-if="!searchData.forder"
            >
              {{ $t("filter.common.sort_by_member") }}<span class="material-icons-outlined"
                >arrow_drop_down</span
              >
            </div>
            <div
              :class="{ active: searchData.findex == 'shopname' }"
              @click="
                searchData.findex = 'shopname';
                searchData.pageno = 1;
              "
              v-if="!searchData.forder"
            >
              {{ $t("filter.common.sort_by_location_name") }}<span class="material-icons-outlined"
                >arrow_drop_down</span
              >
            </div>
            <div class="ms-auto fw-normal text-normal">
              {{ $t("button.common.total") }} {{ $t("menu.location.location") }}<b class="text-primary ls-n1px">
                {{ shopcount | comma }}</b
              >{{ $t("text.common.ea") }}
            </div>
          </div>

          <b-table-simple responsive table-class="tfoot-type">
            <b-thead>
              <b-tr
                v-for="(val, key) in Object.entries(saleData.salelist).slice(
                  0,
                  1
                )"
                :key="'a' + key"
              >
                <b-th sticky-column>{{ $t("title.member.location") }}</b-th>
                <b-th v-for="(val_, key_) in val[1]" :key="'a' + key_">
                  {{ val_[0] }}
                </b-th>
                <b-th>{{ $t("title.common.sum") }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(val, key) in saleData.salelist" :key="'b' + key">
                <b-td sticky-column class="shop-name">{{ key }}</b-td>
                <b-td v-for="(val_, key_) in val" :key="'b' + key_">
                  {{ val_[1] ? val_[1].membercnt : "-" }}
                </b-td>
                <b-td>
                  {{
                    saleData.total_sum[key]
                      ? saleData.total_sum[key].membercnt
                      : "-"
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Pagination"
            class="mt-3"
            v-if="page_total > 1 && !searchData.forder"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmembertotalTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";

export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        forder: "",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
        pagesize: 10,
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        findex: "membercnt",
      },
      chartsData: {
        chartsType: "ColumnChart",
        saleData: [],
        saletotalOptions: {
          legend: { position: "bottom" },

          hAxis: { showTextEvery: "1", textPosition: "out" },
        },

        hAxis: {},
      },
      page_total: 1,
      shopcount: 0,
      saleData: { saleinfo: [], salelist: {}, total_sum: {} },
      view_hide: null,
    };
  },
  methods: {
    get_membershoplist() {
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });

      ax.get_membershoplist(this.searchData, (flag, data = "") => {
        this.saleData.total_sum = data.total_sum;
        this.shopcount = data.shopcount;

        this.page_total = Math.ceil(data.total_rows / this.searchData.pagesize);
        this.saleData.salelist = {};
        this.chartsData.chartsType = "ColumnChart";
        Object.keys(data.salelist).forEach((k) => {
          let item = [];
          Object.keys(data.salelist[k])
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .forEach((_k) => {
              let datekey = _k;
              if (this.searchData.datetype === "year")
                datekey =
                  parseInt(_k.substring(_k.length - 4, _k.length)) + this.$i18n.t("text.common.year");
              if (this.searchData.datetype === "month")
                datekey =
                  parseInt(_k.substring(_k.length - 2, _k.length)) + this.$i18n.t("text.common.month");

              item.push([datekey, data.salelist[k][_k] || ""]);
            });

          this.saleData.salelist[k] = item;
        });
        console.log("saleshoplist", this.saleData.salelist);

        if (this.searchData.forder) {
          let _key = Object.keys(data.salelist).filter((k) => k !== this.$i18n.t("title.common.sum"));
          this.chartsData.chartsType = "LineChart";

          this.chartsData.saleData = [[this.$i18n.t("title.common.period"), ..._key]];
          Object.keys(data.saleinfo)
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .forEach((k) => {
              let datekey = k;
              if (this.searchData.datetype === "year")
                datekey = parseInt(k.substring(k.length - 4, k.length)) + this.$i18n.t("text.common.year");
              if (this.searchData.datetype === "month")
                datekey = parseInt(k.substring(k.length - 2, k.length)) + this.$i18n.t("text.common.month");

              this.chartsData.saleData.push([
                datekey,
                ..._key.map((v) =>
                  data.saleinfo[k][v] ? data.saleinfo[k][v] : 0
                ),
              ]);
            });
        } else {
          this.chartsData.chartsType = "ColumnChart";
          this.chartsData.saleData = [[this.$i18n.t("title.member.location"), this.$i18n.t("title.location.member_count")]];
          for (let v in data.saleinfo) {
            this.chartsData.saleData.push([v, parseInt(data.saleinfo[v]) || 0]);
          }
        }
        if (!Object.keys(data.saleinfo).length) {
          this.chartsData.saleData = [[this.$i18n.t("title.member.location"), this.$i18n.t("title.common.sales_sum")]];
          this.chartsData.chartsType = "PieChart";
        }
        console.log("saleshopData", this.chartsData.saleData);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });
      this.get_membershoplist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
  },
  created() {
    this.get_membershoplist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_membershoplist();
      },
      deep: true,
    },
  },
};
</script>
