<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swiper"
    >
      <swiper-slide :class="{ active: tab == 0 }">
        <div
          @click="
            $router
              .push({
                name: 'GoodsList',
                params: { goodstype: '0' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '0',
          }"
        >
          {{ $t("filter.common.total") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 1 }">
        <div
          @click="
            $router
              .push({
                name: 'GoodsList',
                params: { goodstype: '1' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '1',
          }"
        >
          {{ $t("filter.common.driver") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 2 }">
        <div
          @click="
            $router
              .push({
                name: 'GoodsList',
                params: { goodstype: '2' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '2',
          }"
        >
          {{ $t("filter.common.wood") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 3 }">
        <div
          @click="
            $router
              .push({
                name: 'GoodsList',
                params: { goodstype: '3' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '3',
          }"
        >
          {{ $t("filter.common.utility") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 4 }">
        <div
          @click="
            $router
              .push({
                name: 'GoodsList',
                params: { goodstype: '4' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '4',
          }"
        >
          {{ $t("filter.common.iron") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 5 }">
        <div
          @click="
            $router
              .push({
                name: 'GoodsList',
                params: { goodstype: '5' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '5',
          }"
        >
          {{ $t("filter.common.wedge") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 6 }">
        <div
          @click="
            $router
              .push({
                name: 'GoodsList',
                params: { goodstype: '6' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '6',
          }"
        >
          {{ $t("filter.common.putter") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 7 }">
        <div
          @click="
            $router
              .push({
                name: 'ShaftList',
              })
              .catch(() => {})
          "
          :class="{
            active: $route.name === 'ShaftList',
          }"
        >
          {{ $t("filter.common.shaft") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 8 }">
        <div
          @click="
            $router
              .push({
                name: 'GripList',
              })
              .catch(() => {})
          "
          :class="{
            active: $route.name === 'GripList',
          }"
        >
          {{ $t("filter.common.grip") }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Tab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (this.$route.name === "GoodsList") {
        // alert(this.$route.params.goodstype);
        if (this.$route.params.goodstype) {
          return parseInt(this.$route.params.goodstype);
        } else {
          return 0;
        }
      } else if (this.$route.name === "ShaftList") {
        return 7;
      } else if (this.$route.name === "GripList") {
        return 8;
      } else {
        return 0;
      }
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>