<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page">
      <b-form @submit="onSubmit">
        <div class="filter-group row">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                aria-label=""
                v-model="searchData.searchtype"
              >
                <option value="G.fit_goods_nm">
                  {{ $t("filter.common.goods_name") }}
                </option>
              </select>
              <div class="input-group flex-nowrap">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.input_search')"
                  :aria-label="$t('text.common.input_search')"
                  aria-describedby="button-addon"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="" v-model="searchData.findex">
                <option value="G.reg_date" selected>
                  {{ $t("filter.common.sort_by_date") }}
                </option>
                <!-- <option value="2">판매순</option> -->
                <option value="G.fit_sale_price">
                  {{ $t("filter.common.sort_by_price") }}
                </option>
                <!-- <option value="fit_sale_price">저가순</option> -->
                <option value="G.fit_goods_nm">
                  {{ $t("filter.common.sort_by_goods_name") }}
                </option>
              </select>
              <select aria-label="" v-model="searchData.pagesize">
                <option value="20" selected>
                  {{ $t("filter.common.sort_by_20") }}
                </option>
                <option value="40">{{ $t("filter.common.sort_by_40") }}</option>
                <option value="60">{{ $t("filter.common.sort_by_60") }}</option>
              </select>
            </div>
          </div>
        </div>
      </b-form>

      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 g-lg-4"
        v-if="data_yn"
      >
        <div class="col" :key="index" v-for="(item, index) in goodslist">
          <router-link
            :to="{
              name: 'GoodsWrite',
              params: { goodsno: item.goodsno },
              query: {
                searchtype: searchData.searchtype,
                searchname: searchData.searchname,
                goodstype: searchData.goodstype,
              },
            }"
            class="card h-100 rounded-0 view-zoom-in"
          >
            <div
              class="card-body d-flex justify-content-center align-items-center overflow-hidden m-2 mb-0 p-0"
            >
              <img :src="goods_img(index)" class="rounded-0" alt="Clubs" />
            </div>
            <div class="card-footer p-3 border-0 bg-light">
              <h6 class="mb-2 font-montserrat text-truncate">
                {{ item.goodstitle }}
              </h6>
              <p class="card-text text-truncate small">
                {{ item.shafttitle }}({{ item.shaftmaterial }})
              </p>
              <p class="mt-2 small text-price">
                &#8361;{{ item.goodsprice | comma }}
              </p>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row g-0 border-top-dgray border-bottom text-center" v-else>
        <div class="col m-5">{{ $t("text.common.no_data") }}</div>
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class=""
        v-if="page_total > 1 && data_yn"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/goods/Tab";
import ax from "@/api/goods";
import "@/filter/common";

export default {
  name: "GoodsList",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "G.fit_goods_nm",
        findex:
          this.$route.query.findex ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].findex) ||
          "G.reg_date",
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          20,
        goodstype:
          this.$route.params.goodstype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].goodstype) ||
          "0",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      goodslist: [],
      rows: 20,
      browser: "",
    };
  },
  methods: {
    getGoodslist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_goodslist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.goodslistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }

          this.goodslist = data.goodslist;
          this.goodslistcnt = data.pagination.goodslistcnt;
          this.total_cnt = data.pagination.goodslistcnt;
          this.page_total = Math.ceil(data.pagination.goodslistcnt / this.rows);
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.getGoodslist();
      // this.$router
      //   .push({
      //     query: {
      //       searchtype: this.searchData.searchtype,
      //       searchname: this.searchData.searchname,
      //       goodstype: this.searchData.goodstype,
      //       pagesize: this.searchData.pagesize,
      //       findex: this.searchData.findex,
      //       brandno: this.searchData.brandno,
      //     },
      //   })
      //   .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    goods_img(index) {
      if (
        this.goodslist[index].goodsimagepath !== null &&
        this.goodslist[index].goodsimagepath !== ""
      ) {
        return (
          process.env.VUE_APP_S3_BUCKET_URL +
          "/" +
          this.goodslist[index].goodsimagepath
        );
      } else {
        return "/img/no-img.jpg";
      }
    },
  },
  created() {
    this.getGoodslist();
  },
  watch: {
    "$route.params.goodstype": function () {
      (this.searchData.goodstype = this.$route.params.goodstype || "0"),
        (this.searchData.pageno = 1);
      this.view_hide = null;
      this.getGoodslist();
    },

    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.getGoodslist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.getGoodslist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.getGoodslist();
    },
    "searchData.pageno": function () {
      this.getGoodslist();
    },
  },
  computed: {
    getGoodsType: function () {
      return this.$route.params.goodstype || "0";
    },
  },
};
</script>
