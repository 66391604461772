<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-6 col-sm-4">
              <select
                :aria-label="$t('title.common.choose')" 
                v-model="searchData.datetype"
              >
                <option value="year">{{ $t("filter.common.yearly") }}</option>
                <option value="month">{{ $t("filter.common.monthly") }}</option>
                <option value="day">{{ $t("filter.common.daily") }}</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :disabled="searchData.datetype == 'year'"
                :type="searchData.datetype == 'month' ? 'year' : searchData.datetype == 'year' ? '' : 'month'"
                :placeholder="$t('title.common.choose')"
                :aria-label="$t('title.common.choose')"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              {{ $t("button.stats.sales_sum_by_club") }}
              <span class="align-self-center ms-auto price-unit"
                >({{ $t("text.common.unit") }} : {{ $t("text.common.unit_1000") }})</span
              >
            </div>
            <div class="card-body">
              <GChart
                type="LineChart"
                :data="chartsData.saleclubData"
                :options="chartsData.saleclubtotalOptions"
              />
            </div>
          </div>

          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>{{ $t("title.common.club_type") }}</b-th>
                <b-th
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'a' + key"
                >
                  {{ val[0] }}
                </b-th>
                <b-th> {{ $t("title.common.sum") }} </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td sticky-column class="goods-kind">{{ $t("filter.common.driver") }}</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1][$t("filter.common.driver")] &&
                    val[1][$t("filter.common.driver")].salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['드라이버']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[$t("filter.common.driver")] &&
                    saleData.total_salesum[$t("filter.common.driver")].total_salesum
                      | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['드라이버']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">{{ $t("filter.common.wood") }}</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{ val[1][$t("filter.common.wood")] && val[1][$t("filter.common.wood")].salesum | comma("-") }}
                  <!-- <span
                    v-if="val[1]['우드']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[$t("filter.common.wood")] &&
                    saleData.total_salesum[$t("filter.common.wood")].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['우드']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">{{ $t("filter.common.utility") }}</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1][$t("filter.common.utility")] &&
                    val[1][$t("filter.common.utility")].salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['유틸리티']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[$t("filter.common.utility")] &&
                    saleData.total_salesum[$t("filter.common.utility")].total_salesum
                      | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['유틸리티']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">{{ $t("filter.common.iron") }}</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1][$t("filter.common.iron")] && val[1][$t("filter.common.iron")].salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['아이언']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[$t("filter.common.iron")] &&
                    saleData.total_salesum[$t("filter.common.iron")].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['아이언']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">{{ $t("filter.common.wedge") }}</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{ val[1][$t("filter.common.wedge")] && val[1][$t("filter.common.wedge")].salesum | comma("-") }}
                  <!-- <span
                    v-if="val[1]['웨지']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[$t("filter.common.wedge")] &&
                    saleData.total_salesum[$t("filter.common.wedge")].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['웨지']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">{{ $t("filter.common.putter") }}</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{ val[1][$t("filter.common.putter")] && val[1][$t("filter.common.putter")].salesum | comma("-") }}
                  <!-- <span
                    v-if="val[1]['퍼터']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[$t("filter.common.putter")] &&
                    saleData.total_salesum[$t("filter.common.putter")].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['퍼터']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td sticky-column>{{ $t("title.common.sum") }}</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1][$t("title.common.monthly_sum")] &&
                    val[1][$t("title.common.monthly_sum")].month_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['월 합계']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[$t("title.common.sum")] &&
                    saleData.total_salesum[$t("title.common.sum")].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['합계']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmainTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";
export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      chartsData: {
        saleclubData: [],
        saleclubtotalOptions: {
          chart: {
            title: this.$i18n.t("button.stats.sales_sum_by_club"),
          },
          legend: { position: "bottom" },
        },
      },
      saleData: { saleclubinfo: [], total_salesum: {} },
      view_hide: null,
    };
  },
  methods: {
    get_saleclublist() {
      (this.searchData.goodstype =
        this.$route.params.goodstype ||
        (this.$store.state.searchData[this.$route.matched[0].name] &&
          this.$store.state.searchData[this.$route.matched[0].name]
            .goodstype) ||
        1),
        this.$store.commit("setSearchData", {
          [this.$route.matched[0].name]: this.searchData,
        });

      ax.get_saleclublist(this.searchData, (flag, data = "") => {
        this.saleData.total_salesum = data.total_salesum;
        this.saleData.saleclubinfo = [];
        this.chartsData.saleclubData = [
          [ this.$i18n.t("title.common.period"), this.$i18n.t("title.common.driver"), this.$i18n.t("title.common.wood"), this.$i18n.t("title.common.utility"), this.$i18n.t("title.common.iron"), this.$i18n.t("title.common.wedge"), this.$i18n.t("title.common.putter")],
        ];
        Object.keys(data.saleclubinfo)
          .sort((a, b) => {
            return new Date(a) - new Date(b);
          })
          .forEach((k) => {
            let datekey = k;
            if (this.searchData.datetype === "year")
              datekey = parseInt(k.substring(k.length - 4, k.length)) + this.$i18n.t("text.common.year");
            if (this.searchData.datetype === "month")
              datekey = parseInt(k.substring(k.length - 2, k.length)) + this.$i18n.t("text.common.month");
            if (this.searchData.datetype === "day")
              datekey = parseInt(k.substring(k.length - 2, k.length)) + this.$i18n.t("text.common.day");

            this.chartsData.saleclubData.push([
              datekey,
              (data.saleclubinfo[k][this.$i18n.t("title.common.driver")] &&
                parseInt(data.saleclubinfo[k][this.$i18n.t("title.common.driver")].salesum)) ||
                0,
              (data.saleclubinfo[k][this.$i18n.t("title.common.wood")] &&
                parseInt(data.saleclubinfo[k][this.$i18n.t("title.common.wood")].salesum)) ||
                0,
              (data.saleclubinfo[k][this.$i18n.t("title.common.utility")] &&
                parseInt(data.saleclubinfo[k][this.$i18n.t("title.common.utility")].salesum)) ||
                0,
              (data.saleclubinfo[k][this.$i18n.t("title.common.iron")] &&
                parseInt(data.saleclubinfo[k][this.$i18n.t("title.common.iron")].salesum)) ||
                0,
              (data.saleclubinfo[k][this.$i18n.t("title.common.wedge")] &&
                parseInt(data.saleclubinfo[k][this.$i18n.t("title.common.wedge")].salesum)) ||
                0,
              (data.saleclubinfo[k][this.$i18n.t("title.common.putter")] &&
                parseInt(data.saleclubinfo[k][this.$i18n.t("title.common.putter")].salesum)) ||
                0,
            ]);

            this.saleData.saleclubinfo.push([
              datekey,
              data.saleclubinfo[k] || "",
            ]);
          });
        console.log(this.saleData.saleclubinfo);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });
      this.get_saleclublist();
    },
  },
  created() {
    this.get_saleclublist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_saleclublist();
      },
      deep: true,
    },
  },
};
</script>