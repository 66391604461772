<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <b-form @submit="onSubmit">
        <div
          class="d-flex justify-content-between align-items-center mt-lg-1 mb-2 mb-lg-0"
        >
          <button
            class="btn btn-xs ps-0"
            type="button"
            @click="goodsDelete()"
            v-if="goodsno"
          >
            <span class="icon-delete-bin"></span>
            <span class="d-inline">&nbsp;{{ $t("button.common.delete") }}</span>
          </button>
          <div class="btn-group ms-auto">
            <b-button
              @click="tabIndex--"
              class="btn-xs border-0 bg-transparent text-black"
            >
              <span class="material-icons-outlined fs-6">arrow_back_ios</span>
              <span class="d-inline">&nbsp;{{ $t("button.common.prev") }}</span>
            </b-button>
            <b-button
              @click="tabIndex++"
              class="btn-xs pe-0 border-0 bg-transparent text-black"
            >
              <span class="d-inline">{{ $t("button.common.next") }}&nbsp;</span>
              <span class="material-icons-outlined fs-6"
                >arrow_forward_ios</span
              >
            </b-button>
          </div>
        </div>
        <div class="progress-step-arrow mt-lg-2">
          <b-card no-body>
            <b-tabs v-model="tabIndex" card justified>
              <b-tab :title="$t('page.goods.basic_information')">
                <section>
                  <h6 class="sub-title">
                    {{ $t("page.goods.basic_information") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.common.goods_type")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        name="goodstype"
                        v-model="form.goodstype"
                        @change="change_type($event)"
                      >
                        <option value="1">
                          {{ $t("filter.common.driver") }}
                        </option>
                        <option value="2">
                          {{ $t("filter.common.wood") }}
                        </option>
                        <option value="3">
                          {{ $t("filter.common.utility") }}
                        </option>
                        <option value="4">
                          {{ $t("filter.common.iron") }}
                        </option>
                        <option value="5">
                          {{ $t("filter.common.wedge") }}
                        </option>
                        <option value="6">
                          {{ $t("filter.common.putter") }}
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.common.goods_name")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <b-form-input
                        :placeholder="$t('title.common.goods_name')"
                        class="text"
                        name="goodstitle"
                        v-model="form.goodstitle"
                      ></b-form-input>
                    </div>

                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.common.goods_price")
                      }}</span>
                      <!-- <mark
                        class="ms-1 fs-12 fw-normal font-monospace text-price"
                        >(단위 : 원)
                      </mark> -->
                    </div>
                    <div class="col-sm-9">
                      <b-row class="g-2">
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            :placeholder="$t('title.common.retail_price')"
                            class="text-price"
                            name="goodsprice"
                            v-model="form.goodsprice"
                          ></b-form-input>
                          <label for="sale_price">{{
                            $t("title.common.retail_price")
                          }}</label>
                        </b-col>
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            :placeholder="$t('title.common.location_price')"
                            class="text-price"
                            name="goodsshopprice"
                            v-model="form.goodsshopprice"
                          ></b-form-input>
                          <label for="shop_price">{{
                            $t("title.common.location_price")
                          }}</label>
                        </b-col>
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            :placeholder="$t('title.common.xgolf_price')"
                            class="text-price"
                            name="goodsxgolfprice"
                            v-model="form.goodsxgolfprice"
                          ></b-form-input>
                          <label for="supply_price">{{
                            $t("title.common.xgolf_price")
                          }}</label>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.common.benefit")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <b-form-textarea
                        v-model="form.benefit"
                        cols="30"
                        rows="10"
                        :placeholder="$t('title.common.benefit')"
                        required
                      ></b-form-textarea>
                    </div>

                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.common.basic_image")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <b-form-file
                        v-model="file1"
                        ref="file1"
                        :state="Boolean(file1)"
                        :placeholder="$t('text.common.file_select')"
                        :drop-placeholder="$t('text.common.file_select')"
                        @change="fileSelect"
                      >
                      </b-form-file>
                      <p
                        class="mt-2 text-subscript text-truncate more"
                        @click="$bvModal.show('modal-img-thumbnail')"
                        v-if="form.goodsbasicimagenametxt"
                      >
                        {{ form.goodsbasicimagenametxt }}
                      </p>
                    </div>
                  </div>
                  <br />
                </section>

                <section>
                  <h6 class="sub-title">{{ $t("page.goods.club_detail") }}</h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.common.attached_image1")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <b-form-file
                        v-model="file2"
                        ref="file2"
                        :state="Boolean(file2)"
                        :placeholder="$t('text.common.file_select')"
                        :drop-placeholder="$t('text.common.file_select')"
                        @change="fileSelect1"
                      >
                      </b-form-file>
                      <p
                        class="mt-2 text-subscript text-truncate more"
                        @click="$bvModal.show('modal-img-attached1')"
                        v-if="form.goodsdetailimagename1txt"
                      >
                        {{ form.goodsdetailimagename1txt }}
                      </p>
                    </div>

                    <div class="col-sm-3">
                      <span>{{ $t("title.common.attached_image2") }}</span>
                    </div>
                    <div class="col-sm-9">
                      <b-form-file
                        v-model="file3"
                        ref="file3"
                        :state="Boolean(file3)"
                        :placeholder="$t('text.common.file_select')"
                        :drop-placeholder="$t('text.common.file_select')"
                        @change="fileSelect2"
                      >
                      </b-form-file>
                      <p
                        class="mt-2 text-subscript text-truncate more"
                        @click="$bvModal.show('modal-img-attached2')"
                        v-if="form.goodsdetailimagename2txt"
                      >
                        {{ form.goodsdetailimagename2txt }}
                      </p>
                    </div>
                  </div>
                </section>

                <section
                  v-if="
                    form.goodsdetailimagename1txt ||
                    form.goodsdetailimagename2txt
                  "
                >
                  <h6 class="sub-title">
                    {{ $t("page.goods.attached_image") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-12">
                      <img
                        :src="form.goodsdetailimagename1 | get_img"
                        class="w-100 h-auto"
                        v-if="form.goodsdetailimagename1txt"
                      />
                    </div>
                    <div class="col-sm-12">
                      <img
                        :src="form.goodsdetailimagename2 | get_img"
                        width="100%"
                        v-if="form.goodsdetailimagename2txt"
                      />
                    </div>
                  </div>
                </section>

                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      disabled
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      :aria-label="$t('button.common.next')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button>
                  </b-button-group>
                </div> </b-tab
              ><!-- //tabIndex: 1, 기본정보 -->

              <b-tab :title="$t('page.common.spec')">
                <section class="reg-spec">
                  <h6 class="sub-title">{{ $t("page.common.spec") }}</h6>
                  <b-table-simple responsive table-class="border-0">
                    <b-thead>
                      <b-tr>
                        <b-th sticky-column
                          ><span class="required">{{
                            $t("title.common.club_type")
                          }}</span></b-th
                        >
                        <b-th
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <select
                            name="goodsclubnotype"
                            v-model="form.goodsclubnotype[index]"
                          >
                            <!-- <option disabled>클럽선택</option> -->
                            <option value="undefined">
                              {{ $t("title.common.choose") }}
                            </option>
                            <option
                              :value="item.clubnotype"
                              v-for="(item, index) in form.clubnotypelist"
                              :key="index"
                            >
                              {{ item.clubnotype_name }}
                            </option>
                            <!-- <option value="">클럽선택</option>
                            <option value="1">DR</option>
                            <option value="2">IR</option> -->
                          </select>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-th sticky-column
                          ><span class="required"
                            >{{ $t("title.common.loft")
                            }}<small class="fw-normal">(&deg;)</small></span
                          ></b-th
                        >
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="form.clubloft[index]"
                            :disabled="
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-th sticky-column
                          ><span class="required"
                            >{{ $t("title.common.lie")
                            }}<small class="fw-normal">(&deg;)</small></span
                          ></b-th
                        >
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="form.clublie[index]"
                            :disabled="
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>

                      <b-tr v-if="clubheadvolume_yn === 'y'">
                        <b-th sticky-column
                          ><span class="required"
                            >{{ $t("title.common.head_volume")
                            }}<small class="fw-normal">(&deg;)</small></span
                          ></b-th
                        >
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="form.clubheadvolume[index]"
                            :disabled="
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>

                      <b-tr v-if="clubsol_yn === 'y'">
                        <b-th sticky-column
                          ><span class="required"
                            >{{ $t("title.common.sole")
                            }}<small class="fw-normal">(&deg;)</small></span
                          ></b-th
                        >
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="form.clubsol[index]"
                            :disabled="
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-th sticky-column
                          ><span class="required"
                            >FP<small class="fw-normal">(mm)</small></span
                          ></b-th
                        >
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="form.clubfp[index]"
                            :disabled="
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-th sticky-column
                          ><span class="required"
                            >{{ $t("title.common.length")
                            }}<small class="fw-normal">(Inch)</small></span
                          ></b-th
                        >
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="form.clublength[index]"
                            :disabled="
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-th sticky-column
                          ><span class="required"
                            >{{ $t("title.common.weight")
                            }}<small class="fw-normal">(g)</small></span
                          ></b-th
                        >
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="form.clubweight[index]"
                            :disabled="
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column rowspan="5">
                          <div class="sticky-column-rowspan">
                            <div>
                              <span class="required">{{
                                $t("title.common.balance")
                              }}</span>
                            </div>
                            <div class="flex-column">
                              <div>
                                <select
                                  aria-label=""
                                  id="goodsbalance1"
                                  v-model="form.goodsbalance1"
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option value="1">R</option>
                                  <option value="2">SR</option>
                                  <option value="3">S</option>
                                  <option value="4">L</option>
                                </select>
                              </div>
                              <div>
                                <select
                                  aria-label=""
                                  id="goodsbalance2"
                                  v-model="form.goodsbalance2"
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option value="1">R</option>
                                  <option value="2">SR</option>
                                  <option value="3">S</option>
                                  <option value="4">L</option>
                                </select>
                              </div>
                              <div>
                                <select
                                  aria-label=""
                                  id="goodsbalance3"
                                  v-model="form.goodsbalance3"
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option value="1">R</option>
                                  <option value="2">SR</option>
                                  <option value="3">S</option>
                                  <option value="4">L</option>
                                </select>
                              </div>
                              <div>
                                <select
                                  aria-label=""
                                  id="goodsbalance4"
                                  v-model="form.goodsbalance4"
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option value="1">R</option>
                                  <option value="2">SR</option>
                                  <option value="3">S</option>
                                  <option value="4">L</option>
                                </select>
                              </div>
                              <div>
                                <select
                                  aria-label=""
                                  id="goodsbalance5"
                                  v-model="form.goodsbalance5"
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option value="1">R</option>
                                  <option value="2">SR</option>
                                  <option value="3">S</option>
                                  <option value="4">L</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </b-th>
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            name="goodsbalancedetail1"
                            v-model="form.goodsbalancedetail1[index]"
                            :disabled="
                              form.goodsbalance1 < 1 ||
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            name="goodsbalancedetail2"
                            v-model="form.goodsbalancedetail2[index]"
                            :disabled="
                              form.goodsbalance2 < 1 ||
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            name="goodsbalancedetail3"
                            v-model="form.goodsbalancedetail3[index]"
                            :disabled="
                              form.goodsbalance3 < 1 ||
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            name="goodsbalancedetail4"
                            v-model="form.goodsbalancedetail4[index]"
                            :disabled="
                              form.goodsbalance4 < 1 ||
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(item, index) in 10"
                          class="w-auto"
                          :key="index"
                        >
                          <b-form-input
                            type="text"
                            placeholder=""
                            name="goodsbalancedetail5"
                            v-model="form.goodsbalancedetail5[index]"
                            :disabled="
                              form.goodsbalance5 < 1 ||
                              form.goodsclubnotype[index] == undefined ||
                              form.goodsclubnotype[index] == 'undefined'
                            "
                          ></b-form-input>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </section>
                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      :aria-label="$t('button.common.next')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button>
                  </b-button-group>
                </div> </b-tab
              ><!-- //tabIndex: 2, 스펙 -->

              <b-tab :title="$t('page.goods.shaft_grip')">
                <section class="reg-etc">
                  <h6 class="sub-title">{{ $t("page.goods.shaft") }}</h6>
                  <div class="row g-3 border-0">
                    <div class="col-md-6">
                      <select
                        class="form-select h-100"
                        aria-label="multiple select"
                        size="6"
                        v-model="form.shaftcheck"
                      >
                        <template v-for="(item, index) in form.goodsshaftlist">
                          <option :key="index" :value="item.shaftno">
                            {{ item.shafttitle }}
                          </option>
                        </template>
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="card h-100">
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              form.goodsshaftinfo.shafttitle ||
                              $t("title.common.shaft_name")
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                form.goodsshaftinfo.shaftimagename | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.shaft')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th>{{ $t("title.common.flex") }}</th>
                                <th>
                                  {{ $t("title.common.weight")
                                  }}<small class="fw-normal">(g)</small>
                                </th>
                                <th>
                                  {{ $t("title.common.torque")
                                  }}<small class="fw-normal">(&deg;)</small>
                                </th>
                                <th>{{ $t("title.common.kick_point") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in form.goodsshaftinfo
                                  .shaftbalance"
                                class="col-sm"
                                :key="index"
                              >
                                <td>{{ item.shaftflextypetxt || "-" }}</td>
                                <td>{{ item.shaftweight || "-" }}</td>
                                <td>{{ item.shafttorque || "-" }}</td>
                                <td>{{ item.shaftkickpoint || "-" }}</td>
                              </tr>
                              <tr v-if="!form.goodsshaftinfo.length">
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h6 class="sub-title mt-5">{{ $t("page.goods.grip") }}</h6>
                  <div class="row g-3 border-0">
                    <div class="col-md-6">
                      <select
                        class="form-select h-100"
                        size="6"
                        aria-label="multiple select"
                        v-model="form.gripcheck"
                      >
                        <template v-for="(item, index) in form.goodsgriplist">
                          <option :key="index" :value="item.gripno">
                            {{ item.griptitle }}
                          </option>
                        </template>
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="card h-100">
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              form.goodsgripinfo.griptitle ||
                              $t("title.common.grip_name")
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="form.goodsgripinfo.gripimagename | get_img"
                              class="img-fluid"
                              :alt="$t('page.goods.grip')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th>{{ $t("title.common.standard") }}</th>
                                <th>
                                  {{ $t("title.common.weight")
                                  }}<small class="fw-normal">(g)</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="col-sm">
                                <td>
                                  {{ form.goodsgripinfo.gripspec || "-" }}
                                </td>
                                <td>
                                  {{ form.goodsgripinfo.gripweight || "-" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      :aria-label="$t('button.common.next')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button>
                  </b-button-group>
                </div> </b-tab
              ><!-- //tabIndex: 3, 샤프트&그립 -->

              <b-tab :title="$t('page.goods.recommend_information')">
                <section>
                  <h6 class="sub-title">
                    {{ $t("page.goods.recommend_information") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.common.handicap")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('title.common.choose')"
                        v-model="form.fit_goods_hadicap"
                        v-if="form.goodstype == '1'"
                      >
                        <option value="">
                          {{ $t("title.common.choose") }}
                        </option>
                        <option
                          v-for="(val, idx) in [
                            '-79',
                            '80-89',
                            '90-99',
                            '+100',
                          ]"
                          :key="'fit_goods_hadicap' + val"
                          :value="idx + 1"
                        >
                          {{ val }}
                        </option>
                      </select>
                      <select
                        :aria-label="$t('title.common.choose')"
                        v-model="form.fit_goods_hadicap"
                        v-else
                      >
                        <option value="">
                          {{ $t("title.common.choose") }}
                        </option>
                        <option
                          v-for="(val, idx) in [
                            $t('text.common.below_average_distance'),
                            $t('text.common.over_average_distance'),
                          ]"
                          :key="'fit_goods_hadicap' + val"
                          :value="idx + 1"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3">
                      <span class="required"
                        >{{ $t("title.goods.avg_driver_distance")
                        }}<small class="d-inline font-monospace fw-400"
                          >(m)</small
                        ></span
                      >
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('title.common.choose')"
                        v-model="form.fit_goods_distance"
                      >
                        <option value="">
                          {{ $t("title.common.choose") }}
                        </option>
                        <option
                          v-for="(val, idx) in [
                            '+240',
                            '220-240',
                            '200-220',
                            '180-200',
                            '-179',
                          ]"
                          :key="'fit_goods_distance' + val"
                          :value="idx + 1"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.goods.important_of_club")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('title.common.choose')"
                        v-model="form.fit_goods_important"
                      >
                        <option value="">
                          {{ $t("title.common.choose") }}
                        </option>
                        <option
                          v-for="(val, idx) in [
                            $t('text.common.sense_of_shot'),
                            $t('text.common.sense_of_direction'),
                            $t('text.common.sense_of_distance'),
                          ]"
                          :key="'fit_goods_important' + val"
                          :value="idx + 1"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3">
                      <span class="required"
                        >{{ $t("title.common.seperation_distance")
                        }}<small class="d-inline font-monospace fw-400"
                          >(m)</small
                        ></span
                      >
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('title.common.choose')"
                        v-model="form.Fit_goods_sep_distance"
                        v-if="form.goodstype == '1'"
                      >
                        <option value="">
                          {{ $t("title.common.choose") }}
                        </option>
                        <option value="1">
                          {{ $t("text.common.over_seperation_20") }}
                        </option>
                        <option value="2">
                          {{ $t("text.common.below_seperation_20") }}
                        </option>
                      </select>
                      <select
                        :aria-label="$t('title.common.choose')"
                        v-model="form.Fit_goods_sep_distance"
                        v-else
                      >
                        <option value="">
                          {{ $t("title.common.choose") }}
                        </option>
                        <option value="1">
                          {{ $t("text.common.over_seperation_10") }}
                        </option>
                        <option value="2">
                          {{ $t("text.common.below_seperation_10") }}
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3">
                      <span class="required">{{
                        $t("title.goods.favorite_shaft_strength")
                      }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('title.common.choose')"
                        v-model="form.fit_goods_use_shaft"
                      >
                        <option value="">
                          {{ $t("title.common.choose") }}
                        </option>
                        <option
                          v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                          :key="'fit_goods_use_shaft' + val"
                          :value="idx + 1"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </section>
                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      disabled
                      :aria-label="$t('button.common.next')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button>
                  </b-button-group>
                  <b-button
                    size="xl"
                    variant="secondary"
                    :aria-label="$t('button.common.cancel')"
                    @click="$router.go(-1)"
                    >{{ $t("button.common.cancel") }}</b-button
                  >
                  <b-button
                    type="submit"
                    size="xl"
                    variant="primary"
                    :aria-label="$t('button.common.write')"
                  >
                    {{
                      goodsno
                        ? $t("button.common.edit")
                        : $t("button.common.write")
                    }}</b-button
                  >
                  <b-button
                    @click="tabIndex++"
                    size="xl"
                    variant="outline-secondary btn-next ms-0"
                    disabled
                    :aria-label="$t('button.common.next')"
                  >
                    <span class="material-icons-outlined"
                      >arrow_forward_ios</span
                    >
                  </b-button>
                </div> </b-tab
              ><!-- //tabIndex: 1, 기본정보 -->
            </b-tabs>
          </b-card>
        </div>
      </b-form>

      <b-modal
        id="modal-img-thumbnail"
        size="lg"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.basic_image") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img
            :src="form.goodsbasicimagenametxt | get_img"
            class="mw-100 h-100"
          />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.close')"
            @click="cancel()"
          >
            {{ $t("button.common.close") }}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-img-attached1"
        size="xl"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.attached_image1") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img
            :src="form.goodsdetailimagename1txt | get_img"
            class="mw-100 h-100"
          />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.close')"
            @click="cancel()"
          >
            {{ $t("button.common.close") }}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-img-attached2"
        size="xl"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.attached_image2") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img
            :src="form.goodsdetailimagename2txt | get_img"
            class="mw-100 h-100"
          />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.close')"
            @click="cancel()"
          >
            {{ $t("button.common.close") }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/goods";
import "@/filter/common";

export default {
  name: "GoodsWrite",
  data() {
    return {
      form: {
        brandno: "0",
        goodstype: 1,
        goodstitle: "",
        goodsprice: "",
        goodsxgolfprice: "",
        goodsshopprice: "",
        benefit: "",
        goodsbasicimagename: "",
        goodsbasicimagenametxt: "",
        goodsdetailimagename1: "",
        goodsdetailimagename1txt: "",
        goodsdetailimagename2: "",
        goodsdetailimagename2txt: "",

        goodsbalance1: "0",
        goodsbalance2: "0",
        goodsbalance3: "0",
        goodsbalance4: "0",
        goodsbalance5: "0",

        goodsbalancedetail1: [],
        goodsbalancedetail2: [],
        goodsbalancedetail3: [],
        goodsbalancedetail4: [],
        goodsbalancedetail5: [],

        goodsshaftno: "",
        goodsgripno: "",

        goodsclubnotype: [],
        clubloft: [],
        clublie: [],
        clubheadvolume: [],
        clubsol: [],
        clubfp: [],
        clublength: [],
        clubweight: [],
        clubbalancecnt: 0,

        shaftcheck: [],

        goodsshaftlist: [],
        goodsshaftlistcnt: 0,
        goodsshaftinfo: [],
        goodsshaftbalancecnt: 0,

        gripcheck: [],
        goodsgriplist: [],
        goodsgriplistcnt: 0,
        goodsgripinfo: [],
        clubnotypelist: [],

        fit_goods_hadicap: "",
        fit_goods_distance: "",
        fit_goods_important: "",
        Fit_goods_sep_distance: "",
        fit_goods_use_shaft: "",
        // fit_goods_gender: "M",
      },
      tabIndex: 0,
      file1: null,
      file2: null,
      file3: null,

      // targetFileName: "",
      // targetFileName1: "",
      // targetFileName2: "",
      // goodsbasicimagename: "",
      // goodsdetailimagename1: "",
      // goodsdetailimagename2: "",

      goodsno: this.$route.params.goodsno || 0,

      // isVisible: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      // isVisible1: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      // isVisible2: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      // isVisible3: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      // isVisible4: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      // isVisible5: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],

      // isVisible: [true, true, true, true, true, true, true, true, true, true],
      // isVisible1: [true, true, true, true, true, true, true, true, true, true],
      // isVisible2: [true, true, true, true, true, true, true, true, true, true],
      // isVisible3: [true, true, true, true, true, true, true, true, true, true],
      // isVisible4: [true, true, true, true, true, true, true, true, true, true],
      // isVisible5: [true, true, true, true, true, true, true, true, true, true],

      clubheadvolume_yn: "n",
      clubsol_yn: "n",

      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  components: {
    location,
  },
  methods: {
    getGoodsview() {
      if (this.mainbrandno) {
        this.form.brandno = this.mainbrandno;
      }
      if (this.goodsno) {
        ax.get_goodsview(this.goodsno, {}, (flag, data) => {
          console.log(flag, data);

          this.form.goodstype = data.goodsinfo.goodstype;
          this.form.goodstitle = data.goodsinfo.goodstitle;
          this.form.goodsprice = data.goodsinfo.goodsprice;
          this.form.goodsxgolfprice = data.goodsinfo.goodsxgolfprice;
          this.form.goodsshopprice = data.goodsinfo.goodsshopprice;
          this.form.benefit = data.goodsinfo.benefit;
          this.form.goodsbasicimagename = data.goodsinfo.goodsbasicimagename;
          this.form.goodsbasicimagenametxt = data.goodsinfo.goodsbasicimagename;
          this.form.goodsdetailimagename1 =
            data.goodsinfo.goodsdetailimagename1;
          this.form.goodsdetailimagename1txt =
            data.goodsinfo.goodsdetailimagename1;
          this.form.goodsdetailimagename2 =
            data.goodsinfo.goodsdetailimagename2;
          this.form.goodsdetailimagename2txt =
            data.goodsinfo.goodsdetailimagename2;

          this.form.goodsbalance1 = data.goodsinfo.goodsbalance1;
          this.form.goodsbalance2 = data.goodsinfo.goodsbalance2;
          this.form.goodsbalance3 = data.goodsinfo.goodsbalance3;
          this.form.goodsbalance4 = data.goodsinfo.goodsbalance4;
          this.form.goodsbalance5 = data.goodsinfo.goodsbalance5;

          this.form.goodsshaftno = data.goodsinfo.goodsshaftno;
          this.form.goodsgripno = data.goodsinfo.goodsgripno;

          this.Getclubyplist(data.goodsinfo.goodstype);

          this.form.clubbalancecnt = data.goodsinfo.clubbalance.length;

          for (let i = 0; i < data.goodsinfo.clubbalance.length; i++) {
            // this.isVisible[i] = false;

            // for (let j = 1; j < 6; j++) {
            //   console.log(j,  eval(`this.form.goodsbalance${j}`));
            //   if (eval(`this.form.goodsbalance${j}`) > 0) {
            //   //   eval(`this.isVisible${j}`) = false;
            //     console.log(j, `this.isVisible${j}`);
            //   }
            // }
            // if (this.form.goodsbalance1 > 0) {
            //   this.isVisible1[i] = false;
            // }
            // if (this.form.goodsbalance2 > 0) {
            //   this.isVisible2[i] = false;
            // }
            // if (this.form.goodsbalance3 > 0) {
            //   this.isVisible3[i] = false;
            // }
            // if (this.form.goodsbalance4 > 0) {
            //   this.isVisible4[i] = false;
            // }
            // if (this.form.goodsbalance5 > 0) {
            //   this.isVisible5[i] = false;
            // }

            this.form.goodsclubnotype[i] =
              data.goodsinfo.clubbalance[i].goodsclubnotype;

            // this.form.clubnotype[i] =
            //   data.goodsinfo.clubbalance[i].goodsclubnotype;
            this.form.clubloft[i] = data.goodsinfo.clubbalance[i].clubloft;
            this.form.clublie[i] = data.goodsinfo.clubbalance[i].clublie;
            this.form.clubheadvolume[i] =
              data.goodsinfo.clubbalance[i].clubheadvolume;
            this.form.clubsol[i] = data.goodsinfo.clubbalance[i].clubsol;
            this.form.clubfp[i] = data.goodsinfo.clubbalance[i].clubfp;
            this.form.clublength[i] = data.goodsinfo.clubbalance[i].clublength;
            this.form.clubweight[i] = data.goodsinfo.clubbalance[i].clubweight;

            this.form.goodsbalancedetail1[i] =
              data.goodsinfo.clubbalance[i].goodsbalancedetail1;
            this.form.goodsbalancedetail2[i] =
              data.goodsinfo.clubbalance[i].goodsbalancedetail2;
            this.form.goodsbalancedetail3[i] =
              data.goodsinfo.clubbalance[i].goodsbalancedetail3;
            this.form.goodsbalancedetail4[i] =
              data.goodsinfo.clubbalance[i].goodsbalancedetail4;
            this.form.goodsbalancedetail5[i] =
              data.goodsinfo.clubbalance[i].goodsbalancedetail5;
          }

          if (data.goodsinfo.fit_goods_hadicap)
            this.form.fit_goods_hadicap = data.goodsinfo.fit_goods_hadicap;
          if (data.goodsinfo.fit_goods_distance)
            this.form.fit_goods_distance = data.goodsinfo.fit_goods_distance;
          if (data.goodsinfo.fit_goods_important)
            this.form.fit_goods_important = data.goodsinfo.fit_goods_important;
          if (data.goodsinfo.Fit_goods_sep_distance)
            this.form.Fit_goods_sep_distance =
              data.goodsinfo.Fit_goods_sep_distance;
          if (data.goodsinfo.fit_goods_use_shaft)
            this.form.fit_goods_use_shaft = data.goodsinfo.fit_goods_use_shaft;
          // if (data.goodsinfo.fit_goods_gender)
          //   this.form.fit_goods_gender = data.goodsinfo.fit_goods_gender;

          if (sessionStorage.getItem("isAdmin") == "1") {
            if (
              data.goodsinfo.brandno &&
              this.mainbrandno != data.goodsinfo.brandno
            ) {
              this.$store.commit("setBrandno", data.goodsinfo.brandno);
              window.location.reload(true);
            }
            this.form.brandno = data.goodsinfo.brandno;
          }

          this.getGoodsShaftlist();
          this.getGoodsGriplist();

          if (!flag) {
            alert(data);
          }
        });
      }
    },

    getGoodsShaftlist() {
      ax.get_goodsshaftlist((flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.form.goodsshaftlist = data.shaftlist;
          this.form.goodsshaftlistcnt = data.shaftlist.length;

          for (let i = 0; i < data.shaftlist.length; i++) {
            // alert(this.form.shaftcheck[i]);
            if (this.form.goodsshaftno == data.shaftlist[i].shaftno) {
              // this.form.shaftcheck[i] = true;
              this.form.shaftcheck = data.shaftlist[i].shaftno;
              // this.Goodsshafttoggle(data.shaftlist[i].shaftno);
            }
          }
        } else {
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    Goodsshafttoggle(shaftno) {
      if (shaftno) {
        ax.get_goodsshaftview(shaftno, (flag, data) => {
          console.log(flag, data);
          this.form.goodsshaftinfo = data.shaftinfo;
          this.form.goodsshaftbalancecnt = data.shaftinfo.shaftbalance.length;
          this.form.goodsshaftinfo.shaftbalance = data.shaftinfo.shaftbalance;
          // this.form.goodsshaftinfo.shaftimagename = data.shaftinfo.shaftimagename;

          if (!flag) {
            alert(data);
          }
        });
      }
    },
    getGoodsGriplist() {
      ax.get_goodsgriplist((flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.form.goodsgriplist = data.griplist;
          this.form.goodsgriplistcnt = data.griplist.length;
          for (let i = 0; i < data.griplist.length; i++) {
            if (this.form.goodsgripno == data.griplist[i].gripno) {
              // this.form.gripcheck[i] = true;
              this.form.gripcheck = data.griplist[i].gripno;
              // this.Goodsgriptoggle(data.griplist[i].gripno);
            }
          }
        } else {
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    Goodsgriptoggle(gripno) {
      // alert(shaftno);
      if (gripno) {
        ax.get_goodsgripview(gripno, (flag, data) => {
          console.log(flag, data);
          this.form.goodsgripinfo = data.gripinfo;
          // this.form.goodsgripinfo.gripimagename = data.gripinfo.gripimagename;

          if (!flag) {
            alert(data);
          }
        });
      }
    },
    // toggleDisabled(index) {
    //   if (this.form.goodsclubnotype[index]) {
    //     this.form.clubbalancecnt = this.form.clubbalancecnt + 1;
    //     this.isVisible[index] = false;
    //     this.form.goodsbalance1 > 0 ? (this.isVisible1[index] = false) : "";
    //     this.form.goodsbalance2 > 0 ? (this.isVisible2[index] = false) : "";
    //     this.form.goodsbalance3 > 0 ? (this.isVisible3[index] = false) : "";
    //     this.form.goodsbalance4 > 0 ? (this.isVisible4[index] = false) : "";
    //     this.form.goodsbalance5 > 0 ? (this.isVisible5[index] = false) : "";
    //   } else {
    //     this.form.clubbalancecnt = this.form.clubbalancecnt - 1;
    //     this.isVisible[index] = true;
    //     this.isVisible1[index] = true;
    //     this.isVisible2[index] = true;
    //     this.isVisible3[index] = true;
    //     this.isVisible4[index] = true;
    //     this.isVisible5[index] = true;
    //   }
    //   // this.isVisible[index] = !this.isVisible[index];
    // },
    Getclubyplist(clubtype) {
      ax.get_clubtypelist(clubtype, (flag, data) => {
        if (flag) {
          console.log(flag, data);
          if (flag) {
            this.form.clubnotypelist = data.clubnotypelist;
            if (clubtype == 1 || clubtype == 2 || clubtype == 3) {
              this.clubheadvolume_yn = "y";
              this.clubsol_yn = "n";
            } else {
              this.clubheadvolume_yn = "n";
              this.clubsol_yn = "y";
            }
          } else {
            alert(data);
          }
        } else {
          alert(data.message);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.goodstitle === "") {
        alert(this.$i18n.t("alert.common.enter_goods_name"));
        return false;
      }
      if (this.form.goodsprice === "") {
        alert(this.$i18n.t("alert.common.enter_retail_price"));
        return false;
      }
      if (this.form.goodsshopprice === "") {
        alert(this.$i18n.t("alert.common.enter_location_price"));
        return false;
      }
      if (this.form.goodsxgolfprice === "") {
        alert(this.$i18n.t("alert.common.enter_xgolf_price"));
        return false;
      }
      if (this.form.benefit === "") {
        alert(this.$i18n.t("alert.common.enter_benefit"));
        return false;
      }

      this.form.goodsclubnotype.forEach((v, i) => {
        if (v == "undefined" || v == undefined) {
          this.form.goodsclubnotype.splice(i, 1);
          this.form.clubloft.splice(i, 1);
          this.form.clublie.splice(i, 1);
          this.form.clubheadvolume.splice(i, 1);
          this.form.clubsol.splice(i, 1);
          this.form.clubfp.splice(i, 1);
          this.form.clublength.splice(i, 1);
          this.form.clubweight.splice(i, 1);
          this.form.goodsbalancedetail1.splice(i, 1);
          this.form.goodsbalancedetail2.splice(i, 1);
          this.form.goodsbalancedetail3.splice(i, 1);
          this.form.goodsbalancedetail4.splice(i, 1);
          this.form.goodsbalancedetail5.splice(i, 1);
        }
      });

      this.form.clubbalancecnt = this.form.goodsclubnotype.length;

      ax.post_goodswrite(this.form, this.goodsno, (flag, data) => {
        if (flag) {
          alert(data.message);
          this.$router.push({
            path: "/goods/goodslist",
          });
        } else {
          alert(data.message);
        }
      });
    },
    goodsDelete() {
      ax.delete_goods(this.goodsno, (flag, data) => {
        alert(data.message);
        if (flag) {
          this.$router.push({
            path: "/goods/goodslist/0",
          });
        } else {
        }
      });
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.goodsbasicimagename = e.dataTransfer.files[0];
      } else {
        this.form.goodsbasicimagename = e.target.files[0];
      }
    },
    fileSelect1(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.goodsdetailimagename1 = e.dataTransfer.files[0];
      } else {
        this.form.goodsdetailimagename1 = e.target.files[0];
      }
    },
    fileSelect2(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.goodsdetailimagename2 = e.dataTransfer.files[0];
      } else {
        this.form.goodsdetailimagename2 = e.target.files[0];
      }
    },
    // handleFileChange(e) {
    //   this.targetFileName = e.target.files[0].name;
    //   this.form.goodsbasicimagename = this.$refs.file.files[0];

    // },
    // handleFileChange1(e) {
    //   this.targetFileName1 = e.target.files[0].name;
    //   this.form.goodsdetailimagename1 = this.$refs.file1.files[0];
    // },
    // handleFileChange2(e) {
    //   this.targetFileName2 = e.target.files[0].name;
    //   this.form.goodsdetailimagename2 = this.$refs.file2.files[0];
    // },
    change_type(event) {
      // alert(event.target.value);
      this.Getclubyplist(event.target.value);
    },
    goods_main_img() {
      if (
        this.form.goodsbasicimagename !== null &&
        this.form.goodsbasicimagename !== ""
      ) {
        return (
          process.env.VUE_APP_S3_BUCKET_URL +
          "/" +
          this.form.goodsbasicimagename
        );
      } else {
        return "/img/no-img.jpg";
      }
    },
    // goods_sub_img1() {
    //   if (
    //     this.form.goodsdetailimagename1 !== null &&
    //     this.form.goodsdetailimagename1 !== ""
    //   ) {
    //     return (
    //       process.env.VUE_APP_S3_BUCKET_URL +
    //       "/" +
    //       this.form.goodsdetailimagename1
    //     );
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
    // goods_sub_img2() {
    //   if (
    //     this.form.goodsdetailimagename2 !== null &&
    //     this.form.goodsdetailimagename2 !== ""
    //   ) {
    //     return (
    //       process.env.VUE_APP_S3_BUCKET_URL +
    //       "/" +
    //       this.form.goodsdetailimagename2
    //     );
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
    // shaft_img() {
    //   if (
    //     this.form.goodsshaftinfo.shaftimagename !== null &&
    //     this.form.goodsshaftinfo.shaftimagename !== ""
    //   ) {
    //     return (
    //       process.env.VUE_APP_S3_BUCKET_URL +
    //       "/" +
    //       this.form.goodsshaftinfo.shaftimagename
    //     );
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
    // grip_img() {
    //   if (
    //     this.form.goodsgripinfo.gripimagename !== null &&
    //     this.form.goodsgripinfo.gripimagename !== ""
    //   ) {
    //     return (
    //       process.env.VUE_APP_S3_BUCKET_URL +
    //       "/" +
    //       this.form.goodsgripinfo.gripimagename
    //     );
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
  },
  created() {
    this.getGoodsview();
    if (!this.goodsno) {
      this.Getclubyplist(1);
      this.getGoodsShaftlist();
      this.getGoodsGriplist();
    }
  },
  watch: {
    "form.shaftcheck": function (newVal) {
      if (newVal != "0") this.Goodsshafttoggle(newVal);
    },
    "form.gripcheck": function (newVal) {
      if (newVal != "0") this.Goodsgriptoggle(newVal);
    },
  },
};
</script>
