<template>
  <div class="container-fluid">
    <locations />
    <div class="page">
      <article class="article-header row g-0">
        <div class="col-12">
          <h5>{{ answerinfo.noticetitle }}</h5>
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div class="d-flex align-items-center">
            <span
              class="icon badge fill-purple"
              :class="answerinfo.category | get_custormer_category_bg"
            >
              {{ answerinfo.category | get_custormer_category }}
            </span>
            <span class="shop-name">{{ answerinfo.writer }}</span>
          </div>
          <div class="ms-sm-auto mt-2 mt-sm-0">
            <span class="me-2 text-date">
              <i class="material-icons">date_range </i>
              {{ answerinfo.timezone_regdate | dateformat }}
            </span>
            <span>
              <i class="material-icons">visibility</i>
              {{ answerinfo.viewcnt | comma }}
            </span>
          </div>
        </div>
      </article>

      <article class="article-attach" v-if="answerinfo.as_filename">
        <a :href="answerinfo.as_filepath" class="text-truncate">
          <i class="material-icons-outlined">file_download</i>
          <span>{{ answerinfo.as_filename }}</span>
        </a>
      </article>

      <article class="article-body">
        <span v-html="answerinfo.content"></span>
      </article>

      <!-- 하단답변부분 -->
      <b-form @submit="onSubmit">
        <div class="row g-0 table-type header-col">
          <div class="col-sm-3">
            <span class="required">{{
              $t("title.common.estimated_cost")
            }}</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              placeholder="0"
              class="text-price"
              name="answerprice"
              v-model="form.answerprice"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">{{
              $t("title.common.answer_content")
            }}</span>
          </div>
          <div class="col-sm-9">
            <ckeditor
              tag-name="textarea"
              ref="cktext"
              v-model="form.answercontent"
              :config="editorConfig"
            />
          </div>

          <div class="col-sm-3">
            {{ $t("title.common.answer_attached_file") }}
          </div>
          <div class="col-sm-9">
            <b-form-file
              v-model="file1"
              ref="file1"
              :state="Boolean(file1)"
              :placeholder="$t('text.common.file_select')"
              :drop-placeholder="$t('text.common.file_select')"
              @change="fileSelect"
            >
            </b-form-file>
            <article
              class="article-attach mt-2 p-0 border-bottom-0"
              v-if="form.as_ans_filenametxt"
            >
              <a :href="answerinfo.as_ans_download_link" class="text-truncate">
                <i class="material-icons-outlined">file_download</i>
                <span>{{ form.as_ans_filenametxt }}</span>
              </a>
              <a
                @click="fileDelete"
                class="ms-3 text-nowrap text-decoration-none"
              >
                <i class="material-icons-outlined">delete_outline</i
                >{{ $t("button.common.delete") }}
              </a>
            </article>
          </div>
        </div>
        <div class="btn-bottom-wrapper">
          <!-- 버튼그룹 -->
          <b-button
            size="xl"
            variant="outline-primary"
            :aria-label="$t('button.common.cancel')"
            @click="$router.go(-1)"
            >{{ $t("button.common.cancel") }}</b-button
          >
          <b-button
            size="xl"
            variant="primary"
            type="submit"
            :aria-label="$t('button.common.answer')"
            >{{ $t("button.common.answer") }}</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/customer";
import "@/filter/common";

export default {
  name: "NoticeWrite",
  data() {
    return {
      form: {
        answercontent: "",
        answerprice: "",
        as_filename: "",
        as_filenametxt: "",
        as_ans_filename: "",
        as_ans_filenametxt: "",
      },
      editorConfig: {
        // The configuration of the editor.
        height: "200px",
        language: "en",
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
      },
      answerinfo: {},
      file1: null,

      noticeno: this.$route.params.noticeno || 0,
      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  components: {
    locations,
  },
  methods: {
    get_custormeranswerview() {
      if (this.mainbrandno) {
        this.form.brandno = this.mainbrandno;
      }
      if (this.noticeno) {
        ax.get_custormeranswerview(this.noticeno, {}, (flag, data, code) => {
          this.answerinfo = data.answerinfo;
          this.form.answercontent = data.answerinfo.answercontent;
          this.form.answerprice = data.answerinfo.answerprice;
          this.form.as_ans_filename = data.answerinfo.as_ans_filename;
          this.form.as_ans_filenametxt = data.answerinfo.as_ans_filename;
          if (!flag) {
            alert(data);
          }
        });
      }
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.answercontent === "") {
        alert(this.$i18n.t("alert.common.enter_answer"));
        return false;
      }

      if (this.form.answerprice === "") {
        alert(this.$i18n.t("alert.common.enter_estimated_cost"));
        return false;
      }

      ax.post_custormeranswerwrite(this.form, this.noticeno, (flag, data) => {
        if (flag) {
          alert(data.message);
          this.$router.push({
            path: "/customer/noticeview/" + data.noticeno,
          });
        } else {
          alert(data.message);
        }
      });
    },
    fileDelete() {
      if (confirm(this.$i18n.t("alert.common.want_to_delete"))) {
        ax.delete_custormerfile(this.noticeno, "as_ans", (flag, data) => {
          alert(data.message);
          if (flag) this.get_custormeranswerview();
          else return;
        });
      }
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.as_ans_filename = e.dataTransfer.files[0];
      } else {
        this.form.as_ans_filename = e.target.files[0];
      }
    },
  },
  // watch: {
  //   "form.newpassword": {
  //     handler: function (val) {
  //       const idReg2 = /(?=.*\d)(?=.*[a-zA-ZS]).{8,30}/; // 문자, 숫자 1개이상 포함, 8자리 이상
  //       if (!idReg2.test(val)) {
  //         this.formValidation.password =
  //           "Please enter a password of at least 8 characters and a maximum of 30 characters with including letters and numbers.";
  //       } else {
  //         this.formValidation.password = "this password is available.";
  //       }
  //     },
  //   },
  // },
  created() {
    this.get_custormeranswerview();
  },
};
</script>
