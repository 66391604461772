<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <b-form @submit="onSubmit">
        <div class="filter-group row">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                :aria-label="$t('title.common.choose')"
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="fit_as_title">
                  {{ $t("title.location.title") }}
                </option>
                <option value="O.nickname">
                  {{ $t("filter.common.location") }}
                </option>
              </select>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.input_search')"
                  :aria-label="$t('text.common.input_search')"
                  aria-describedby="button-addon"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                ></button>
              </div>
            </div>
          </div>

          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="" v-model="searchData.findex">
                <option value="tf_fit_as.reg_date">
                  {{ $t("filter.common.sort_by_date") }}
                </option>
                <option value="O.nickname">
                  {{ $t("filter.common.sort_by_location") }}
                </option>
                <option value="fit_as_category">
                  {{ $t("filter.common.sort_by_category") }}
                </option>
              </select>
              <select aria-label="" v-model="searchData.pagesize">
                <option value="10">{{ $t("filter.common.sort_by_10") }}</option>
                <option value="20">{{ $t("filter.common.sort_by_20") }}</option>
                <option value="30">{{ $t("filter.common.sort_by_30") }}</option>
              </select>
            </div>
          </div>
        </div>
      </b-form>

      <table class="table board">
        <thead>
          <tr>
            <th class="col-auto w-1 d-none d-lg-table-cell">
              {{ $t("title.common.no") }}
            </th>
            <th class="col-auto w-1 d-lg-table-cell">
              {{ $t("title.common.category") }}
            </th>
            <th class="col-auto d-lg-table-cell text-center">
              {{ $t("title.location.title") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.status") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.member.location") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.view_count") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="total_cnt">
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.noticeinfo.no }}
              </td>
              <td class="col-auto d-lg-table-cell">
                <span
                  class="badge"
                  :class="item.noticeinfo.category | get_custormer_category_bg"
                  >{{ item.noticeinfo.category | get_custormer_category }}
                </span>
              </td>
              <td class="col-auto d-lg-table-cell text-start">
                <router-link
                  :to="{
                    name: 'NoticeView',
                    params: { noticeno: item.noticeinfo.noticeno },
                    query: { category: searchData.category },
                  }"
                >
                  {{ item.noticeinfo.title }}
                </router-link>
                <img
                  src="/img/icon_new.gif"
                  class="ms-1"
                  v-if="$dayjs().diff(item.date, 'day') < 8"
                />
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.noticeinfo.status }}
              </td>
              <td class="col-auto d-none d-lg-table-cell shop-name">
                {{ item.noticeinfo.shopname }}
              </td>
              <td class="col-auto d-none d-lg-table-cell text-date">
                {{ item.noticeinfo.timezone_regdate | dateformat }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.noticeinfo.viewcnt | comma }}
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="7">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-3">{{ $t("title.common.no") }}</div>
                    <div class="col-9">
                      {{ item.noticeinfo.no }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">{{ $t("title.common.status") }}</div>
                    <div class="col-9">
                      {{ item.noticeinfo.status }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">{{ $t("title.member.location") }}</div>
                    <div class="col-9 shop-name">
                      {{ item.noticeinfo.shopname }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">{{ $t("title.common.date") }}</div>
                    <div class="col-9 text-date">
                      {{ item.noticeinfo.timezone_regdate | dateformat }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">{{ $t("title.common.view_count") }}</div>
                    <div class="col-9">
                      {{ item.noticeinfo.viewcnt | comma }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Pagination"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="primary"
          :aria-label="$t('button.customer.notice_write')"
          @click="
            $router.push({
              path: '/customer/noticewrite',
              query: { category: searchData.category },
            })
          "
          >{{ $t("button.customer.notice_write") }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/customer";
import "@/filter/common";

export default {
  name: "NoticeList",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "fit_as_title",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        category:
          this.$route.meta.category ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].category) ||
          "",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
        findex: "tf_fit_as.reg_date",
        pagesize: "10",
      },

      page_total: 1,
      total_cnt: 0,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  methods: {
    get_custormerlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_custormerlist(this.searchData, (flag, data) => {
        console.log(flag, data);
        this.total_cnt = data.pagination.noticelistcnt;
        this.page_total = Math.ceil(
          data.pagination.noticelistcnt / this.searchData.pagesize
        );
        this.items = data.noticelist;
        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      // this.$store.commit("setSearchData", {
      //   [this.$route.name]: this.searchData,
      // });
      this.get_custormerlist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },

    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_custormerlist();
  },
  watch: {
    "$route.meta.category": function () {
      (this.searchData.category = this.$route.meta.category || ""),
        (this.searchData.pageno = 1);
      this.view_hide = null;
      this.get_custormerlist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.get_custormerlist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_custormerlist();
    },

    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_custormerlist();
    },
    "searchData.searchtype": function () {
      this.searchData.searchname = "";
    },
    "searchData.pageno": function () {
      this.get_custormerlist();
    },
  },

  computed: {
    sub_menu_num: function () {
      return this.$route.meta.sub_menu_num || "";
    },
  },
};
</script>
