<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <div
        class="d-flex justify-content-between align-items-center mt-lg-1 mb-2 mb-lg-0"
      >
        <button @click="get_ansresultlist()" class="btn btn-xs ps-0">
          <span class="material-icons-outlined">outbox</span>
          <span class="d-inline"
            >&nbsp;{{ $t("button.fitting.fitting_history") }}</span
          >
        </button>
        <div class="btn-group">
          <b-button
            @click="tabIndex--"
            class="btn-xs border-0 bg-transparent text-black"
          >
            <span class="material-icons-outlined fs-6">arrow_back_ios</span>
            <span class="d-inline">&nbsp;{{ $t("button.common.prev") }}</span>
          </b-button>
          <b-button
            @click="tabIndex++"
            class="btn-xs pe-0 border-0 bg-transparent text-black"
          >
            <span class="d-inline">{{ $t("button.common.next") }}&nbsp;</span>
            <span class="material-icons-outlined fs-6">arrow_forward_ios</span>
          </b-button>
        </div>
      </div>
      <div class="progress-step-arrow mt-lg-2">
        <b-form @submit="onSubmit">
          <b-card no-body>
            <b-tabs v-model="tabIndex" card justified>
              <b-tab :title="$t('page.fitting.fitting_report')">
                <section class="adv">
                  <h6 class="sub-title">{{ $t("page.fitting.ad_write") }}</h6>
                  <div class="row g-0">
                    <div class="col-md-5">
                      <div
                        class="col adv-item adv-video"
                        :class="{ 'has-item': setad.moviead.adno }"
                        @click="set_ad(1)"
                      >
                        <img
                          v-if="setad.moviead.adno"
                          :src="setad.moviead.adimagepath | get_img"
                        />
                      </div>
                    </div>
                    <div class="col-md-7 flex-wrap">
                      <div
                        class="col-12 adv-item adv-title justify-content-center mb-2"
                        :class="{ 'has-item': setad.titlead.adno }"
                        @click="set_ad(2)"
                      >
                        <img
                          v-if="setad.titlead.adno"
                          class="align-self-center"
                          :src="setad.titlead.adimagepath | get_img"
                        />
                      </div>
                      <div class="row g-0 flex-grow-1">
                        <div
                          class="col adv-item adv-goods flex-sm-wrap"
                          :class="{ 'has-item': setad.goodsad1.adno }"
                          @click="set_ad(3)"
                        >
                          <img
                            v-if="setad.goodsad1.adno"
                            :src="setad.goodsad1.adimagepath | get_img"
                          />
                          <p class="goods-name" v-if="setad.goodsad1.adno">
                            <span>{{ setad.goodsad1.adtitle }}</span>
                          </p>
                        </div>
                        <div
                          class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-wrap"
                          :class="{ 'has-item': setad.goodsad2.adno }"
                          @click="set_ad(4)"
                        >
                          <img
                            v-if="setad.goodsad2.adno"
                            :src="setad.goodsad2.adimagepath | get_img"
                          />
                          <p class="goods-name" v-if="setad.goodsad2.adno">
                            <span>{{ setad.goodsad2.adtitle }}</span>
                          </p>
                        </div>
                        <div
                          class="col adv-item adv-goods flex-sm-wrap"
                          :class="{ 'has-item': setad.goodsad3.adno }"
                          @click="set_ad(5)"
                        >
                          <img
                            v-if="setad.goodsad3.adno"
                            :src="setad.goodsad3.adimagepath | get_img"
                          />
                          <p class="goods-name" v-if="setad.goodsad3.adno">
                            <span>{{ setad.goodsad3.adtitle }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- //광고등록 -->

                <section>
                  <h6 class="sub-title">
                    {{ $t("title.common.fitting_total_analysis") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-5 col-md-4">
                      <span>{{ $t("title.common.fitting_analysis") }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_fitresultlist()"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <textarea
                        class="form-control"
                        v-model="form.resulttxt"
                      ></textarea>
                    </div>
                    <div class="col-sm-5 col-md-4 border-sm-0">
                      <span>{{ $t("title.common.attachment") }}333</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-file
                        v-model="form.resultfilename"
                        :state="Boolean(form.resultfilename)"
                        :placeholder="$t('text.common.file_select')"
                        :drop-placeholder="$t('text.common.file_select')"
                      >
                      </b-form-file>
                      <p
                        class="mt-2 text-subscript text-truncate more"
                        @click="$bvModal.show('modal-img-thumbnail')"
                        v-if="
                          fittingreqinfo.fit_ans_file &&
                          fittingreqinfo.fit_ans_file_is_image == '1'
                        "
                      >
                        {{ $t("title.common.existing_attachments") }} :
                        {{ fittingreqinfo.fit_ans_file_originname }}
                      </p>
                      <p
                        v-if="
                          fittingreqinfo.fit_ans_file &&
                          fittingreqinfo.fit_ans_file_is_image == '0'
                        "
                        class="mt-2 text-subscript text-truncate more"
                      >
                        <a :href="fittingreqinfo.download_link">
                          <i class="material-icons-outlined">file_download</i>
                          <span>{{
                            fittingreqinfo.fit_ans_file_originname
                          }}</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
                <!-- //피팅 종합 분석 -->

                <section class="fitting-anls">
                  <h6 class="sub-title">
                    {{ $t("title.common.pitch_club_analysis") }}
                    <mark class="font-monospace text-orange"
                      >({{ fittingreqinfo.fit_club_type | get_club_type }}
                      {{ $t("text.common.standard") }})</mark
                    >
                  </h6>
                  <div class="card-group">
                    <div class="row g-3">
                      <!-- 공통 : 구질분석 -->
                      <div class="col-md-4">
                        <div class="card h-100">
                          <div class="card-body">
                            <Radar
                              class="pitch-anls"
                              height="300"
                              style="height: 300px"
                              :chart-data="radardata"
                              :chart-options="radaroptions"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.distance") }}
                                  </p>
                                  <select
                                    class="w-auto"
                                    :aria-label="$t('title.common.distance')"
                                    v-model="form.distancegrade"
                                  >
                                    <option
                                      v-for="val in 5"
                                      :key="'distancegrade' + val"
                                      :value="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-orange"
                                      >2</small
                                    >{{ $t("title.common.directionality") }}
                                  </p>
                                  <select
                                    class="w-auto"
                                    :aria-label="
                                      $t('title.common.directionality')
                                    "
                                    v-model="form.directiongrade"
                                  >
                                    <option
                                      v-for="val in 5"
                                      :key="'directiongrade' + val"
                                      :value="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-pink"
                                      >3</small
                                    >{{ $t("title.common.accuracy") }}
                                  </p>
                                  <select
                                    class="w-auto"
                                    :aria-label="$t('title.common.accuracy')"
                                    v-model="form.accuracygrade"
                                  >
                                    <option
                                      v-for="val in 5"
                                      :key="'accuracygrade' + val"
                                      :value="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-purple"
                                      >4</small
                                    >{{ $t("title.common.posture") }}
                                  </p>
                                  <select
                                    class="w-auto"
                                    :aria-label="$t('title.common.posture')"
                                    v-model="form.positiongrade"
                                  >
                                    <option
                                      v-for="val in 5"
                                      :key="'positiongrade' + val"
                                      :value="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-blue"
                                      >5</small
                                    >{{ $t("title.common.impact") }}
                                  </p>
                                  <select
                                    class="w-auto"
                                    :aria-label="$t('title.common.impact')"
                                    v-model="form.impactgrade"
                                  >
                                    <option
                                      v-for="val in 5"
                                      :key="'impactgrade' + val"
                                      :value="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                      <!-- //공통 : 구질분석 -->
                      <!-- 클럽분석 : 드라이버, 우드, 유틸 -->
                      <div
                        class="col-md-4 border-end-md border-start-md"
                        v-if="fittingreqinfo.fit_club_type < 4"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_dvr_tv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.head_volume") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="head-volume"
                                      :placeholder="
                                        $t('title.common.head_volume')
                                      "
                                      :aria-label="
                                        $t('title.common.head_volume')
                                      "
                                      v-model="form.headvolume"
                                      required
                                    />
                                    <label for="head-volume" class="ms-1"
                                      >㎤</label
                                    >
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-orange"
                                      >2</small
                                    >{{ $t("title.common.loft") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="loft"
                                      :placeholder="$t('title.common.loft')"
                                      :aria-label="$t('title.common.loft')"
                                      v-model="form.loft"
                                      required
                                    />
                                    <label for="loft" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>

                      <div
                        class="col-md-4"
                        v-if="fittingreqinfo.fit_club_type < 4"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_dvr_fv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.lie") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="lie"
                                      :placeholder="$t('title.common.lie')"
                                      :aria-label="$t('title.common.lie')"
                                      v-model="form.lie"
                                    />
                                    <label for="lie" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                      <!-- //클럽분석 : 드라이버, 우드, 유틸 -->

                      <!-- 클럽분석 : 아이언, 웨지 -->
                      <div
                        class="col-md-4 border-end-md border-start-md"
                        v-if="fittingreqinfo.fit_club_type > 3"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_iron_tv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.bounce") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="bounce"
                                      :placeholder="$t('title.common.bounce')"
                                      :aria-label="$t('title.common.bounce')"
                                      v-model="form.bounce"
                                      required
                                    />
                                    <label for="bounce" class="ms-1"
                                      >&deg;</label
                                    >
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-orange"
                                      >2</small
                                    >{{ $t("title.common.fp") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="FP"
                                      :placeholder="$t('title.common.fp')"
                                      :aria-label="$t('title.common.fp')"
                                      v-model="form.fp"
                                      required
                                    />
                                    <label for="FP" class="ms-1">㎜</label>
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-pink"
                                      >3</small
                                    >{{ $t("title.common.sole") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="sole"
                                      :placeholder="$t('title.common.sole')"
                                      :aria-label="$t('title.common.sole')"
                                      v-model="form.sole"
                                      required
                                    />
                                    <label for="sole" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-purple"
                                      >4</small
                                    >{{ $t("title.common.loft") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="loft"
                                      :placeholder="$t('title.common.loft')"
                                      :aria-label="$t('title.common.loft')"
                                      v-model="form.loft"
                                      required
                                    />
                                    <label for="loft" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>

                      <div
                        class="col-md-4"
                        v-if="fittingreqinfo.fit_club_type > 3"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_iron_fv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.lie") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="loft"
                                      :placeholder="$t('title.common.lie')"
                                      :aria-label="$t('title.common.lie')"
                                      v-model="form.lie"
                                    />
                                    <label for="loft" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                      <!-- //클럽분석 : 아이언, 웨지 -->
                    </div>
                  </div>
                </section>
                <!-- //구질 및 클럽 분석  -->

                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      disabled
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      :aria-label="$t('button.common.next')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button>
                  </b-button-group>
                </div>
                <!-- //btn-bottom-wrapper -->
              </b-tab>
              <!-- //tabIndex: 0, 피팅리포트 -->

              <b-tab :title="$t('page.fitting.one_point_lesson')">
                <section class="fitting-lesson">
                  <h6 class="sub-title">
                    {{ $t("page.fitting.one_point_lesson") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson1" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lesson1')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.lessons_for_each_item')"
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson1']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel1']"
                          no-border
                          show-clear
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson2" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lesson2')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.lessons_for_each_item')"
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson2']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel2']"
                          no-border
                          show-clear
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson3" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lesson3')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.lessons_for_each_item')"
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson3']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel3']"
                          no-border
                          show-clear
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson4" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lesson4')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.lessons_for_each_item')"
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson4']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel4']"
                          no-border
                          show-clear
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson5" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lesson5')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.lessons_for_each_item')"
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson5']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel5']"
                          no-border
                          show-clear
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson6" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lesson6')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.lessons_for_each_item')"
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson6']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel6']"
                          no-border
                          show-clear
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson7" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lesson7')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.lessons_for_each_item')"
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson7']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel7']"
                          no-border
                          show-clear
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4 border-sm-0">
                      <span>{{ "lessontotal" | get_lesson_title }}</span>
                      <button
                        type="button"
                        class="btn btn-xs ms-auto px-0"
                        @click="get_analysisresultlist('lessontotal')"
                      >
                        <span class="material-icons-outlined">outbox</span>
                      </button>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        :placeholder="$t('text.common.overall_review')"
                        v-model="form.analysisresulttxt['lessontotal']"
                      ></b-form-textarea>
                    </div>
                  </div>
                </section>

                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      :aria-label="$t('button.common.next')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button>
                  </b-button-group>
                </div>
                <!-- //btn-bottom-wrapper --> </b-tab
              ><!-- //tabIndex: 1, 원포인트레슨 -->

              <b-tab :title="$t('page.fitting.premium_goods')">
                <section>
                  <h6 class="sub-title">
                    {{ $t("title.common.basic_information") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-3">
                      <span>{{ $t("title.common.club_type") }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('button.common.club_write')"
                        v-model="form.p_goodsno"
                      >
                        <option value="0">
                          {{ $t("filter.common.club_name") }}
                        </option>
                        <option
                          v-for="val in goodsitems"
                          :key="'p_goodsno' + val.fit_goods_pk"
                          :value="val.fit_goods_pk"
                        >
                          [{{ val.fit_club_type | get_club_type }}]
                          {{ val.fit_goods_nm }}
                          ({{ val.fit_sale_price | comma }})
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3">
                      <span>{{ $t("page.goods.shaft") }}</span>
                    </div>
                    <div class="col-sm-9">
                      <b-row class="g-2">
                        <b-col sm="9">
                          <select
                            :aria-label="$t('page.goods.shaft_write')"
                            v-model="form.p_shaftno"
                          >
                            <option value="0">
                              {{ $t("title.common.shaft_name") }}
                            </option>
                            <option
                              v-for="(val, idx) in p_shaftitems"
                              :key="'p_shaftno' + idx + val.fit_goods_shaft_pk"
                              :value="val.fit_goods_shaft_pk"
                            >
                              {{ val.fit_goods_shaft_nm }}
                              ({{ val.fit_shaft_price | comma }})
                            </option>
                          </select>
                        </b-col>
                        <b-col sm="3">
                          <select
                            :aria-label="$t('title.common.choose')"
                            v-model="form.p_goodsflextype"
                          >
                            <option value="">
                              {{ $t("title.common.flex") }}
                            </option>
                            <option
                              :value="form.p_clubflextype1_"
                              v-if="
                                form.p_clubflextype1_ != '0' &&
                                form.p_clubflextype1_ != ''
                              "
                            >
                              {{ form.p_clubflextype1_ | get_balance }}
                            </option>
                            <option
                              :value="form.p_clubflextype2_"
                              v-if="
                                form.p_clubflextype2_ != '0' &&
                                form.p_clubflextype2_ != ''
                              "
                            >
                              {{ form.p_clubflextype2_ | get_balance }}
                            </option>
                            <option
                              :value="form.p_clubflextype3_"
                              v-if="
                                form.p_clubflextype3_ != '0' &&
                                form.p_clubflextype3_ != ''
                              "
                            >
                              {{ form.p_clubflextype3_ | get_balance }}
                            </option>
                            <option
                              :value="form.p_clubflextype4_"
                              v-if="
                                form.p_clubflextype4_ != '0' &&
                                form.p_clubflextype4_ != ''
                              "
                            >
                              {{ form.p_clubflextype4_ | get_balance }}
                            </option>
                            <option
                              :value="form.p_clubflextype5_"
                              v-if="
                                form.p_clubflextype5_ != '0' &&
                                form.p_clubflextype5_ != ''
                              "
                            >
                              {{ form.p_clubflextype5_ | get_balance }}
                            </option>
                          </select>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="col-sm-3">
                      <span>{{ $t("page.goods.grip") }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('page.goods.grip_write')"
                        v-model="form.p_gripno"
                      >
                        <option value="0">
                          {{ $t("title.common.grip_name") }}
                        </option>
                        <option
                          v-for="val in p_gripitems"
                          :key="'p_gripno' + val.fit_goods_grip_pk"
                          :value="val.fit_goods_grip_pk"
                        >
                          {{ val.fit_goods_grip_nm }}
                          ({{ val.fit_grip_price | comma }})
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3 border-sm-0">
                      <span>{{ $t("title.common.goods_price") }}</span>
                      <mark
                        class="ms-1 fs-12 fw-normal font-monospace text-price"
                        >({{ $t("text.common.unit") }} :
                        {{ $t("text.common.unit_won") }})
                      </mark>
                    </div>
                    <div class="col-sm-9">
                      <b-row class="g-2">
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            id="sale_price"
                            :placeholder="$t('title.common.retail_price')"
                            class="text-price"
                            v-model="form.p_goodsprice"
                          ></b-form-input>
                          <label for="sale_price">{{
                            $t("title.common.retail_price")
                          }}</label>
                        </b-col>
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            id="shop_price"
                            :placeholder="$t('title.common.location_price')"
                            class="text-price"
                            v-model="form.p_goodsxgolfprice"
                          ></b-form-input>
                          <label for="shop_price">{{
                            $t("title.common.location_price")
                          }}</label>
                        </b-col>
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            id="supply_price"
                            :placeholder="$t('title.common.xgolf_price')"
                            class="text-price"
                            v-model="form.p_goodsshopprice"
                          ></b-form-input>
                          <label for="supply_price">{{
                            $t("title.common.xgolf_price")
                          }}</label>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </section>
                <!-- //기본 정보 등록 -->

                <section class="reg-spec">
                  <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                  <b-table-simple responsive table-class="border-0">
                    <b-thead>
                      <b-tr>
                        <b-th sticky-column>{{
                          $t("title.common.club_type")
                        }}</b-th>
                        <b-th
                          v-for="(val, index) in 10"
                          :key="'p_clubnotype' + val"
                        >
                          <select
                            :aria-label="$t('title.common.choose')"
                            v-model="form.p_clubnotype[index]"
                          >
                            <option value="undefined">
                              {{ $t("title.common.choose") }}
                            </option>
                            <option
                              v-for="val in fittingreqinfo.clubtypelist"
                              :key="'clubtypelist' + index + +val.clubtype_pk"
                              :value="val.clubtype_code"
                            >
                              {{ val.clubtype_code_name }}
                            </option>
                          </select>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.loft") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubloft' + idx + val"
                        >
                          <b-form-input
                            v-model="form.p_clubloft[idx]"
                            :disabled="
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          >
                          </b-form-input>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.lie") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clublie' + idx + +val"
                        >
                          <b-form-input
                            v-model="form.p_clublie[idx]"
                            :disabled="
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type > 3">
                        <b-th sticky-column
                          >{{ $t("title.common.sole") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubsole' + idx + val"
                          ><b-form-input
                            v-model="form.p_clubsole[idx]"
                            :disabled="
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type < 4">
                        <b-th sticky-column
                          >{{ $t("title.common.head_volume") }}(㎤)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubheadvolume' + idx + val"
                          ><b-form-input
                            v-model="form.p_clubheadvolume[idx]"
                            :disabled="
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.fp") }}(mm)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubfp' + idx + val"
                          ><b-form-input
                            v-model="form.p_clubfp[idx]"
                            :disabled="
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.length") }}(Inch)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clublength' + idx + val"
                          ><b-form-input
                            v-model="form.p_clublength[idx]"
                            :disabled="
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column rowspan="5">
                          <div class="sticky-column-rowspan">
                            <div>{{ $t("title.common.balance") }}</div>
                            <div class="flex-column">
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.p_clubflextype1"
                                  :disabled="
                                    form.p_clubflextype1_ !=
                                    form.p_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'p_clubflextype1' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.p_clubflextype2"
                                  :disabled="
                                    form.p_clubflextype2_ !=
                                    form.p_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'p_clubflextype2' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.p_clubflextype3"
                                  :disabled="
                                    form.p_clubflextype3_ !=
                                    form.p_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'p_clubflextype3' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.p_clubflextype4"
                                  :disabled="
                                    form.p_clubflextype4_ !=
                                    form.p_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'p_clubflextype4' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div class="py-2 pe-2">
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.p_clubflextype5"
                                  :disabled="
                                    form.p_clubflextype5_ !=
                                    form.p_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'p_clubflextype5' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </b-th>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubflextitle1' + val"
                          ><b-form-input
                            v-model="form.p_clubflextitle1[idx]"
                            :disabled="
                              form.p_clubflextype1_ != form.p_goodsflextype ||
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubflextitle2' + val"
                          ><b-form-input
                            v-model="form.p_clubflextitle2[idx]"
                            :disabled="
                              form.p_clubflextype2_ != form.p_goodsflextype ||
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubflextitle3' + val"
                          ><b-form-input
                            v-model="form.p_clubflextitle3[idx]"
                            :disabled="
                              form.p_clubflextype3_ != form.p_goodsflextype ||
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubflextitle4' + val"
                          ><b-form-input
                            v-model="form.p_clubflextitle4[idx]"
                            :disabled="
                              form.p_clubflextype4_ != form.p_goodsflextype ||
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubflextitle5' + val"
                          ><b-form-input
                            v-model="form.p_clubflextitle5[idx]"
                            :disabled="
                              form.p_clubflextype5_ != form.p_goodsflextype ||
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column class="border-0"
                          >{{ $t("title.common.weight") }}(g)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'p_clubweight' + val"
                          ><b-form-input
                            v-model="form.p_clubweight[idx]"
                            :disabled="
                              form.p_clubnotype[idx] == undefined ||
                              form.p_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </section>
                <!-- 스펙 등록 -->

                <section class="reg-etc">
                  <div class="row gx-4 gy-5 border-0">
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.shaft") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          p_shaftitems.find(
                            (v) => v.fit_goods_shaft_pk == form.p_shaftno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              p_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.p_shaftno
                              ).fit_goods_shaft_nm
                            }}
                            /
                            {{
                              p_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.p_shaftno
                              ).fit_shaft_material | get_shaft_material
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                p_shaftitems.find(
                                  (v) => v.fit_goods_shaft_pk == form.p_shaftno
                                ).fit_goods_shaft_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.shaft')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_balance | get_balance
                                  }}
                                </td>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_weight
                                  }}
                                </td>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_torque
                                  }}
                                </td>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_kickpoint
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card h-100" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          p_gripitems.find(
                            (v) => v.fit_goods_grip_pk == form.p_gripno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              p_gripitems.find(
                                (v) => v.fit_goods_grip_pk == form.p_gripno
                              ).fit_goods_grip_nm
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                p_gripitems.find(
                                  (v) => v.fit_goods_grip_pk == form.p_gripno
                                ).fit_goods_grip_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.grip')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                    p_gripitems.find(
                                      (v) =>
                                        v.fit_goods_grip_pk == form.p_gripno
                                    ).fit_goods_grip_standard
                                  }}
                                </td>
                                <td>
                                  {{
                                    p_gripitems.find(
                                      (v) =>
                                        v.fit_goods_grip_pk == form.p_gripno
                                    ).fit_goods_grip_weight
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card h-100" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- 샤프트 & 그립 등록 -->

                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      :aria-label="$t('button.common.next')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button>
                  </b-button-group>
                </div>
                <!-- //btn-bottom-wrapper --> </b-tab
              ><!-- //tabIndex: 2, 고급형 -->

              <b-tab :title="$t('page.fitting.entry_goods')">
                <section>
                  <h6 class="sub-title">
                    {{ $t("title.common.basic_information") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-3">
                      <span>{{ $t("title.common.club_type") }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('button.common.club_write')"
                        v-model="form.c_goodsno"
                      >
                        <option value="0">
                          {{ $t("filter.common.club_name") }}
                        </option>
                        <option
                          v-for="val in goodsitems"
                          :key="'c_goodsno' + val.fit_goods_pk"
                          :value="val.fit_goods_pk"
                        >
                          [{{ val.fit_club_type | get_club_type }}]
                          {{ val.fit_goods_nm }}
                          ({{ val.fit_sale_price | comma }})
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3">
                      <span>{{ $t("page.goods.shaft") }}</span>
                    </div>
                    <div class="col-sm-9">
                      <b-row class="g-2">
                        <b-col sm="9">
                          <select
                            :aria-label="$t('page.goods.shaft_write')"
                            v-model="form.c_shaftno"
                          >
                            <option value="0">
                              {{ $t("title.common.shaft_name") }}
                            </option>
                            <option
                              v-for="(val, idx) in c_shaftitems"
                              :key="'c_shaftno' + idx + val.fit_goods_shaft_pk"
                              :value="val.fit_goods_shaft_pk"
                            >
                              {{ val.fit_goods_shaft_nm }}
                              ({{ val.fit_shaft_price | comma }})
                            </option>
                          </select>
                        </b-col>
                        <b-col sm="3">
                          <select
                            :aria-label="$t('title.common.choose')"
                            v-model="form.c_goodsflextype"
                          >
                            >
                            <option value="">
                              {{ $t("title.common.flex") }}
                            </option>
                            <option
                              :value="form.c_clubflextype1_"
                              v-if="
                                form.c_clubflextype1_ != '0' &&
                                form.c_clubflextype1_ != ''
                              "
                            >
                              {{ form.c_clubflextype1_ | get_balance }}
                            </option>
                            <option
                              :value="form.c_clubflextype2_"
                              v-if="
                                form.c_clubflextype2_ != '0' &&
                                form.c_clubflextype2_ != ''
                              "
                            >
                              {{ form.c_clubflextype2_ | get_balance }}
                            </option>
                            <option
                              :value="form.c_clubflextype3_"
                              v-if="
                                form.c_clubflextype3_ != '0' &&
                                form.c_clubflextype3_ != ''
                              "
                            >
                              {{ form.c_clubflextype3_ | get_balance }}
                            </option>
                            <option
                              :value="form.c_clubflextype4_"
                              v-if="
                                form.c_clubflextype4_ != '0' &&
                                form.c_clubflextype4_ != ''
                              "
                            >
                              {{ form.c_clubflextype4_ | get_balance }}
                            </option>
                            <option
                              :value="form.c_clubflextype5_"
                              v-if="
                                form.c_clubflextype5_ != '0' &&
                                form.c_clubflextype5_ != ''
                              "
                            >
                              {{ form.c_clubflextype5_ | get_balance }}
                            </option>
                          </select>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="col-sm-3">
                      <span>{{ $t("page.goods.grip") }}</span>
                    </div>
                    <div class="col-sm-9">
                      <select
                        :aria-label="$t('page.goods.grip_write')"
                        v-model="form.c_gripno"
                      >
                        >
                        <option value="0">
                          {{ $t("title.common.grip_name") }}
                        </option>
                        <option
                          v-for="val in c_gripitems"
                          :key="'c_gripno' + val.fit_goods_grip_pk"
                          :value="val.fit_goods_grip_pk"
                        >
                          {{ val.fit_goods_grip_nm }}
                          ({{ val.fit_grip_price | comma }})
                        </option>
                      </select>
                    </div>

                    <div class="col-sm-3 border-sm-0">
                      <span>{{ $t("title.common.goods_price") }}</span>
                      <mark
                        class="ms-1 fs-12 fw-normal font-monospace text-price"
                        >({{ $t("text.common.unit") }} :
                        {{ $t("text.common.unit_won") }})
                      </mark>
                    </div>
                    <div class="col-sm-9">
                      <b-row class="g-2">
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            id="sale_price"
                            :placeholder="$t('title.common.retail_price')"
                            class="text-price"
                            v-model="form.c_goodsprice"
                          ></b-form-input>
                          <label for="sale_price">{{
                            $t("title.common.retail_price")
                          }}</label>
                        </b-col>
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            id="shop_price"
                            :placeholder="$t('title.common.location_price')"
                            class="text-price"
                            v-model="form.c_goodsxgolfprice"
                          ></b-form-input>
                          <label for="shop_price">{{
                            $t("title.common.location_price")
                          }}</label>
                        </b-col>
                        <b-col sm="4" class="form-floating">
                          <b-form-input
                            id="supply_price"
                            :placeholder="$t('title.common.xgolf_price')"
                            class="text-price"
                            v-model="form.c_goodsshopprice"
                          ></b-form-input>
                          <label for="supply_price">{{
                            $t("title.common.xgolf_price")
                          }}</label>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </section>
                <!-- //기본 정보 등록 -->

                <section class="reg-spec">
                  <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                  <b-table-simple responsive table-class="border-0">
                    <b-thead>
                      <b-tr>
                        <b-th sticky-column>{{
                          $t("title.common.club_type")
                        }}</b-th>
                        <b-th
                          v-for="(val, idx) in 10"
                          :key="'c_clubnotype' + val"
                        >
                          <select
                            :aria-label="$t('title.common.choose')"
                            v-model="form.c_clubnotype[idx]"
                          >
                            <option value="undefined">
                              {{ $t("title.common.choose") }}
                            </option>
                            <option
                              v-for="val in fittingreqinfo.clubtypelist"
                              :key="'clubtypelist' + idx + +val.clubtype_pk"
                              :value="val.clubtype_code"
                            >
                              {{ val.clubtype_code_name }}
                            </option>
                          </select>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.loft") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubloft' + idx + val"
                        >
                          <b-form-input
                            v-model="form.c_clubloft[idx]"
                            :disabled="
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                          >
                          </b-form-input>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.lie") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clublie' + idx + +val"
                        >
                          <b-form-input
                            v-model="form.c_clublie[idx]"
                            :disabled="
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type > 3">
                        <b-th sticky-column
                          >{{ $t("title.common.sole") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubsole' + idx + val"
                          ><b-form-input
                            v-model="form.c_clubsole[idx]"
                            :disabled="
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type < 4">
                        <b-th sticky-column
                          >{{ $t("title.common.head_volume") }}(㎤)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubheadvolume' + idx + val"
                          ><b-form-input
                            v-model="form.c_clubheadvolume[idx]"
                            :disabled="
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.fp") }}(mm)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubfp' + idx + val"
                          ><b-form-input
                            v-model="form.c_clubfp[idx]"
                            :disabled="
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.length") }}(Inch)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clublength' + idx + val"
                          ><b-form-input
                            v-model="form.c_clublength[idx]"
                            :disabled="
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column rowspan="5">
                          <div class="sticky-column-rowspan">
                            <div>{{ $t("title.common.balance") }}</div>
                            <div class="flex-column">
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.c_clubflextype1"
                                  :disabled="
                                    form.c_clubflextype1_ !=
                                    form.c_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'c_clubflextype1' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.c_clubflextype2"
                                  :disabled="
                                    form.c_clubflextype2_ !=
                                    form.c_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'c_clubflextype2' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.c_clubflextype3"
                                  :disabled="
                                    form.c_clubflextype3_ !=
                                    form.c_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'c_clubflextype3' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div>
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.c_clubflextype4"
                                  :disabled="
                                    form.c_clubflextype4_ !=
                                    form.c_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'c_clubflextype4' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                              <div class="py-2 pe-2">
                                <select
                                  :aria-label="$t('title.common.choose')"
                                  v-model="form.c_clubflextype5"
                                  :disabled="
                                    form.c_clubflextype5_ !=
                                    form.c_goodsflextype
                                  "
                                >
                                  <option value="0">
                                    {{ $t("title.common.flex") }}
                                  </option>
                                  <option
                                    v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                                    :key="'c_clubflextype5' + val"
                                    :value="idx + 1"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </b-th>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubflextitle1' + val"
                          ><b-form-input
                            :disabled="
                              form.c_clubflextype1_ != form.c_goodsflextype ||
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                            v-model="form.c_clubflextitle1[idx]"
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubflextitle2' + val"
                          ><b-form-input
                            :disabled="
                              form.c_clubflextype2_ != form.c_goodsflextype ||
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                            v-model="form.c_clubflextitle2[idx]"
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubflextitle3' + val"
                          ><b-form-input
                            :disabled="
                              form.c_clubflextype3_ != form.c_goodsflextype ||
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                            v-model="form.c_clubflextitle3[idx]"
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubflextitle4' + val"
                          ><b-form-input
                            :disabled="
                              form.c_clubflextype4_ != form.c_goodsflextype ||
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                            v-model="form.c_clubflextitle4[idx]"
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubflextitle5' + val"
                          ><b-form-input
                            :disabled="
                              form.c_clubflextype5_ != form.c_goodsflextype ||
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                            v-model="form.c_clubflextitle5[idx]"
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column class="border-0"
                          >{{ $t("title.common.weight") }}(g)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in 10"
                          :key="'c_clubweight' + val"
                          ><b-form-input
                            v-model="form.c_clubweight[idx]"
                            :disabled="
                              form.c_clubnotype[idx] == undefined ||
                              form.c_clubnotype[idx] == 'undefined'
                            "
                          ></b-form-input
                        ></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </section>
                <!-- 스펙 등록 -->

                <section class="reg-etc">
                  <div class="row gx-4 gy-5 border-0">
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.shaft") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          c_shaftitems.find(
                            (v) => v.fit_goods_shaft_pk == form.c_shaftno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              c_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.c_shaftno
                              ).fit_goods_shaft_nm
                            }}
                            /
                            {{
                              c_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.c_shaftno
                              ).fit_shaft_material | get_shaft_material
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                c_shaftitems.find(
                                  (v) => v.fit_goods_shaft_pk == form.c_shaftno
                                ).fit_goods_shaft_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.shaft')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_balance | get_balance
                                  }}
                                </td>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_weight
                                  }}
                                </td>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_torque
                                  }}
                                </td>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_kickpoint
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          c_gripitems.find(
                            (v) => v.fit_goods_grip_pk == form.c_gripno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              c_gripitems.find(
                                (v) => v.fit_goods_grip_pk == form.c_gripno
                              ).fit_goods_grip_nm
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                c_gripitems.find(
                                  (v) => v.fit_goods_grip_pk == form.c_gripno
                                ).fit_goods_grip_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.grip')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                    c_gripitems.find(
                                      (v) =>
                                        v.fit_goods_grip_pk == form.c_gripno
                                    ).fit_goods_grip_standard
                                  }}
                                </td>
                                <td>
                                  {{
                                    c_gripitems.find(
                                      (v) =>
                                        v.fit_goods_grip_pk == form.c_gripno
                                    ).fit_goods_grip_weight
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card h-100" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- //샤프트 & 그립 등록 -->

                <div class="btn-bottom-wrapper">
                  <b-button-group>
                    <b-button
                      @click="tabIndex--"
                      size="xl"
                      variant="outline-secondary btn-prev"
                      :aria-label="$t('button.common.prev')"
                    >
                      <span class="material-icons-outlined"
                        >arrow_back_ios</span
                      >
                    </b-button>
                    <!-- <b-button
                      @click="tabIndex++"
                      size="xl"
                      variant="outline-secondary btn-next"
                      disabled
                      aria-label="다음"
                    >
                      <span class="material-icons-outlined"
                        >arrow_forward_ios</span
                      >
                    </b-button> -->
                  </b-button-group>
                  <b-button
                    size="xl"
                    variant="secondary"
                    :aria-label="$t('button.fitting.preview')"
                    @click="$refs['preViewModal'].show()"
                    >{{ $t("button.fitting.preview") }}</b-button
                  >
                  <b-button
                    size="xl"
                    variant="primary mb-0"
                    :aria-label="$t('button.fitting.fitting_done')"
                    type="submit"
                  >
                    {{ $t("button.fitting.fitting_done") }}
                  </b-button>
                  <b-button
                    @click="tabIndex++"
                    size="xl"
                    variant="outline-secondary btn-next ms-0"
                    disabled
                    :aria-label="$t('button.common.next')"
                  >
                    <span class="material-icons-outlined"
                      >arrow_forward_ios</span
                    >
                  </b-button>
                </div>
                <!-- //btn-bottom-wrapper --> </b-tab
              ><!-- //tabIndex: 3, 보급형 -->

              <div class="btn-bottom-wrapper">
                <b-button
                  size="xl"
                  variant="outline-primary"
                  :aria-label="$t('button.common.list')"
                  @click="
                    $router.push({
                      name: 'FittingList',
                      query: { goodstype: goodstype },
                    })
                  "
                  >{{ $t("button.common.list") }}</b-button
                >
              </div>
            </b-tabs>
          </b-card>
        </b-form>

        <!-- 팝업, 피팅 미리보기 -->
        <b-modal
          id="modal-review-whole"
          ref="preViewModal"
          centered
          scrollable
          dialog-class="modal-fullscreen"
          footer-class="btn-bottom-wrapper has-pagination"
        >
          <template #modal-header="{ close }">
            <h5 class="modal-title">
              {{ $t("page.fitting.fitting_preview") }}
            </h5>
            <b-button variant="close" @click="close()"></b-button>
          </template>

          <template>
            <section class="adv mt-0">
              <h6 class="sub-title">{{ $t("page.fitting.fitting_count") }}</h6>
              <div class="row g-0">
                <div class="col-md-5">
                  <div
                    class="col adv-item adv-video"
                    :class="{ 'has-item': setad.moviead.adno }"
                  >
                    <img
                      v-if="setad.moviead.adno"
                      :src="setad.moviead.adimagepath | get_img"
                    />
                  </div>
                </div>
                <div class="col-md-7 flex-wrap">
                  <div
                    class="col-12 adv-item adv-title justify-content-center mb-2"
                    :class="{ 'has-item': setad.titlead.adno }"
                  >
                    <img
                      v-if="setad.titlead.adno"
                      class="align-self-center"
                      :src="setad.titlead.adimagepath | get_img"
                    />
                  </div>
                  <div class="row g-0 flex-grow-1">
                    <div
                      class="col adv-item adv-goods flex-sm-wrap"
                      :class="{ 'has-item': setad.goodsad1.adno }"
                    >
                      <img
                        v-if="setad.goodsad1.adno"
                        :src="setad.goodsad1.adimagepath | get_img"
                      />
                      <p class="goods-name" v-if="setad.goodsad1.adno">
                        <span>{{ setad.goodsad1.adtitle }}</span>
                      </p>
                    </div>

                    <div
                      class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-wrap"
                      :class="{ 'has-item': setad.goodsad2.adno }"
                    >
                      <img
                        v-if="setad.goodsad2.adno"
                        :src="setad.goodsad2.adimagepath | get_img"
                      />
                      <p class="goods-name" v-if="setad.goodsad2.adno">
                        <span>{{ setad.goodsad2.adtitle }}</span>
                      </p>
                    </div>
                    <div
                      class="col adv-item adv-goods flex-sm-wrap"
                      :class="{ 'has-item': setad.goodsad3.adno }"
                    >
                      <img
                        v-if="setad.goodsad3.adno"
                        :src="setad.goodsad3.adimagepath | get_img"
                      />
                      <p class="goods-name" v-if="setad.goodsad3.adno">
                        <span>{{ setad.goodsad3.adtitle }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- //광고등록 -->

            <!-- 탭 영역 시작 -->
            <div class="tab-sub mt-4">
              <swiper
                :options="{
                  slidesPerView: 'auto',
                  freeMode: true,
                  loop: false,
                  initialSlide: preViewTabIndex,
                }"
              >
                <swiper-slide
                  :class="{
                    active: preViewTabIndex == 0,
                  }"
                >
                  <div @click="preViewTabIndex = 0">
                    {{ $t("page.fitting.fitting_report") }}
                  </div>
                </swiper-slide>
                <swiper-slide :class="{ active: preViewTabIndex == 1 }">
                  <div @click="preViewTabIndex = 1">
                    {{ $t("page.fitting.one_point_lesson") }}
                  </div>
                </swiper-slide>
                <swiper-slide :class="{ active: preViewTabIndex == 2 }">
                  <div @click="preViewTabIndex = 2">
                    {{ $t("page.fitting.premium_goods") }}
                  </div>
                </swiper-slide>
                <swiper-slide :class="{ active: preViewTabIndex == 3 }">
                  <div @click="preViewTabIndex = 3">
                    {{ $t("page.fitting.entry_goods") }}
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <!-- tab-sub -->

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show"
                :class="{ active: preViewTabIndex == 0 }"
              >
                <section>
                  <h6 class="sub-title">
                    {{ $t("title.common.fitting_total_analysis") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-5 col-md-4">
                      <span>{{ $t("title.common.fitting_analysis") }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <textarea
                        class="form-control"
                        v-model="form.resulttxt"
                        readonly
                      ></textarea>
                    </div>
                    <div class="col-sm-5 col-md-4 border-sm-0">
                      <span>{{ $t("title.common.attachment") }}111</span>
                    </div>

                    <div class="col-sm-7 col-md-8">
                      <span v-if="form.resultfilename">
                        <span>{{ form.resultfilename.name }}</span>
                      </span>

                      <span
                        v-if="
                          fittingreqinfo.fit_ans_file_originname &&
                          !form.resultfilename
                        "
                      >
                        <a :href="fittingreqinfo.download_link">
                          <i class="material-icons-outlined">file_download</i>
                          <span>{{
                            fittingreqinfo.fit_ans_file_originname
                          }}</span>
                        </a>
                      </span>
                    </div>
                  </div>
                </section>
                <!-- //피팅 종합 분석 -->

                <section class="fitting-anls">
                  <h6 class="sub-title">
                    {{ $t("title.common.pitch_club_analysis") }}
                    <mark class="font-monospace text-orange"
                      >({{ fittingreqinfo.fit_club_type | get_club_type }}
                      {{ $t("text.common.standard") }})</mark
                    >
                  </h6>
                  <div class="card-group">
                    <div class="row g-3 flex-fill">
                      <!-- 공통 : 구질분석 -->
                      <div class="col-md-4">
                        <div class="card h-100">
                          <div class="card-body">
                            <Radar
                              class="pitch-anls"
                              :chart-data="radardata"
                              :chart-options="radaroptions"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.distance") }}
                                  </p>
                                  <input
                                    type="text"
                                    class="text-truncate"
                                    :aria-label="$t('title.common.distance')"
                                    v-model="form.distancegrade"
                                    readonly
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-orange"
                                      >2</small
                                    >{{ $t("title.common.directionality") }}
                                  </p>
                                  <input
                                    type="text"
                                    class="text-truncate"
                                    :aria-label="
                                      $t('title.common.directionality')
                                    "
                                    v-model="form.directiongrade"
                                    readonly
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-pink"
                                      >3</small
                                    >{{ $t("title.common.accuracy") }}
                                  </p>
                                  <input
                                    type="text"
                                    class="text-truncate"
                                    :aria-label="$t('title.common.accuracy')"
                                    v-model="form.accuracygrade"
                                    readonly
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-purple"
                                      >4</small
                                    >{{ $t("title.common.posture") }}
                                  </p>
                                  <input
                                    type="text"
                                    class="text-truncate"
                                    :aria-label="$t('title.common.posture')"
                                    v-model="form.positiongrade"
                                    readonly
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-blue"
                                      >5</small
                                    >{{ $t("title.common.impact") }}
                                  </p>
                                  <input
                                    type="text"
                                    class="text-truncate"
                                    :aria-label="$t('title.common.impact')"
                                    v-model="form.impactgrade"
                                    readonly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                      <!-- //공통 : 구질분석 -->
                      <!-- 클럽분석 : 드라이버, 우드, 유틸 -->
                      <div
                        class="col-md-4 border-end-md border-start-md"
                        v-if="fittingreqinfo.fit_club_type < 4"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_dvr_tv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.head_volume") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      :placeholder="
                                        $t('title.common.head_volume')
                                      "
                                      :aria-label="
                                        $t('title.common.head_volume')
                                      "
                                      v-model="form.headvolume"
                                      readonly
                                    />
                                    <label for="head-volume" class="ms-1"
                                      >㎤</label
                                    >
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-orange"
                                      >2</small
                                    >{{ $t("title.common.loft") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="loft"
                                      :placeholder="$t('title.common.loft')"
                                      :aria-label="$t('title.common.loft')"
                                      v-model="form.loft"
                                      readonly
                                    />
                                    <label for="loft" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>

                      <div
                        class="col-md-4"
                        v-if="fittingreqinfo.fit_club_type < 4"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_dvr_fv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.lie") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="lie"
                                      :placeholder="$t('title.common.lie')"
                                      :aria-label="$t('title.common.lie')"
                                      v-model="form.lie"
                                      readonly
                                    />
                                    <label for="lie" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                      <!-- //클럽분석 : 드라이버, 우드, 유틸 -->

                      <!-- 클럽분석 : 아이언, 웨지 -->
                      <div
                        class="col-md-4 border-end-md border-start-md"
                        v-if="fittingreqinfo.fit_club_type > 3"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_iron_tv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.bounce") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="bounce"
                                      :placeholder="$t('title.common.bounce')"
                                      :aria-label="$t('title.common.bounce')"
                                      v-model="form.bounce"
                                      readonly
                                    />
                                    <label for="bounce" class="ms-1"
                                      >&deg;</label
                                    >
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-orange"
                                      >2</small
                                    >{{ $t("title.common.fp") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="FP"
                                      :placeholder="$t('title.common.fp')"
                                      :aria-label="$t('title.common.fp')"
                                      v-model="form.fp"
                                      readonly
                                    />
                                    <label for="FP" class="ms-1">㎜</label>
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-pink"
                                      >3</small
                                    >{{ $t("title.common.sole") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="sole"
                                      :placeholder="$t('title.common.sole')"
                                      :aria-label="$t('title.common.sole')"
                                      v-model="form.sole"
                                      readonly
                                    />
                                    <label for="sole" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-purple"
                                      >4</small
                                    >{{ $t("title.common.loft") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="loft"
                                      :placeholder="$t('title.common.loft')"
                                      :aria-label="$t('title.common.loft')"
                                      v-model="form.loft"
                                      readonly
                                    />
                                    <label for="loft" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>

                      <div
                        class="col-md-4"
                        v-if="fittingreqinfo.fit_club_type > 3"
                      >
                        <div class="card h-100">
                          <div
                            class="card-body d-flex justify-content-center p-0"
                          >
                            <img
                              src="/img/fit_anls_iron_fv.png"
                              class="align-self-center img-fluid"
                              :alt="$t('title.common.club_analysis')"
                            />
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                          >
                            <div class="row gx-2 gy-2 text-nowrap fs-14">
                              <div class="col">
                                <div class="d-flex flex-column">
                                  <p class="mb-2">
                                    <small class="rounded-circle bg-green"
                                      >1</small
                                    >{{ $t("title.common.lie") }}
                                  </p>
                                  <p class="d-flex align-items-center">
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      id="loft"
                                      :placeholder="$t('title.common.lie')"
                                      :aria-label="$t('title.common.lie')"
                                      v-model="form.lie"
                                      readonly
                                    />
                                    <label for="loft" class="ms-1">&deg;</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                      <!-- //클럽분석 : 아이언, 웨지 -->
                    </div>
                  </div>
                </section>
                <!-- //구질 및 클럽 분석  -->
              </div>
              <!-- //tab-pane -->
              <div
                class="tab-pane fade show"
                :class="{ active: preViewTabIndex == 1 }"
              >
                <section class="fitting-lesson">
                  <h6 class="sub-title">
                    {{ $t("title.fitting.one_point_lesson_write") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson1" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        class="mb-2"
                        :value="form.analysisresulttxt['lesson1']"
                        plaintext
                      ></b-form-textarea>

                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel1']"
                          readonly
                          no-border
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson2" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        plaintext
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson2']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel2']"
                          no-border
                          readonly
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson3" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        plaintext
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson3']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel3']"
                          no-border
                          readonly
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson4" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        plaintext
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson4']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel4']"
                          no-border
                          readonly
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson5" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        plaintext
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson5']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel5']"
                          no-border
                          readonly
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson6" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        plaintext
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson6']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel6']"
                          no-border
                          readonly
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4">
                      <span>{{ "lesson7" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        plaintext
                        class="mb-2"
                        v-model="form.analysisresulttxt['lesson7']"
                      ></b-form-textarea>
                      <div class="rating-wrapper d-sm-inline-flex">
                        <label for="rating-address">{{
                          $t("title.common.grade_point_by_action")
                        }}</label>
                        <b-form-rating
                          id="rating-address"
                          v-model="form.analysisresultgrade['lessonlevel7']"
                          no-border
                          readonly
                          show-value
                          show-value-max
                          class="py-1"
                        ></b-form-rating>
                      </div>
                    </div>

                    <div class="col-sm-5 col-md-4 border-sm-0">
                      <span>{{ "lessontotal" | get_lesson_title }}</span>
                    </div>
                    <div class="col-sm-7 col-md-8">
                      <b-form-textarea
                        plaintext
                        v-model="form.analysisresulttxt['lessontotal']"
                      ></b-form-textarea>
                    </div>
                  </div>
                </section>
              </div>
              <!-- //tabIndex: 1, 원포인트레슨 -->

              <div
                class="tab-pane fade show"
                :class="{ active: preViewTabIndex == 2 }"
              >
                <section>
                  <h6 class="sub-title">
                    <span class="brand-name fw-bold">{{
                      p_item.fit_brand_nm || ""
                    }}</span
                    >{{ $t("title.fitting.recommended_by") }}
                    <span class="text-green">{{
                      $t("page.fitting.premium_goods")
                    }}</span>
                  </h6>
                  <div class="card">
                    <div class="row g-0 table-type header-col">
                      <div class="col-sm-3">
                        <div
                          class="d-flex flex-fill justify-content-center h-100 bg-white"
                        >
                          <img
                            :src="p_item.fit_goods_image1 | get_img"
                            class="img-fluid rounded-0"
                          />
                        </div>
                      </div>
                      <div
                        class="col-sm-9 d-flex flex-column align-items-stretch"
                      >
                        <div class="card-body px-0 px-sm-3">
                          <h4 class="card-title">
                            {{ p_item.fit_goods_nm }}
                          </h4>
                          <div class="row g-0 row-cols-1 row-cols-sm-2">
                            <div
                              class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 fw-bold text-black"
                            >
                              {{ $t("title.common.retail_price") }}
                            </div>
                            <div class="col-sm-8 py-0 py-sm-2">
                              <h5 class="text-price">
                                &#8361;
                                {{ p_item.fit_sale_price | comma }}
                              </h5>
                            </div>

                            <div
                              class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                            >
                              {{ $t("title.common.benefit") }}
                            </div>
                            <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                              {{ p_item.fit_goods_info }}
                              <!-- 미등록 -->
                            </div>
                          </div>
                        </div>
                        <!-- //card-body -->
                        <div
                          class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                        >
                          <div
                            class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                          >
                            <b-button
                              size="md"
                              variant="primary"
                              :aria-label="$t('button.fitting.purchase_goods')"
                            >
                              {{ $t("button.fitting.purchase_goods") }}
                            </b-button>
                          </div>
                        </div>
                        <!-- //card-footer -->
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h6 class="sub-title">
                    {{ $t("page.sales.sales_goods_detail") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <img
                      v-if="p_item.fit_goods_image2"
                      :src="p_item.fit_goods_image2 | get_img"
                      class="img-fluid rounded-0"
                    />
                    <img
                      v-if="p_item.fit_goods_image3"
                      :src="p_item.fit_goods_image3 | get_img"
                      class="img-fluid rounded-0"
                    />
                  </div>
                </section>

                <section class="reg-spec">
                  <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                  <b-table-simple responsive table-class="border-0">
                    <b-thead>
                      <b-tr>
                        <b-th sticky-column>{{
                          $t("title.common.club_type")
                        }}</b-th>
                        <b-th
                          v-for="(val, idx) in form.p_clubnotype"
                          :key="'p_clubnotype' + idx"
                        >
                          {{
                            fittingreqinfo.clubtypelist.find(
                              (v) => v.clubtype_code == val
                            ) &&
                            fittingreqinfo.clubtypelist.find(
                              (v) => v.clubtype_code == val
                            ).clubtype_code_name
                          }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.loft") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.p_clubloft"
                          :key="'p_clubloft' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.lie") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.p_clublie"
                          :key="'p_clublie' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type > 3">
                        <b-th sticky-column
                          >{{ $t("title.common.sole") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.p_clubsole"
                          :key="'p_clubsole' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type < 4">
                        <b-th sticky-column
                          >{{ $t("title.common.head_volume") }}(㎤)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.p_clubheadvolume"
                          :key="'p_clubheadvolume' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.fp") }}(mm)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.p_clubfp"
                          :key="'p_clubfp' + idx"
                          >{{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.length") }}(Inch)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.p_clublength"
                          :key="'p_clublength' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column rowspan="5">
                          <div class="sticky-column-rowspan">
                            <div>{{ $t("title.common.balance") }}</div>
                            <div class="flex-column">
                              <div
                                v-if="
                                  form.p_clubflextype1 == form.p_goodsflextype
                                "
                              >
                                {{ form.p_clubflextype1 | get_balance }}
                              </div>
                              <div
                                v-if="
                                  form.p_clubflextype2 == form.p_goodsflextype
                                "
                              >
                                {{ form.p_clubflextype2 | get_balance }}
                              </div>
                              <div
                                v-if="
                                  form.p_clubflextype3 == form.p_goodsflextype
                                "
                              >
                                {{ form.p_clubflextype3 | get_balance }}
                              </div>
                              <div
                                v-if="
                                  form.p_clubflextype4 == form.p_goodsflextype
                                "
                              >
                                {{ form.p_clubflextype4 | get_balance }}
                              </div>
                              <div
                                class="py-2 pe-2"
                                v-if="
                                  form.p_clubflextype5 == form.p_goodsflextype
                                "
                              >
                                {{ form.p_clubflextype5 | get_balance }}
                              </div>
                            </div>
                          </div>
                        </b-th>
                        <template v-for="(val, idx) in form.p_clubflextitle1">
                          <b-td
                            :key="'p_clubflextitle1' + idx"
                            v-if="form.p_clubflextype1 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.p_clubflextitle2">
                          <b-td
                            :key="'p_clubflextitle2' + idx"
                            v-if="form.p_clubflextype2 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.p_clubflextitle3">
                          <b-td
                            :key="'p_clubflextitle3' + idx"
                            v-if="form.p_clubflextype3 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.p_clubflextitle4">
                          <b-td
                            :key="'p_clubflextitle4' + idx"
                            v-if="form.p_clubflextype4 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.p_clubflextitle5">
                          <b-td
                            :key="'p_clubflextitle5' + idx"
                            v-if="form.p_clubflextype5 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column class="border-0"
                          >{{ $t("title.common.weight") }}(g)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.p_clubweight"
                          :key="'p_clubweight' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </section>
                <!-- 스펙 등록 -->

                <section class="reg-etc">
                  <div class="row gx-4 gy-5 border-0">
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.shaft") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          p_shaftitems.find(
                            (v) => v.fit_goods_shaft_pk == form.p_shaftno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              p_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.p_shaftno
                              ).fit_goods_shaft_nm
                            }}
                            /
                            {{
                              p_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.p_shaftno
                              ).fit_shaft_material | get_shaft_material
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                p_shaftitems.find(
                                  (v) => v.fit_goods_shaft_pk == form.p_shaftno
                                ).fit_goods_shaft_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.shaft')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_balance | get_balance
                                  }}
                                </td>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_weight
                                  }}
                                </td>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_torque
                                  }}
                                </td>
                                <td>
                                  {{
                                    p_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.p_shaftno
                                    ).fit_goods_shaft_kickpoint
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card h-100" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colspan="4" class="text-center">
                                  {{ $t("text.common.no_data") }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          p_gripitems.find(
                            (v) => v.fit_goods_grip_pk == form.p_gripno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              p_gripitems.find(
                                (v) => v.fit_goods_grip_pk == form.p_gripno
                              ).fit_goods_grip_nm
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                p_gripitems.find(
                                  (v) => v.fit_goods_grip_pk == form.p_gripno
                                ).fit_goods_grip_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.grip')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  { p_gripitems.find( (v) => v.fit_goods_grip_pk
                                  == form.p_gripno ).fit_goods_grip_standard }}
                                </td>
                                <td>
                                  {{
                                    p_gripitems.find(
                                      (v) =>
                                        v.fit_goods_grip_pk == form.p_gripno
                                    ).fit_goods_grip_weight
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card h-100" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colspan="2" class="text-center">
                                  {{ $t("text.common.no_data") }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- //tabIndex: 2, 고급형 -->
              </div>

              <div
                class="tab-pane fade show"
                :class="{ active: preViewTabIndex == 3 }"
              >
                <section>
                  <h6 class="sub-title">
                    <span class="brand-name fw-bold">{{
                      c_item.fit_brand_nm || ""
                    }}</span
                    >{{ $t("title.fitting.recommended_by") }}
                    <span class="text-green">{{
                      $t("page.fitting.entry_goods")
                    }}</span>
                  </h6>
                  <div class="card">
                    <div class="row g-0 table-type header-col">
                      <div class="col-sm-3">
                        <div
                          class="d-flex flex-fill justify-content-center h-100 bg-white"
                        >
                          <img
                            :src="c_item.fit_goods_image1 | get_img"
                            class="img-fluid rounded-0"
                          />
                        </div>
                      </div>
                      <div
                        class="col-sm-9 d-flex flex-column align-items-stretch"
                      >
                        <div class="card-body px-0 px-sm-3">
                          <h4 class="card-title">
                            {{ c_item.fit_goods_nm }}
                          </h4>
                          <div class="row g-0 row-cols-1 row-cols-sm-2">
                            <div
                              class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 fw-bold text-black"
                            >
                              {{ $t("title.common.retail_price") }}
                            </div>
                            <div class="col-sm-8 py-0 py-sm-2">
                              <h5 class="text-price">
                                &#8361;
                                {{ c_item.fit_sale_price | comma }}
                              </h5>
                            </div>

                            <div
                              class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                            >
                              {{ $t("title.common.benefit") }}
                            </div>
                            <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                              {{ c_item.fit_goods_info }}
                              <!-- 미등록 -->
                            </div>
                          </div>
                        </div>
                        <!-- //card-body -->
                        <div
                          class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                        >
                          <div
                            class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                          >
                            <b-button
                              size="md"
                              variant="primary"
                              :aria-label="$t('button.fitting.purchase_goods')"
                            >
                              {{ $t("button.fitting.purchase_goods") }}
                            </b-button>
                          </div>
                        </div>
                        <!-- //card-footer -->
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h6 class="sub-title">
                    {{ $t("page.sales.sales_goods_detail") }}
                  </h6>
                  <div class="row g-0 table-type header-col">
                    <img
                      v-if="c_item.fit_goods_image2"
                      :src="c_item.fit_goods_image2 | get_img"
                      class="img-fluid rounded-0"
                    />
                    <img
                      v-if="c_item.fit_goods_image3"
                      :src="c_item.fit_goods_image3 | get_img"
                      class="img-fluid rounded-0"
                    />
                  </div>
                </section>

                <section class="reg-spec">
                  <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                  <b-table-simple responsive table-class="border-0">
                    <b-thead>
                      <b-tr>
                        <b-th sticky-column>{{
                          $t("title.common.club_type")
                        }}</b-th>
                        <b-th
                          v-for="(val, idx) in form.c_clubnotype"
                          :key="'c_clubnotype' + idx"
                        >
                          {{ val }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.loft") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.c_clubloft"
                          :key="'c_clubloft' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.lie") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.c_clublie"
                          :key="'c_clublie' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type > 3">
                        <b-th sticky-column
                          >{{ $t("title.common.sole") }}(&deg;)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.c_clubsole"
                          :key="'c_clubsole' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr v-if="fittingreqinfo.fit_club_type < 4">
                        <b-th sticky-column
                          >{{ $t("title.common.head_volume") }}(㎤)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.c_clubheadvolume"
                          :key="'c_clubheadvolume' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.fp") }}(mm)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.c_clubfp"
                          :key="'c_clubfp' + idx"
                          >{{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column
                          >{{ $t("title.common.length") }}(Inch)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.c_clublength"
                          :key="'c_clublength' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column rowspan="5">
                          <div class="sticky-column-rowspan">
                            <div>{{ $t("title.common.balance") }}</div>
                            <div class="flex-column">
                              <div
                                v-if="
                                  form.c_clubflextype1 == form.p_goodsflextype
                                "
                              >
                                {{ form.c_clubflextype1 | get_balance }}
                              </div>
                              <div
                                v-if="
                                  form.c_clubflextype2 == form.p_goodsflextype
                                "
                              >
                                {{ form.c_clubflextype2 | get_balance }}
                              </div>
                              <div
                                v-if="
                                  form.c_clubflextype3 == form.p_goodsflextype
                                "
                              >
                                {{ form.c_clubflextype3 | get_balance }}
                              </div>
                              <div
                                v-if="
                                  form.c_clubflextype4 == form.p_goodsflextype
                                "
                              >
                                {{ form.c_clubflextype4 | get_balance }}
                              </div>
                              <div
                                class="py-2 pe-2"
                                v-if="
                                  form.c_clubflextype5 == form.p_goodsflextype
                                "
                              >
                                {{ form.c_clubflextype5 | get_balance }}
                              </div>
                            </div>
                          </div>
                        </b-th>
                        <template v-for="(val, idx) in form.c_clubflextitle1">
                          <b-td
                            :key="'c_clubflextitle1' + idx"
                            v-if="form.c_clubflextype1 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.c_clubflextitle2">
                          <b-td
                            :key="'c_clubflextitle2' + idx"
                            v-if="form.c_clubflextype2 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.c_clubflextitle3">
                          <b-td
                            :key="'c_clubflextitle3' + idx"
                            v-if="form.c_clubflextype3 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.c_clubflextitle4">
                          <b-td
                            :key="'c_clubflextitle4' + idx"
                            v-if="form.c_clubflextype4 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <template v-for="(val, idx) in form.c_clubflextitle5">
                          <b-td
                            :key="'c_clubflextitle5' + idx"
                            v-if="form.c_clubflextype5 == form.p_goodsflextype"
                          >
                            {{ val }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <b-th sticky-column class="border-0"
                          >{{ $t("title.common.weight") }}(g)</b-th
                        >
                        <b-td
                          v-for="(val, idx) in form.c_clubweight"
                          :key="'c_clubweight' + idx"
                        >
                          {{ val }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </section>
                <!-- 스펙 등록 -->

                <section class="reg-etc">
                  <div class="row gx-4 gy-5 border-0">
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.shaft") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          c_shaftitems.find(
                            (v) => v.fit_goods_shaft_pk == form.c_shaftno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              c_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.c_shaftno
                              ).fit_goods_shaft_nm
                            }}
                            /
                            {{
                              c_shaftitems.find(
                                (v) => v.fit_goods_shaft_pk == form.c_shaftno
                              ).fit_shaft_material | get_shaft_material
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                c_shaftitems.find(
                                  (v) => v.fit_goods_shaft_pk == form.c_shaftno
                                ).fit_goods_shaft_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.shaft')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_balance | get_balance
                                  }}
                                </td>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_weight
                                  }}
                                </td>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_torque
                                  }}
                                </td>
                                <td>
                                  {{
                                    c_shaftitems.find(
                                      (v) =>
                                        v.fit_goods_shaft_pk == form.c_shaftno
                                    ).fit_goods_shaft_kickpoint
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card h-100" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.flex") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.torque") }}(˚)
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.kick_point") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colspan="4" class="text-center">
                                  {{ $t("text.common.no_data") }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 d-flex flex-column">
                      <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                      <div
                        class="card h-100"
                        v-if="
                          c_gripitems.find(
                            (v) => v.fit_goods_grip_pk == form.c_gripno
                          )
                        "
                      >
                        <div class="card-body d-flex flex-column p-0">
                          <p class="card-title">
                            {{
                              c_gripitems.find(
                                (v) => v.fit_goods_grip_pk == form.c_gripno
                              ).fit_goods_grip_nm
                            }}
                          </p>
                          <div
                            class="d-flex justify-content-center align-items-center h-100 py-3"
                          >
                            <img
                              :src="
                                c_gripitems.find(
                                  (v) => v.fit_goods_grip_pk == form.c_gripno
                                ).fit_goods_grip_image1 | get_img
                              "
                              class="img-fluid"
                              :alt="$t('page.goods.grip')"
                            />
                          </div>
                        </div>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  { c_gripitems.find( (v) => v.fit_goods_grip_pk
                                  == form.c_gripno ).fit_goods_grip_standard }}
                                </td>
                                <td>
                                  {{
                                    c_gripitems.find(
                                      (v) =>
                                        v.fit_goods_grip_pk == form.c_gripno
                                    ).fit_goods_grip_weight
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card h-100" v-else>
                        <div class="card-footer">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {{ $t("title.common.standard") }}
                                </th>
                                <th scope="col">
                                  {{ $t("title.common.weight") }}(g)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colspan="2" class="text-center">
                                  {{ $t("text.common.no_data") }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- 샤프트 & 그립 등록 -->
                <!-- //tabIndex: 3, 보급형 -->
              </div>
            </div>
            <!-- //tab-content -->
            <!-- //탭 영역 끝 -->
          </template>
          <!-- modal-body -->

          <template #modal-footer="{ ok }">
            <div class="d-flex flex-row">
              <b-button
                size="md"
                variant="secondary"
                :aria-label="$t('button.common.confirm')"
                @click="ok()"
              >
                {{ $t("button.common.confirm") }}
              </b-button>
            </div>
          </template>
        </b-modal>

        <!-- 팝업, 피팅 전체 불러오기 -->
        <b-modal
          id="modal-review-whole"
          ref="ansresultModal"
          centered
          scrollable
          dialog-class="modal-fullscreen"
          footer-class="btn-bottom-wrapper has-pagination"
        >
          <template #modal-header="{ close }">
            <h5 class="modal-title">
              {{ $t("page.fitting.fitting_total_history") }}
            </h5>
            <b-button variant="close" @click="close()"></b-button>
          </template>

          <template>
            <div class="tab-main d-flex flex-column flex-sm-row pb-sm-3">
              <ul class="nav nav-pills d-none d-md-flex">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active:
                        ansresult.goodstype == 0 || ansresult.goodstype == '',
                    }"
                    @click="get_ansresultlist(0)"
                    aria-current="page"
                    >{{ $t("filter.common.total") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: ansresult.goodstype == '1',
                    }"
                    @click="get_ansresultlist(1)"
                    >{{ $t("filter.common.driver") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: ansresult.goodstype == '2',
                    }"
                    @click="get_ansresultlist(2)"
                    >{{ $t("filter.common.wood") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: ansresult.goodstype == '3',
                    }"
                    @click="get_ansresultlist(3)"
                    >{{ $t("filter.common.utility") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: ansresult.goodstype == '4',
                    }"
                    @click="get_ansresultlist(4)"
                    >{{ $t("filter.common.iron") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: ansresult.goodstype == '5',
                    }"
                    @click="get_ansresultlist(5)"
                    >{{ $t("filter.common.wedge") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: ansresult.goodstype == '6',
                    }"
                    @click="get_ansresultlist(6)"
                    >{{ $t("filter.common.putter") }}</a
                  >
                </li>
              </ul>

              <div class="d-flex d-md-none flex-fill me-sm-5">
                <select
                  :aria-label="$t('title.common.choose')"
                  v-model="ansresult.goodstype"
                >
                  <option value="0">{{ $t("filter.common.total") }}</option>
                  <option value="1">{{ $t("filter.common.driver") }}</option>
                  <option value="2">{{ $t("filter.common.wood") }}</option>
                  <option value="3">{{ $t("filter.common.utility") }}</option>
                  <option value="4">{{ $t("filter.common.iron") }}</option>
                  <option value="5">{{ $t("filter.common.wedge") }}</option>
                  <option value="6">{{ $t("filter.common.putter") }}</option>
                </select>
              </div>

              <div class="query-block sort-by ms-sm-auto gap-3 my-2 my-sm-0">
                <div
                  :class="{ active: ansresult.findex == 'tf_fit_req.reg_date' }"
                  @click="ansresult.findex = 'tf_fit_req.reg_date'"
                >
                  {{ $t("filter.common.sort_by_recent_fitting") }}
                  <span class="material-icons-outlined">arrow_drop_down</span>
                </div>
                <div
                  :class="{
                    active: ansresult.findex == 'tf_fit_req.fit_req_copy_cnt',
                  }"
                  @click="ansresult.findex = 'tf_fit_req.fit_req_copy_cnt'"
                >
                  {{ $t("filter.common.sort_by_favorite") }}
                  <span class="material-icons-outlined">arrow_drop_down</span>
                </div>
              </div>
            </div>
            <!-- tab-main -->
            <div v-if="ansresultitems.fit_req_pk">
              <section class="adv">
                <h6 class="sub-title">
                  {{ $t("page.fitting.fitting_count") }}
                </h6>
                <div class="row g-0">
                  <div class="col-md-5">
                    <div
                      class="col adv-item adv-video"
                      :class="{ 'has-item': ansresultitems.fit_ad_no_mov }"
                    >
                      <img
                        v-if="ansresultitems.fit_ad_no_mov"
                        :src="
                          ansresultitems.fit_ad_no_mov_info &&
                          ansresultitems.fit_ad_no_mov_info.fit_ad_img | get_img
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-7 flex-wrap">
                    <div
                      class="col-12 adv-item adv-title justify-content-center mb-2"
                      :class="{ 'has-item': ansresultitems.fit_ad_no_title }"
                    >
                      <img
                        v-if="ansresultitems.fit_ad_no_title"
                        class="align-self-center"
                        :src="
                          ansresultitems.fit_ad_no_title_info &&
                          ansresultitems.fit_ad_no_title_info.fit_ad_img
                            | get_img
                        "
                      />
                    </div>
                    <div class="row g-0 flex-grow-1">
                      <div
                        class="col adv-item adv-goods flex-sm-wrap"
                        :class="{ 'has-item': ansresultitems.fit_ad_no_img1 }"
                      >
                        <img
                          v-if="ansresultitems.fit_ad_no_img1"
                          :src="
                            ansresultitems.fit_ad_no_img1_info &&
                            ansresultitems.fit_ad_no_img1_info.fit_ad_img
                              | get_img
                          "
                        />
                        <p
                          class="goods-name"
                          v-if="ansresultitems.fit_ad_no_img1"
                        >
                          <span>{{
                            ansresultitems.fit_ad_no_img1_info &&
                            ansresultitems.fit_ad_no_img1_info.fit_ad_title
                          }}</span>
                        </p>
                      </div>

                      <div
                        class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-wrap"
                        :class="{ 'has-item': ansresultitems.fit_ad_no_img2 }"
                      >
                        <img
                          v-if="ansresultitems.fit_ad_no_img2"
                          :src="
                            ansresultitems.fit_ad_no_img2_info &&
                            ansresultitems.fit_ad_no_img2_info.fit_ad_img
                              | get_img
                          "
                        />
                        <p
                          class="goods-name"
                          v-if="ansresultitems.fit_ad_no_img2"
                        >
                          <span>{{
                            ansresultitems.fit_ad_no_img2_info &&
                            ansresultitems.fit_ad_no_img2_info.fit_ad_title
                          }}</span>
                        </p>
                      </div>
                      <div
                        class="col adv-item adv-goods flex-sm-wrap"
                        :class="{ 'has-item': ansresultitems.fit_ad_no_img3 }"
                      >
                        <img
                          v-if="ansresultitems.fit_ad_no_img3"
                          :src="
                            ansresultitems.fit_ad_no_img3_info &&
                            ansresultitems.fit_ad_no_img3_info.fit_ad_img
                              | get_img
                          "
                        />
                        <p
                          class="goods-name"
                          v-if="ansresultitems.fit_ad_no_img3"
                        >
                          <span>{{
                            ansresultitems.fit_ad_no_img3_info &&
                            ansresultitems.fit_ad_no_img3_info.fit_ad_title
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- //광고등록 -->

              <div class="tab-sub mt-4">
                <swiper
                  :options="{
                    slidesPerView: 'auto',
                    freeMode: true,
                    loop: false,
                    initialSlide: preViewTabIndex,
                  }"
                >
                  <swiper-slide
                    :class="{
                      active: preViewTabIndex == 0,
                    }"
                  >
                    <div @click="preViewTabIndex = 0">
                      {{ $t("page.fitting.fitting_report") }}
                    </div>
                  </swiper-slide>
                  <swiper-slide :class="{ active: preViewTabIndex == 1 }">
                    <div @click="preViewTabIndex = 1">
                      {{ $t("page.fitting.one_point_lesson") }}
                    </div>
                  </swiper-slide>
                  <swiper-slide :class="{ active: preViewTabIndex == 2 }">
                    <div @click="preViewTabIndex = 2">
                      {{ $t("page.fitting.premium_goods") }}
                    </div>
                  </swiper-slide>
                  <swiper-slide :class="{ active: preViewTabIndex == 3 }">
                    <div @click="preViewTabIndex = 3">
                      {{ $t("page.fitting.entry_goods") }}
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <!-- //tab-sub -->

              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show"
                  :class="{ active: preViewTabIndex == 0 }"
                >
                  <section>
                    <h6 class="sub-title">
                      {{ $t("title.common.fitting_total_analysis") }}
                    </h6>
                    <div class="row g-0 table-type header-col">
                      <div class="col-sm-5 col-md-4">
                        <span>{{ $t("title.common.fitting_analysis") }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <textarea
                          class="form-control"
                          v-model="ansresultitems.fit_ans_txt"
                          readonly
                        ></textarea>
                      </div>
                      <div class="col-sm-5 col-md-4 border-sm-0">
                        <span>{{ $t("title.common.attachment") }}222</span>
                      </div>

                      <div class="col-sm-7 col-md-8">
                        <p
                          v-if="ansresultitems.fit_ans_file"
                          class="mt-2 text-subscript text-truncate more"
                        >
                          <a :href="ansresultitems.download_link">
                            <i class="material-icons-outlined">file_download</i>
                            <span>{{
                              ansresultitems.fit_ans_file_originname
                            }}</span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </section>
                  <!-- //피팅 종합 분석 -->

                  <section class="fitting-anls">
                    <h6 class="sub-title">
                      {{ $t("title.common.pitch_club_analysis") }}
                      <mark class="font-monospace text-orange"
                        >({{ ansresultitems.fit_club_type | get_club_type }}
                        {{ $t("text.common.standard") }})</mark
                      >
                    </h6>
                    <div class="card-group">
                      <div class="row g-3 flex-fill">
                        <!-- 공통 : 구질분석 -->
                        <div class="col-md-4">
                          <div class="card h-100">
                            <div class="card-body">
                              <Radar
                                class="pitch-anls"
                                :chart-data="resultradardata"
                                :chart-options="radaroptions"
                              />
                            </div>
                            <!-- //card-body -->
                            <div
                              class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                            >
                              <div class="row gx-2 gy-2 text-nowrap fs-14">
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-green"
                                        >1</small
                                      >{{ $t("title.common.distance") }}
                                    </p>
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      :aria-label="$t('title.common.distance')"
                                      :value="ansresultitems.grade_distance"
                                      readonly
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-orange"
                                        >2</small
                                      >{{ $t("title.common.directionality") }}
                                    </p>
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      :aria-label="
                                        $t('title.common.directionality')
                                      "
                                      v-model="ansresultitems.grade_direction"
                                      readonly
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-pink"
                                        >3</small
                                      >{{ $t("title.common.accuracy") }}
                                    </p>
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      :aria-label="$t('title.common.accuracy')"
                                      v-model="ansresultitems.grade_accuracy"
                                      readonly
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-purple"
                                        >4</small
                                      >{{ $t("title.common.posture") }}
                                    </p>
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      :aria-label="$t('title.common.posture')"
                                      v-model="ansresultitems.grade_position"
                                      readonly
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-blue"
                                        >5</small
                                      >{{ $t("title.common.impact") }}
                                    </p>
                                    <input
                                      type="text"
                                      class="text-truncate"
                                      :aria-label="$t('title.common.impact')"
                                      v-model="ansresultitems.grade_impact"
                                      readonly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- //card-footer -->
                          </div>
                        </div>
                        <!-- //공통 : 구질분석 -->
                        <!-- 클럽분석 : 드라이버, 우드, 유틸 -->
                        <div
                          class="col-md-4 border-end-md border-start-md"
                          v-if="ansresultitems.fit_club_type < 4"
                        >
                          <div class="card h-100">
                            <div
                              class="card-body d-flex justify-content-center p-0"
                            >
                              <img
                                src="/img/fit_anls_dvr_tv.png"
                                class="align-self-center img-fluid"
                                :alt="$t('title.common.club_analysis')"
                              />
                            </div>
                            <!-- //card-body -->
                            <div
                              class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                            >
                              <div class="row gx-2 gy-2 text-nowrap fs-14">
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-green"
                                        >1</small
                                      >{{ $t("title.common.head_volume") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="head-volume"
                                        :placeholder="
                                          $t('title.common.head_volume')
                                        "
                                        :aria-label="
                                          $t('title.common.head_volume')
                                        "
                                        v-model="ansresultitems.club_headvolume"
                                        readonly
                                      />
                                      <label for="head-volume" class="ms-1"
                                        >㎤</label
                                      >
                                    </p>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-orange"
                                        >2</small
                                      >{{ $t("title.common.loft") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="loft"
                                        :placeholder="$t('title.common.loft')"
                                        :aria-label="$t('title.common.loft')"
                                        v-model="ansresultitems.club_loft"
                                        readonly
                                      />
                                      <label for="loft" class="ms-1"
                                        >&deg;</label
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- //card-footer -->
                          </div>
                        </div>

                        <div
                          class="col-md-4"
                          v-if="ansresultitems.fit_club_type < 4"
                        >
                          <div class="card h-100">
                            <div
                              class="card-body d-flex justify-content-center p-0"
                            >
                              <img
                                src="/img/fit_anls_dvr_fv.png"
                                class="align-self-center img-fluid"
                                :alt="$t('title.common.club_analysis')"
                              />
                            </div>
                            <!-- //card-body -->
                            <div
                              class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                            >
                              <div class="row gx-2 gy-2 text-nowrap fs-14">
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-green"
                                        >1</small
                                      >{{ $t("title.common.lie") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="lie"
                                        :placeholder="$t('title.common.lie')"
                                        :aria-label="$t('title.common.lie')"
                                        v-model="ansresultitems.club_lie"
                                        readonly
                                      />
                                      <label for="lie" class="ms-1"
                                        >&deg;</label
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- //card-footer -->
                          </div>
                        </div>
                        <!-- //클럽분석 : 드라이버, 우드, 유틸 -->

                        <!-- 클럽분석 : 아이언, 웨지 -->
                        <div
                          class="col-md-4 border-end-md border-start-md"
                          v-if="ansresultitems.fit_club_type > 3"
                        >
                          <div class="card h-100">
                            <div
                              class="card-body d-flex justify-content-center p-0"
                            >
                              <img
                                src="/img/fit_anls_iron_tv.png"
                                class="align-self-center img-fluid"
                                :alt="$t('title.common.club_analysis')"
                              />
                            </div>
                            <!-- //card-body -->
                            <div
                              class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                            >
                              <div class="row gx-2 gy-2 text-nowrap fs-14">
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-green"
                                        >1</small
                                      >{{ $t("title.common.bounce") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="bounce"
                                        :placeholder="$t('title.common.bounce')"
                                        :aria-label="$t('title.common.bounce')"
                                        v-model="ansresultitems.club_bounce"
                                        readonly
                                      />
                                      <label for="bounce" class="ms-1"
                                        >&deg;</label
                                      >
                                    </p>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-orange"
                                        >2</small
                                      >{{ $t("title.common.fp") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="FP"
                                        :placeholder="$t('title.common.fp')"
                                        :aria-label="$t('title.common.fp')"
                                        v-model="ansresultitems.club_fp"
                                        readonly
                                      />
                                      <label for="FP" class="ms-1">㎜</label>
                                    </p>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-pink"
                                        >3</small
                                      >{{ $t("title.common.sole") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="sole"
                                        :placeholder="$t('title.common.sole')"
                                        :aria-label="$t('title.common.sole')"
                                        v-model="ansresultitems.club_sol"
                                        readonly
                                      />
                                      <label for="sole" class="ms-1"
                                        >&deg;</label
                                      >
                                    </p>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-purple"
                                        >4</small
                                      >{{ $t("title.common.loft") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="loft"
                                        :placeholder="$t('title.common.loft')"
                                        :aria-label="$t('title.common.loft')"
                                        v-model="ansresultitems.club_loft"
                                        readonly
                                      />
                                      <label for="loft" class="ms-1"
                                        >&deg;</label
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- //card-footer -->
                          </div>
                        </div>

                        <div
                          class="col-md-4"
                          v-if="ansresultitems.fit_club_type > 3"
                        >
                          <div class="card h-100">
                            <div
                              class="card-body d-flex justify-content-center p-0"
                            >
                              <img
                                src="/img/fit_anls_iron_fv.png"
                                class="align-self-center img-fluid"
                                :alt="$t('title.common.club_analysis')"
                              />
                            </div>
                            <!-- //card-body -->
                            <div
                              class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                            >
                              <div class="row gx-2 gy-2 text-nowrap fs-14">
                                <div class="col">
                                  <div class="d-flex flex-column">
                                    <p class="mb-2">
                                      <small class="rounded-circle bg-green"
                                        >1</small
                                      >{{ $t("title.common.lie") }}
                                    </p>
                                    <p class="d-flex align-items-center">
                                      <input
                                        type="text"
                                        class="text-truncate"
                                        id="loft"
                                        :placeholder="$t('title.common.lie')"
                                        :aria-label="$t('title.common.lie')"
                                        v-model="ansresultitems.club_lie"
                                        readonly
                                      />
                                      <label for="loft" class="ms-1"
                                        >&deg;</label
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- //card-footer -->
                          </div>
                        </div>
                        <!-- //클럽분석 : 아이언, 웨지 -->
                      </div>
                    </div>
                  </section>
                  <!-- //구질 및 클럽 분석  -->
                </div>
                <!-- //tab-pane -->
                <div
                  class="tab-pane fade show"
                  :class="{ active: preViewTabIndex == 1 }"
                >
                  <section class="fitting-lesson">
                    <h6 class="sub-title">
                      {{ $t("title.fitting.one_point_lesson_write") }}
                    </h6>
                    <div class="row g-0 table-type header-col">
                      <div class="col-sm-5 col-md-4">
                        <span>{{ "lesson1" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          class="mb-2"
                          :value="ansresultitems.lesson1"
                          plaintext
                        ></b-form-textarea>

                        <div class="rating-wrapper d-sm-inline-flex">
                          <label for="rating-address">{{
                            $t("title.common.grade_point_by_action")
                          }}</label>
                          <b-form-rating
                            id="rating-address"
                            :value="ansresultitems.lessonlevel1"
                            readonly
                            no-border
                            show-value
                            show-value-max
                            class="py-1"
                          ></b-form-rating>
                        </div>
                      </div>

                      <div class="col-sm-5 col-md-4">
                        <span>{{ "lesson2" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          plaintext
                          class="mb-2"
                          :value="ansresultitems.lesson2"
                        ></b-form-textarea>
                        <div class="rating-wrapper d-sm-inline-flex">
                          <label for="rating-address">{{
                            $t("title.common.grade_point_by_action")
                          }}</label>
                          <b-form-rating
                            id="rating-address"
                            :value="ansresultitems.lessonlevel2"
                            no-border
                            readonly
                            show-value
                            show-value-max
                            class="py-1"
                          ></b-form-rating>
                        </div>
                      </div>

                      <div class="col-sm-5 col-md-4">
                        <span>{{ "lesson3" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          plaintext
                          class="mb-2"
                          :value="ansresultitems.lesson3"
                        ></b-form-textarea>
                        <div class="rating-wrapper d-sm-inline-flex">
                          <label for="rating-address">{{
                            $t("title.common.grade_point_by_action")
                          }}</label>
                          <b-form-rating
                            id="rating-address"
                            :value="ansresultitems.lessonlevel3"
                            no-border
                            readonly
                            show-value
                            show-value-max
                            class="py-1"
                          ></b-form-rating>
                        </div>
                      </div>

                      <div class="col-sm-5 col-md-4">
                        <span>{{ "lesson4" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          plaintext
                          class="mb-2"
                          :value="ansresultitems.lesson4"
                        ></b-form-textarea>
                        <div class="rating-wrapper d-sm-inline-flex">
                          <label for="rating-address">{{
                            $t("title.common.grade_point_by_action")
                          }}</label>
                          <b-form-rating
                            id="rating-address"
                            :value="ansresultitems.lessonlevel4"
                            no-border
                            readonly
                            show-value
                            show-value-max
                            class="py-1"
                          ></b-form-rating>
                        </div>
                      </div>

                      <div class="col-sm-5 col-md-4">
                        <span>{{ "lesson5" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          plaintext
                          class="mb-2"
                          :value="ansresultitems.lesson5"
                        ></b-form-textarea>
                        <div class="rating-wrapper d-sm-inline-flex">
                          <label for="rating-address">{{
                            $t("title.common.grade_point_by_action")
                          }}</label>
                          <b-form-rating
                            id="rating-address"
                            :value="ansresultitems.lessonlevel5"
                            no-border
                            readonly
                            show-value
                            show-value-max
                            class="py-1"
                          ></b-form-rating>
                        </div>
                      </div>

                      <div class="col-sm-5 col-md-4">
                        <span>{{ "lesson6" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          plaintext
                          class="mb-2"
                          :value="ansresultitems.lesson6"
                        ></b-form-textarea>
                        <div class="rating-wrapper d-sm-inline-flex">
                          <label for="rating-address">{{
                            $t("title.common.grade_point_by_action")
                          }}</label>
                          <b-form-rating
                            id="rating-address"
                            :value="ansresultitems.lessonlevel6"
                            no-border
                            readonly
                            show-value
                            show-value-max
                            class="py-1"
                          ></b-form-rating>
                        </div>
                      </div>

                      <div class="col-sm-5 col-md-4">
                        <span>{{ "lesson7" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          plaintext
                          class="mb-2"
                          :value="ansresultitems.lesson7"
                        ></b-form-textarea>
                        <div class="rating-wrapper d-sm-inline-flex">
                          <label for="rating-address">{{
                            $t("title.common.grade_point_by_action")
                          }}</label>
                          <b-form-rating
                            id="rating-address"
                            :value="ansresultitems.lessonlevel7"
                            no-border
                            readonly
                            show-value
                            show-value-max
                            class="py-1"
                          ></b-form-rating>
                        </div>
                      </div>

                      <div class="col-sm-5 col-md-4 border-sm-0">
                        <span>{{ "lessontotal" | get_lesson_title }}</span>
                      </div>
                      <div class="col-sm-7 col-md-8">
                        <b-form-textarea
                          plaintext
                          :value="ansresultitems.lessontotal"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </section>
                </div>
                <!-- //tabIndex: 1, 원포인트레슨 -->

                <div
                  class="tab-pane fade show"
                  :class="{ active: preViewTabIndex == 2 }"
                >
                  <section>
                    <h6 class="sub-title">
                      <span class="brand-name fw-bold">{{
                        ansresultitems.ansclubinfo.find(
                          (v) => v.fit_goods_grade == "P"
                        ).fit_brand_nm
                      }}</span
                      >{{ $t("title.fitting.recommended_by") }}
                      <span class="text-green">{{
                        $t("page.fitting.premium_goods")
                      }}</span>
                    </h6>
                    <div class="card m-0 p-0 bg-transparent border-0 rounded-0">
                      <div class="row g-0 table-type header-col">
                        <div class="col-sm-3">
                          <div
                            class="d-flex flex-fill justify-content-center h-100 bg-white"
                          >
                            <img
                              :src="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_image1 | get_img
                              "
                              class="img-fluid rounded-0"
                            />
                          </div>
                        </div>
                        <div
                          class="col-sm-9 d-flex flex-column align-items-stretch"
                        >
                          <div class="card-body px-0 px-sm-3">
                            <h4 class="card-title">
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "P"
                                ).fit_goods_nm
                              }}
                            </h4>
                            <div class="row g-0 row-cols-1 row-cols-sm-2">
                              <div
                                class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 fw-bold text-black"
                              >
                                {{ $t("title.common.retail_price") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2">
                                <h5 class="text-price">
                                  &#8361;
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_sale_price | comma
                                  }}
                                </h5>
                              </div>

                              <div
                                class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                              >
                                {{ $t("title.common.location_price") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                                <h5 class="text-price">
                                  &#8361;
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_shop_price | comma
                                  }}
                                </h5>
                              </div>

                              <div
                                class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                              >
                                {{ $t("title.common.xgolf_price") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                                <h5 class="text-price">
                                  &#8361;
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_supply_price | comma
                                  }}
                                </h5>
                              </div>

                              <div
                                class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                              >
                                {{ $t("title.common.benefit") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                                {{
                                  ansresultitems.ansclubinfo.find(
                                    (v) => v.fit_goods_grade == "P"
                                  ).fit_goods_info
                                }}
                                <!-- 미등록 -->
                              </div>
                            </div>
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                          >
                            <div
                              class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                            >
                              <!-- <b-button
                                size="md"
                                variant="primary"
                                aria-label="제품구매"
                              >
                                제품구매
                              </b-button> -->
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                    </div>
                  </section>

                  <!-- //기본 정보 등록 -->
                  <section>
                    <h6 class="sub-title">
                      {{ $t("page.sales.sales_goods_detail") }}
                    </h6>
                    <div class="row g-0 table-type header-col">
                      <img
                        v-if="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'P'
                          ).fit_goods_image2
                        "
                        :src="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'P'
                          ).fit_goods_image2 | get_img
                        "
                        class="img-fluid rounded-0"
                      />
                      <img
                        v-if="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'P'
                          ).fit_goods_image3
                        "
                        :src="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'P'
                          ).fit_goods_image3 | get_img
                        "
                        class="img-fluid rounded-0"
                      />
                    </div>
                  </section>
                  <section class="reg-spec">
                    <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                    <b-table-simple responsive table-class="border-0">
                      <b-thead>
                        <b-tr>
                          <b-th sticky-column>{{
                            $t("title.common.club_type")
                          }}</b-th>
                          <b-th
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clubnotype' + idx"
                          >
                            {{
                              fittingreqinfo.clubtypelist.find(
                                (v) => v.clubtype_code == val.fit_club_nm
                              ).clubtype_code_name
                            }}
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.loft") }}(&deg;)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clubloft' + idx"
                          >
                            {{ val.fit_club_loft }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.lie") }}(&deg;)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clublie' + idx"
                          >
                            {{ val.fit_club_lie }}
                          </b-td>
                        </b-tr>

                        <b-tr
                          v-if="
                            ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).fit_club_type < 4
                          "
                        >
                          <b-th sticky-column
                            >{{ $t("title.common.head_volume") }}(㎤)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clubheadvolume' + idx"
                          >
                            {{ val.fit_club_headvolume }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="
                            ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).fit_club_type > 3
                          "
                        >
                          <b-th sticky-column
                            >{{ $t("title.common.sole") }}(&deg;)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clubsole' + idx"
                          >
                            {{ val.fit_club_sol }}
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.fp") }}(mm)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clubfp' + idx"
                            >{{ val.fit_club_fp }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.length") }}(Inch)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clublength' + idx"
                          >
                            {{ val.fit_club_length }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column rowspan="5">
                            <div class="sticky-column-rowspan">
                              <div>{{ $t("title.common.balance") }}</div>
                              <div class="flex-column">
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_balance1 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_balance1 | get_balance
                                  }}
                                </div>
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_balance2 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_balance2 | get_balance
                                  }}
                                </div>
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_balance3 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_balance3 | get_balance
                                  }}
                                </div>
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_balance4 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_balance4 | get_balance
                                  }}
                                </div>
                                <div
                                  class="py-2 pe-2"
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_balance5 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'P'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "P"
                                    ).fit_balance5 | get_balance
                                  }}
                                </div>
                              </div>
                            </div>
                          </b-th>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle1' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance1 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance1 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle2' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance2 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance2 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle3' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance3 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance3 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle4' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance4 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance4 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle5' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance5 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance5 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column class="border-0"
                            >{{ $t("title.common.weight") }}(g)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'P'
                            ).ansclublist"
                            :key="'p_clubweight' + idx"
                          >
                            {{ val.fit_club_weight }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </section>
                  <!-- 스펙 등록 -->

                  <section class="reg-etc">
                    <div class="row gx-4 gy-5 border-0">
                      <div class="col-lg-6 d-flex flex-column">
                        <h6 class="sub-title">{{ $t("page.goods.shaft") }}</h6>
                        <div class="card h-100">
                          <div class="card-body d-flex flex-column p-0">
                            <p class="card-title">
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "P"
                                ).fit_goods_shaft_nm
                              }}
                              /
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "P"
                                ).fit_shaft_material | get_shaft_material
                              }}
                            </p>
                            <div
                              class="d-flex justify-content-center align-items-center h-100 py-3"
                            >
                              <img
                                :src="
                                  ansresultitems.ansclubinfo.find(
                                    (v) => v.fit_goods_grade == 'P'
                                  ).fit_goods_shaft_image1 | get_img
                                "
                                class="img-fluid"
                                :alt="$t('page.goods.shaft')"
                              />
                            </div>
                          </div>
                          <div class="card-footer">
                            <table class="table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {{ $t("title.common.flex") }}
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.weight") }}(g)
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.torque") }}(˚)
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.kick_point") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "P"
                                      ).fit_goods_shaft_balance | get_balance
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "P"
                                      ).fit_goods_shaft_weight
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "P"
                                      ).fit_goods_shaft_torque
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "P"
                                      ).fit_goods_shaft_kickpoint
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 d-flex flex-column">
                        <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                        <div class="card h-100">
                          <div class="card-body d-flex flex-column p-0">
                            <p class="card-title">
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "P"
                                ).fit_goods_grip_nm
                              }}
                            </p>
                            <div
                              class="d-flex justify-content-center align-items-center h-100 py-3"
                            >
                              <img
                                :src="
                                  ansresultitems.ansclubinfo.find(
                                    (v) => v.fit_goods_grade == 'P'
                                  ).fit_goods_grip_image1 | get_img
                                "
                                class="img-fluid"
                                :alt="$t('page.goods.grip')"
                              />
                            </div>
                          </div>
                          <div class="card-footer">
                            <table class="table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {{ $t("title.common.standard") }}
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.weight") }}(g)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "P"
                                      ).fit_goods_grip_standard
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "P"
                                      ).fit_goods_grip_weight
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- 샤프트 & 그립 등록 -->
                  <!-- //tabIndex: 2, 고급형 -->
                </div>

                <div
                  class="tab-pane fade show"
                  :class="{ active: preViewTabIndex == 3 }"
                >
                  <section>
                    <h6 class="sub-title">
                      <span class="brand-name fw-bold">{{
                        ansresultitems.ansclubinfo.find(
                          (v) => v.fit_goods_grade == "C"
                        ).fit_brand_nm
                      }}</span
                      >{{ $t("title.fitting.recommended_by") }}
                      <span class="text-green">{{
                        $t("page.fitting.entry_goods")
                      }}</span>
                    </h6>
                    <div class="card m-0 p-0 bg-transparent border-0 rounded-0">
                      <div class="row g-0 table-type header-col">
                        <div class="col-sm-3">
                          <div
                            class="d-flex flex-fill justify-content-center h-100 bg-white"
                          >
                            <img
                              :src="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'C'
                                ).fit_goods_image1 | get_img
                              "
                              class="img-fluid rounded-0"
                            />
                          </div>
                        </div>
                        <div
                          class="col-sm-9 d-flex flex-column align-items-stretch"
                        >
                          <div class="card-body px-0 px-sm-3">
                            <h4 class="card-title">
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "C"
                                ).fit_goods_nm
                              }}
                            </h4>
                            <div class="row g-0 row-cols-1 row-cols-sm-2">
                              <div
                                class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 fw-bold text-black"
                              >
                                {{ $t("title.common.retail_price") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2">
                                <h5 class="text-price">
                                  &#8361;
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_sale_price | comma
                                  }}
                                </h5>
                              </div>

                              <div
                                class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                              >
                                {{ $t("title.common.location_price") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                                <h5 class="text-price">
                                  &#8361;
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_shop_price | comma
                                  }}
                                </h5>
                              </div>

                              <div
                                class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                              >
                                {{ $t("title.common.xgolf_price") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                                <h5 class="text-price">
                                  &#8361;
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_supply_price | comma
                                  }}
                                </h5>
                              </div>

                              <div
                                class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                              >
                                {{ $t("title.common.benefit") }}
                              </div>
                              <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                                {{
                                  ansresultitems.ansclubinfo.find(
                                    (v) => v.fit_goods_grade == "C"
                                  ).fit_goods_info
                                }}
                                <!-- 미등록 -->
                              </div>
                            </div>
                          </div>
                          <!-- //card-body -->
                          <div
                            class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                          >
                            <div
                              class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                            >
                              <!-- <b-button
                                size="md"
                                variant="primary"
                                aria-label="제품구매"
                              >
                                제품구매
                              </b-button> -->
                            </div>
                          </div>
                          <!-- //card-footer -->
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- //기본 정보 등록 -->
                  <section>
                    <h6 class="sub-title">2</h6>
                    <div class="row g-0 table-type header-col">
                      <img
                        v-if="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'C'
                          ).fit_goods_image2
                        "
                        :src="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'C'
                          ).fit_goods_image2 | get_img
                        "
                        class="img-fluid rounded-0"
                      />
                      <img
                        v-if="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'C'
                          ).fit_goods_image3
                        "
                        :src="
                          ansresultitems.ansclubinfo.find(
                            (v) => v.fit_goods_grade == 'C'
                          ).fit_goods_image3 | get_img
                        "
                        class="img-fluid rounded-0"
                      />
                    </div>
                  </section>
                  <section class="reg-spec">
                    <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                    <b-table-simple responsive table-class="border-0">
                      <b-thead>
                        <b-tr>
                          <b-th sticky-column>{{
                            $t("title.common.club_type")
                          }}</b-th>
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'p_clubnotype' + idx"
                          >
                            {{
                              fittingreqinfo.clubtypelist.find(
                                (v) => v.clubtype_code == val.fit_club_nm
                              ).clubtype_code_name
                            }}
                          </b-td>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.loft") }}(&deg;)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'p_clubloft' + idx"
                          >
                            {{ val.fit_club_loft }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.lie") }}(&deg;)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'p_clublie' + idx"
                          >
                            {{ val.fit_club_lie }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="
                            ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).fit_club_type < 4
                          "
                        >
                          <b-th sticky-column
                            >{{ $t("title.common.head_volume") }}(㎤)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'c_clubheadvolume' + idx"
                          >
                            {{ val.fit_club_headvolume }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="
                            ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).fit_club_type > 3
                          "
                        >
                          <b-th sticky-column
                            >{{ $t("title.common.sole") }}(&deg;)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'c_clubsole' + idx"
                          >
                            {{ val.fit_club_sol }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.fp") }}(mm)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'p_clubfp' + idx"
                            >{{ val.fit_club_fp }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column
                            >{{ $t("title.common.length") }}(Inch)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'p_clublength' + idx"
                          >
                            {{ val.fit_club_length }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column rowspan="5">
                            <div class="sticky-column-rowspan">
                              <div>{{ $t("title.common.balance") }}</div>
                              <div class="flex-column">
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_balance1 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_balance1 | get_balance
                                  }}
                                </div>
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_balance2 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_balance2 | get_balance
                                  }}
                                </div>
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_balance3 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_balance3 | get_balance
                                  }}
                                </div>
                                <div
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_balance4 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_balance4 | get_balance
                                  }}
                                </div>
                                <div
                                  class="py-2 pe-2"
                                  v-if="
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_balance5 ==
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == 'C'
                                    ).fit_goods_balance
                                  "
                                >
                                  {{
                                    ansresultitems.ansclubinfo.find(
                                      (v) => v.fit_goods_grade == "C"
                                    ).fit_balance5 | get_balance
                                  }}
                                </div>
                              </div>
                            </div>
                          </b-th>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle1' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance1 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance1 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle2' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance2 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance2 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle3' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance3 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance3 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle4' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance4 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance4 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <template
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                          >
                            <b-td
                              :key="'p_clubflextitle5' + idx"
                              v-if="
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_balance5 ==
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == 'P'
                                ).fit_goods_balance
                              "
                            >
                              {{ val.fit_club_balance5 }}
                            </b-td>
                          </template>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column class="border-0"
                            >{{ $t("title.common.weight") }}(g)</b-th
                          >
                          <b-td
                            v-for="(
                              val, idx
                            ) in ansresultitems.ansclubinfo.find(
                              (v) => v.fit_goods_grade == 'C'
                            ).ansclublist"
                            :key="'p_clubweight' + idx"
                          >
                            {{ val.fit_club_weight }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </section>
                  <!-- 스펙 등록 -->

                  <section class="reg-etc">
                    <div class="row gx-4 gy-5 border-0">
                      <div class="col-lg-6 d-flex flex-column">
                        <h6 class="sub-title">{{ $t("page.common.shaft") }}</h6>
                        <div class="card h-100">
                          <div class="card-body d-flex flex-column p-0">
                            <p class="card-title">
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "C"
                                ).fit_goods_shaft_nm
                              }}
                              /
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "C"
                                ).fit_shaft_material | get_shaft_material
                              }}
                            </p>
                            <div
                              class="d-flex justify-content-center align-items-center h-100 py-3"
                            >
                              <img
                                :src="
                                  ansresultitems.ansclubinfo.find(
                                    (v) => v.fit_goods_grade == 'C'
                                  ).fit_goods_shaft_image1 | get_img
                                "
                                class="img-fluid"
                                :alt="$t('page.goods.shaft')"
                              />
                            </div>
                          </div>
                          <div class="card-footer">
                            <table class="table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {{ $t("title.common.flex") }}
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.weight") }}(g)
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.torque") }}(˚)
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.kick_point") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "C"
                                      ).fit_goods_shaft_balance | get_balance
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "C"
                                      ).fit_goods_shaft_weight
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "C"
                                      ).fit_goods_shaft_torque
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "C"
                                      ).fit_goods_shaft_kickpoint
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 d-flex flex-column">
                        <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                        <div class="card h-100">
                          <div class="card-body d-flex flex-column p-0">
                            <p class="card-title">
                              {{
                                ansresultitems.ansclubinfo.find(
                                  (v) => v.fit_goods_grade == "C"
                                ).fit_goods_grip_nm
                              }}
                            </p>
                            <div
                              class="d-flex justify-content-center align-items-center h-100 py-3"
                            >
                              <img
                                :src="
                                  ansresultitems.ansclubinfo.find(
                                    (v) => v.fit_goods_grade == 'C'
                                  ).fit_goods_grip_image1 | get_img
                                "
                                class="img-fluid"
                                :alt="$t('page.goods.grip')"
                              />
                            </div>
                          </div>
                          <div class="card-footer">
                            <table class="table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {{ $t("title.common.standard") }}
                                  </th>
                                  <th scope="col">
                                    {{ $t("title.common.weight") }}(g)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "C"
                                      ).fit_goods_grip_standard
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      ansresultitems.ansclubinfo.find(
                                        (v) => v.fit_goods_grade == "C"
                                      ).fit_goods_grip_weight
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- 샤프트 & 그립 등록 -->
                  <!-- //tabIndex: 3, 보급형 -->
                </div>
              </div>
            </div>
            <div class="text-center" v-else>
              {{ $t("text.common.no_data") }}
            </div>
            <!-- //tab-content -->
            <!-- //탭 영역 끝 -->
          </template>
          <!-- modal-body -->

          <template #modal-footer="{}">
            <div class="overflow-auto me-sm-auto mb-3 mb-sm-0">
              <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="ansresultitemscnt"
                v-model="ansresult.pageno"
                v-if="ansresultitemscnt > 1"
                use-router
                aria-label="Pagination"
                first-class="prev"
                prev-class="prev"
                next-class="next"
                last-class="next"
                hide-ellipsis
                limit="10"
              >
                <template #first-text>
                  <i class="material-icons">keyboard_double_arrow_left</i>
                </template>
                <template #prev-text>
                  <i class="material-icons">navigate_before</i>
                </template>
                <template #next-text>
                  <i class="material-icons">navigate_next</i>
                </template>
                <template #last-text>
                  <i class="material-icons">keyboard_double_arrow_right</i>
                </template>
                <template #page="{ page, active }">
                  <a v-if="active">{{ page }}</a>
                  <a v-else>{{ page }}</a>
                </template>
              </b-pagination-nav>
            </div>
            <div class="d-flex flex-row">
              <b-button
                size="md"
                variant="outline-secondary"
                :aria-label="$t('button.common.print')"
                @click="print()"
              >
                {{ $t("button.common.print") }}
              </b-button>
              <b-button
                size="md"
                variant="outline-secondary"
                :aria-label="$t('button.common.delete')"
                @click="ansresultDelete()"
              >
                {{ $t("button.common.delete") }}
              </b-button>
              <b-button
                size="md"
                variant="secondary"
                :aria-label="$t('title.common.choose')"
                @click="ansresultSelect()"
              >
                {{ $t("title.common.choose") }}
              </b-button>
            </div>
          </template>
        </b-modal>

        <!-- 팝업, 동영상 광고 등록 -->
        <b-modal
          ref="settingModal"
          id="modal-adv-video"
          size="lg"
          centered
          scrollable
          footer-class="btn-bottom-wrapper"
        >
          <template #modal-header="{ close }">
            <h5 class="modal-title" v-if="setad.adtype == '1'">
              {{ $t("page.fitting.ad_movie_write") }}
            </h5>
            <h5 class="modal-title" v-if="setad.adtype == '2'">
              {{ $t("page.fitting.ad_title_write") }}
            </h5>
            <h5 class="modal-title" v-if="setad.adtype > 2">
              {{ $t("page.fitting.ad_goods_write") }}
            </h5>
            <b-button variant="close" @click="close()"></b-button>
          </template>

          <template>
            <div class="filter-group d-flex flex-column flex-sm-row">
              <div class="d-flex me-sm-n1px mb-2 mb-sm-0">
                <select
                  class="w-auto flex-grow-1"
                  :aria-label="$t('title.common.choose')"
                  @change="get_adlist()"
                  v-model="setad.goodstype"
                >
                  <option value="0">{{ $t("filter.common.total") }}</option>
                  <option value="1">{{ $t("filter.common.driver") }}</option>
                  <option value="2">{{ $t("filter.common.wood") }}</option>
                  <option value="3">{{ $t("filter.common.utility") }}</option>
                  <option value="4">{{ $t("filter.common.iron") }}</option>
                  <option value="5">{{ $t("filter.common.wedge") }}</option>
                  <option value="6">{{ $t("filter.common.putter") }}</option>
                </select>
              </div>
              <div class="input-group flex-nowrap">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.input_search')"
                  :aria-label="$t('text.common.input_search')"
                  aria-describedby="button-addon"
                  v-model="setad.title"
                  id="button-addon"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="button"
                  id="button-addon"
                  @click="get_adlist()"
                ></button>
              </div>
            </div>

            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div
                class="col"
                v-for="item in aditems"
                :key="'aditems' + item.adno"
              >
                <div class="card h-100">
                  <label>
                    <img
                      :src="item.adimagepath | get_img"
                      class="card-img-top"
                      :alt="$t('menu.ad.ad')"
                    />
                    <div class="card-body">
                      <h6 class="card-title goods-name text-truncate">
                        {{ item.adtitle }}
                      </h6>
                      <input
                        class="form-check-input m-0"
                        type="radio"
                        v-if="setad.adtype == '1'"
                        v-model="setad.movieadno"
                        :value="item.adno"
                      />
                      <input
                        class="form-check-input m-0"
                        type="radio"
                        v-if="setad.adtype == '2'"
                        v-model="setad.titleadno"
                        :value="item.adno"
                      />
                      <input
                        class="form-check-input m-0"
                        type="radio"
                        v-if="setad.adtype == '3'"
                        v-model="setad.goodsadno1"
                        :value="item.adno"
                      />
                      <input
                        class="form-check-input m-0"
                        type="radio"
                        v-if="setad.adtype == '4'"
                        v-model="setad.goodsadno2"
                        :value="item.adno"
                      />
                      <input
                        class="form-check-input m-0"
                        type="radio"
                        v-if="setad.adtype == '5'"
                        v-model="setad.goodsadno3"
                        :value="item.adno"
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="text-center" v-if="!aditems.length">
              {{ $t("text.common.no_data") }}
            </div>
          </template>

          <template #modal-footer="{ cancel }">
            <b-button
              size="md"
              variant="outline-secondary"
              :aria-label="$t('button.common.cancel')"
              @click="cancel()"
            >
              {{ $t("button.common.cancel") }}
            </b-button>
            <b-button
              size="md"
              variant="secondary"
              :aria-label="$t('title.common.choose')"
              @click="confirm()"
            >
              {{ $t("title.common.choose") }}
            </b-button>
          </template>
        </b-modal>

        <!-- 팝업, 피팅분석평 -->
        <b-modal
          id="modal-review"
          size="lg"
          centered
          scrollable
          footer-class="btn-bottom-wrapper"
          ref="fitresultModal"
        >
          <template #modal-header="{ close }">
            <h5 class="modal-title">
              {{ $t("title.common.fitting_analysis") }}
            </h5>
            <b-button variant="close" @click="close()"></b-button>
          </template>

          <template>
            <div class="list-group">
              <label
                v-for="item in fitresultitems"
                :key="'fitresultitems' + item.result_pk"
                class="list-group-item row row-cols-1 row-cols-sm-2 g-0"
              >
                <div class="col w-auto text-center">
                  <input
                    class="form-check-input"
                    type="radio"
                    :value="item.result_pk"
                    v-model="fitresultform.result_pk"
                  />
                  <span class="d-inline d-sm-none text-black">{{
                    $t("title.common.choose")
                  }}</span>
                </div>
                <div class="col flex-grow-1">
                  <div class="input-group flex-nowrap mt-2 mt-sm-0 mb-2">
                    <input
                      type="text"
                      class="form-control w-auto text-truncate"
                      :placeholder="$t('text.common.choose_alias')"
                      :aria-label="$t('text.common.choose_alias')"
                      aria-describedby="button-addon"
                      :ref="'result_title' + item.result_pk"
                      :value="item.result_title"
                    />
                    <button
                      class="btn btn-primary fs-14"
                      type="button"
                      id="button-addon"
                      @click="fitresulttitlewrite(item.result_pk)"
                    >
                      {{ $t("button.common.save") }}
                    </button>
                  </div>
                  <textarea
                    class="form-control"
                    :value="item.result_txt"
                    :ref="'result_txt' + item.result_pk"
                  ></textarea>
                </div>
              </label>
            </div>
            <div class="row" v-if="!fitresultitems.length">
              {{ $t("text.common.no_data") }}
            </div>
          </template>

          <template #modal-footer="{ cancel }">
            <b-button
              size="md"
              variant="outline-secondary"
              :aria-label="$t('button.common.cancel')"
              @click="cancel()"
            >
              {{ $t("button.common.cancel") }}
            </b-button>
            <b-button
              size="md"
              variant="secondary"
              :aria-label="$t('title.common.choose')"
              @click="fitresultconfirm()"
            >
              {{ $t("title.common.choose") }}
            </b-button>
          </template>
        </b-modal>

        <!-- 팝업, 원 포인트 레슨 -->
        <b-modal
          ref="analysisresultModal"
          id="modal-lesson"
          size="lg"
          centered
          scrollable
          footer-class="btn-bottom-wrapper"
        >
          <template #modal-header="{ close }">
            <h5 class="modal-title">
              {{ analysisresulttitle | get_lesson_title }}
            </h5>
            <b-button variant="close" @click="close()"></b-button>
          </template>

          <template>
            <div class="list-group">
              <label
                v-for="item in analysisresultitems"
                :key="'analysisresultitems' + item.result_pk"
                class="list-group-item row row-cols-1 row-cols-sm-2 g-0"
              >
                <div class="col w-auto text-center">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="analysisresultform[analysisresulttitle]"
                    :value="item.result_pk"
                  />
                  <span class="d-inline d-sm-none text-black">{{
                    $t("title.common.choose")
                  }}</span>
                </div>
                <div class="col flex-grow-1">
                  <div class="input-group flex-nowrap mt-2 mt-sm-0 mb-2">
                    <input
                      type="text"
                      class="form-control w-auto text-truncate"
                      :placeholder="$t('text.common.choose_alias')"
                      :aria-label="$t('text.common.choose_alias')"
                      aria-describedby="button-addon"
                      :ref="'analysisresult_title' + item.result_pk"
                      :value="item.result_title"
                    />
                    <button
                      class="btn btn-primary fs-14"
                      type="button"
                      id="button-addon"
                      @click="
                        analysisresulttitlewrite(
                          item.result_pk,
                          analysisresulttitle
                        )
                      "
                    >
                      {{ $t("button.common.save") }}
                    </button>
                  </div>
                  <textarea
                    class="form-control"
                    :value="item.result_txt"
                    :ref="'analysisresult_txt' + item.result_pk"
                  ></textarea>
                </div>
              </label>
            </div>
          </template>

          <template #modal-footer="{ cancel }">
            <b-button
              size="md"
              variant="outline-secondary"
              :aria-label="$t('button.common.cancel')"
              @click="cancel()"
            >
              {{ $t("button.common.cancel") }}
            </b-button>
            <b-button
              size="md"
              variant="secondary"
              :aria-label="$t('title.common.choose')"
              @click="analysisresultconfirm(analysisresulttitle)"
            >
              {{ $t("title.common.choose") }}
            </b-button>
          </template>
        </b-modal>
      </div>
      <!-- //progress-step-arrow -->
      <b-modal
        id="modal-img-thumbnail"
        size="lg"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.basic_image") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img :src="form._resultfilename | get_img" class="mw-100 h-100" />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.close')"
            @click="cancel()"
          >
            {{ $t("button.common.close") }}
          </b-button>
        </template>
      </b-modal>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/fitting";
import "@/filter/common";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import { Radar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

export default {
  name: "FittingAnswerWrite",
  components: {
    location,
    Radar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      form: {
        fit_req_copy_fk: 0,
        movieadno: 0,
        titleadno: 0,
        goodsadno1: 0,
        goodsadno2: 0,
        goodsadno3: 0,
        resulttxt: "",
        resultfilename: null,
        impactgrade: "1",
        distancegrade: "1",
        directiongrade: "1",
        accuracygrade: "1",
        positiongrade: "1",
        headvolume: 0,
        sole: 0,
        loft: 0,
        fp: 0,
        bounce: 0,
        lie: 0,
        analysisresulttxt: {
          lesson1: "",
          lesson2: "",
          lesson3: "",
          lesson4: "",
          lesson5: "",
          lesson6: "",
          lesson7: "",
          lessontotal: "",
        },
        analysisresultgrade: {
          lessonlevel1: "",
          lessonlevel2: "",
          lessonlevel3: "",
          lessonlevel4: "",
          lessonlevel5: "",
          lessonlevel6: "",
          lessonlevel7: "",
        },

        p_goodsno: 0,

        p_shaftno: 0,

        p_goodsflextype: "",
        p_gripno: 0,

        p_goodsprice: 0,
        p_goodsxgolfprice: 0,
        p_goodsshopprice: 0,

        p_clubnotype: [],
        p_clubloft: [],
        p_clublie: [],
        p_clubheadvolume: [],
        p_clubsole: [],
        p_clubfp: [],
        p_clublength: [],
        p_clubweight: [],
        p_clubflextype1: 0,
        p_clubflextype1_: 0,
        p_clubflextitle1: [],
        p_clubflextype2: 0,
        p_clubflextype2_: 0,
        p_clubflextitle2: [],
        p_clubflextype3: 0,
        p_clubflextype3_: 0,
        p_clubflextitle3: [],
        p_clubflextype4: 0,
        p_clubflextype4_: 0,
        p_clubflextitle4: [],
        p_clubflextype5: 0,
        p_clubflextype5_: 0,
        p_clubflextitle5: [],

        c_goodsno: 0,
        c_goodstitle: "",
        c_shaftno: 0,
        c_shafttitle: "",
        c_goodsflextype: "",
        c_gripno: 0,
        c_griptitle: "",
        c_goodsprice: 0,
        c_goodsxgolfprice: 0,
        c_goodsshopprice: 0,

        c_clubnotype: [],
        c_clubloft: [],
        c_clublie: [],
        c_clubheadvolume: [],
        c_clubsole: [],
        c_clubfp: [],
        c_clublength: [],
        c_clubweight: [],
        c_clubflextype1: 0,
        c_clubflextype1_: 0,
        c_clubflextitle1: [],
        c_clubflextype2: 0,
        c_clubflextype2_: 0,
        c_clubflextitle2: [],
        c_clubflextype3: 0,
        c_clubflextype3_: 0,
        c_clubflextitle3: [],
        c_clubflextype4: 0,
        c_clubflextype4_: 0,
        c_clubflextitle4: [],
        c_clubflextype5: 0,
        c_clubflextype5_: 0,
        c_clubflextitle5: [],
      },
      setad: {
        movieadno: 0,
        moviead: {},
        titleadno: 0,
        titlead: {},
        goodsadno1: 0,
        goodsad1: {},
        goodsadno2: 0,
        goodsad2: [],
        goodsadno3: 0,
        goodsad3: [],
        goodstype: 0,
        adtype: 0,
        title: "",
      },
      fitresultform: {
        result_pk: 0,
      },
      ansresult: {
        goodstype: "0",
        findex: "",
        fit_req_pk: "",
        pageno: 1,
      },
      analysisresultform: [],
      item: {},
      aditems: [],
      goodsitems: [],
      p_item: {},
      c_item: {},
      p_shaftitems: [],
      p_gripitems: [],
      p_clubitems: [],
      c_shaftitems: [],
      c_gripitems: [],
      c_clubitems: [],
      ansresultitems: {},
      ansresultitemscnt: 0,
      fitresultitems: [],
      analysisresultitems: [],
      goodstype: this.$route.query.goodstype || 0,
      view_hide: null,
      targetFileName: "",
      tabIndex: 0,
      preViewTabIndex: 0,
      modalTabIndex: 1,
      ansmodalTabIndex: 1,

      fitreqno: this.$route.params.fitreqno || 0,
      analysisresulttitle: "",
      fittingreqinfo: {},
      radardata: {
        labels: [
          this.$i18n.t("title.common.distance"),
          this.$i18n.t("title.common.directionality"),
          this.$i18n.t("title.common.accuracy"),
          this.$i18n.t("title.common.posture"),
          this.$i18n.t("title.common.impact"),
        ],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgba(179,181,198,1)",
            pointBackgroundColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [],
          },
        ],
      },
      radaroptions: {
        scale: {
          r: {
            min: 0, // MIN
            max: 5, // MAX
            beginAtZero: true,
            angleLines: {
              display: true,
              // color: 'red',
            },
            grid: {
              circular: true,
            },
          },
          ticks: {
            stepSize: 1,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      },
      resultradardata: {
        labels: [
          this.$i18n.t("title.common.distance"),
          this.$i18n.t("title.common.directionality"),
          this.$i18n.t("title.common.accuracy"),
          this.$i18n.t("title.common.posture"),
          this.$i18n.t("title.common.impact"),
        ],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgba(179,181,198,1)",
            pointBackgroundColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [],
          },
        ],
      },
      watching: false,
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  created() {
    this.get_fittinganswerview().then((flag) => {
      this.watching = flag;
    });
  },

  methods: {
    preview() {
      this.$refs["preViewModal"].show();
    },
    get_custormerview() {
      if (this.noticeno) {
        ax.get_custormerview(this.noticeno, {}, (flag, data, code) => {
          this.form.noticetitle = data.noticeinfo.noticetitle;
          this.form.noticecategory = data.noticeinfo.fit_as_category;
          this.form.content = data.noticeinfo.content;
          this.as_filename = data.noticeinfo.as_filename;
          if (!flag) {
            alert(data);
          }
        });
      }
    },
    onSubmit(event) {
      event.preventDefault();
      // if (!this.form.movieadno) {
      //   alert("동영상 광고를 선택해 주세요");
      //   this.tabIndex = 1;
      //   return false;
      // }

      // if (!this.form.titleadno) {
      //   alert("타이틀 광고를 선택해 주세요");
      //   this.tabIndex = 1;
      //   return false;
      // }

      // if (!this.form.goodsadno1) {
      //   alert("상품 광고를 선택해 주세요");
      //   this.tabIndex = 1;
      //   return false;
      // }

      // if (!this.form.resulttxt) {
      //   alert("피팅결과분석을 입력해 주세요");
      //   this.tabIndex = 1;
      //   return false;
      // }

      // if (this.setad.movieadno == "0") {
      //   alert("동영상 광고를 선택해 주세요");
      //   return false;
      // }

      this.form.p_clubnotype.forEach((v, i) => {
        if (v == "undefined" || v == undefined) {
          this.form.p_clubnotype.splice(i, 1);
          this.form.p_clubloft.splice(i, 1);
          this.form.p_clublie.splice(i, 1);
          this.form.p_clubheadvolume.splice(i, 1);
          this.form.p_clubsole.splice(i, 1);
          this.form.p_clubfp.splice(i, 1);
          this.form.p_clublength.splice(i, 1);
          this.form.p_clubweight.splice(i, 1);
          this.form.p_clubflextitle1.splice(i, 1);
          this.form.p_clubflextitle2.splice(i, 1);
          this.form.p_clubflextitle3.splice(i, 1);
          this.form.p_clubflextitle4.splice(i, 1);
          this.form.p_clubflextitle5.splice(i, 1);
        }
      });

      this.form.c_clubnotype.forEach((v, i) => {
        if (v == "undefined" || v == undefined) {
          this.form.c_clubnotype.splice(i, 1);
          this.form.c_clubloft.splice(i, 1);
          this.form.c_clublie.splice(i, 1);
          this.form.c_clubheadvolume.splice(i, 1);
          this.form.c_clubsole.splice(i, 1);
          this.form.c_clubfp.splice(i, 1);
          this.form.c_clublength.splice(i, 1);
          this.form.c_clubweight.splice(i, 1);
          this.form.c_clubflextitle1.splice(i, 1);
          this.form.c_clubflextitle2.splice(i, 1);
          this.form.c_clubflextitle3.splice(i, 1);
          this.form.c_clubflextitle4.splice(i, 1);
          this.form.c_clubflextitle5.splice(i, 1);
        }
      });

      ax.post_answerwrite(this.form, this.fitreqno, (flag, data) => {
        if (flag) {
          alert(data.message);
          this.$router.push({
            name: "FittingList",

            query: { goodstype: this.goodstype },
          });
        } else {
          alert(data.message);
        }
      });
    },
    set_ad(adtype) {
      this.setad.adtype = adtype;
      if (this.setad.title == "") this.get_adlist();
      this.setad.title = "";

      this.$refs["settingModal"].show();
    },
    get_ansresultlist(goodstype = "") {
      if (goodstype !== "") this.ansresult.goodstype = goodstype;
      ax.get_ansresultlist(this.ansresult, (flag, data) => {
        if (flag) {
          if (data.ansresultlist)
            this.ansresultitems = data.ansresultlist.pop();
          else this.ansresultitems = {};

          this.ansresultitemscnt = data.ansresultlistcnt;

          this.resultradardata.datasets[0].data = [
            this.ansresultitems.grade_distance,
            this.ansresultitems.grade_direction,
            this.ansresultitems.grade_accuracy,
            this.ansresultitems.grade_position,
            this.ansresultitems.grade_impact,
          ];
          this.$refs["ansresultModal"].show();
        } else alert(data.message);
      });
    },
    get_ansresultview() {
      ax.get_ansresultview(this.ansresult, (flag, data) => {
        if (flag) {
          this.ansresultitem = data.ansresultview;
        } else alert(data.message);
      });
    },
    get_fitresultlist() {
      ax.get_fitresultlist((flag, data) => {
        if (flag) {
          this.fitresultitems = data.fitresultlist;

          this.$refs["fitresultModal"].show();
        } else alert(data.message);
      });
    },
    get_analysisresultlist(fit_type) {
      this.analysisresulttitle = fit_type;
      ax.get_analysisresultlist(fit_type, (flag, data) => {
        if (flag) {
          this.analysisresultitems = data.analysisresultlist;
          // this.$refs["analysisresultModal"].modalOuterStyle.zIndex = 9999;
          this.$refs["analysisresultModal"].show();
        } else alert(data.message);
      });
    },

    get_adlist() {
      ax.get_adlist(
        this.setad.adtype,
        this.setad.goodstype,
        this.setad.title,
        (flag, data) => {
          if (flag) {
            this.aditems = data.adlist;
          } else alert(data.message);
        }
      );
    },

    get_fittinganswerview() {
      return new Promise((resolve, reject) => {
        ax.get_fittinganswerview(this.fitreqno, (flag, data) => {
          if (flag) {
            this.fittingreqinfo = data.fittingreqinfo;
            if (this.fittingreqinfo.fit_ans_fk > 0) {
              alert(this.$i18n.t("alert.common.cannot_be_modified"));
              this.$router.go(-1);
            }
            this.ansresult.goodstype = this.fittingreqinfo.fit_club_type;
            ax.get_goodslist(
              this.fittingreqinfo.fit_club_type,
              (flag, data) => {
                if (flag) {
                  this.goodsitems = data.goodslist;
                  if (this.fittingreqinfo.state_cd != "0") {
                    this.radardata.datasets[0].data = [
                      this.form.distancegrade,
                      this.form.directiongrade,
                      this.form.accuracygrade,
                      this.form.positiongrade,
                      this.form.impactgrade,
                    ];
                    this.form.movieadno = this.fittingreqinfo.fit_ad_no_mov;
                    this.setad.movieadno = this.fittingreqinfo.fit_ad_no_mov;
                    this.setad.moviead = {
                      adno: this.fittingreqinfo.fit_ad_no_mov,
                      adimagepath: this.fittingreqinfo.ad_mov_file,
                    };

                    this.form.titleadno = this.fittingreqinfo.fit_ad_no_title;
                    this.setad.titleadno = this.fittingreqinfo.fit_ad_no_title;

                    this.setad.titlead = {
                      adno: this.fittingreqinfo.fit_ad_no_title,
                      adimagepath: this.fittingreqinfo.ad_title_file,
                    };

                    this.form.goodsadno1 = this.fittingreqinfo.fit_ad_no_img1;
                    this.setad.goodsadno1 = this.fittingreqinfo.fit_ad_no_img1;
                    this.setad.goodsad1 = {
                      adno: this.fittingreqinfo.fit_ad_no_img1,
                      adimagepath: this.fittingreqinfo.ad_img1_file,
                    };
                    this.form.goodsadno2 = this.fittingreqinfo.fit_ad_no_img2;
                    this.setad.goodsadno2 = this.fittingreqinfo.fit_ad_no_img2;
                    this.setad.goodsad2 = {
                      adno: this.fittingreqinfo.fit_ad_no_img2,
                      adimagepath: this.fittingreqinfo.ad_img2_file,
                    };
                    this.form.goodsadno3 = this.fittingreqinfo.fit_ad_no_img3;
                    this.setad.goodsadno3 = this.fittingreqinfo.fit_ad_no_img3;
                    this.setad.goodsad3 = {
                      adno: this.fittingreqinfo.fit_ad_no_img3,
                      adimagepath: this.fittingreqinfo.ad_img3_file,
                    };

                    this.form.resulttxt = this.fittingreqinfo.fit_ans_txt;
                    this.form._resultfilename =
                      this.fittingreqinfo.fit_ans_file;
                    this.form.impactgrade = this.fittingreqinfo.grade_impact;

                    this.form.distancegrade =
                      this.fittingreqinfo.grade_distance;
                    this.form.directiongrade =
                      this.fittingreqinfo.grade_direction;
                    this.form.accuracygrade =
                      this.fittingreqinfo.grade_accuracy;
                    this.form.positiongrade =
                      this.fittingreqinfo.grade_position;

                    this.form.headvolume = this.fittingreqinfo.club_headvolume;
                    this.form.sole = this.fittingreqinfo.club_sol;
                    this.form.loft = this.fittingreqinfo.club_loft;
                    this.form.fp = this.fittingreqinfo.club_fp;
                    this.form.bounce = this.fittingreqinfo.club_bounce;
                    this.form.lie = this.fittingreqinfo.club_lie;

                    this.form.analysisresulttxt.lesson1 =
                      this.fittingreqinfo.lesson1;
                    this.form.analysisresulttxt.lesson2 =
                      this.fittingreqinfo.lesson2;
                    this.form.analysisresulttxt.lesson3 =
                      this.fittingreqinfo.lesson3;
                    this.form.analysisresulttxt.lesson4 =
                      this.fittingreqinfo.lesson4;
                    this.form.analysisresulttxt.lesson5 =
                      this.fittingreqinfo.lesson5;
                    this.form.analysisresulttxt.lesson6 =
                      this.fittingreqinfo.lesson6;
                    this.form.analysisresulttxt.lesson7 =
                      this.fittingreqinfo.lesson7;
                    this.form.analysisresulttxt.lessontotal =
                      this.fittingreqinfo.lessontotal;

                    this.form.analysisresultgrade.lessonlevel1 =
                      this.fittingreqinfo.lessonlevel1;
                    this.form.analysisresultgrade.lessonlevel2 =
                      this.fittingreqinfo.lessonlevel2;
                    this.form.analysisresultgrade.lessonlevel3 =
                      this.fittingreqinfo.lessonlevel3;
                    this.form.analysisresultgrade.lessonlevel4 =
                      this.fittingreqinfo.lessonlevel4;
                    this.form.analysisresultgrade.lessonlevel5 =
                      this.fittingreqinfo.lessonlevel5;
                    this.form.analysisresultgrade.lessonlevel6 =
                      this.fittingreqinfo.lessonlevel6;
                    this.form.analysisresultgrade.lessonlevel7 =
                      this.fittingreqinfo.lessonlevel7;

                    this.fittingreqinfo.answerinfo.forEach((val) => {
                      if (val.fit_goods_grade === "P") {
                        this.form.p_goodsno = val.fit_goods_fk;

                        this.form.p_shaftno = val.fit_goods_shaft_fk;
                        this.form.p_goodsflextype = val.fit_goods_balance;

                        this.form.p_gripno = val.fit_goods_grip_fk;
                        this.form.p_goodsprice = val.fit_sale_price;
                        this.form.p_goodsxgolfprice = val.fit_supply_price;
                        this.form.p_goodsshopprice = val.fit_shop_price;

                        this.form.p_clubflextype1 = val.fit_balance1;
                        this.form.p_clubflextype1_ = val.fit_balance1;
                        this.form.p_clubflextype2 = val.fit_balance2;
                        this.form.p_clubflextype2_ = val.fit_balance2;
                        this.form.p_clubflextype3 = val.fit_balance3;
                        this.form.p_clubflextype3_ = val.fit_balance3;
                        this.form.p_clubflextype4 = val.fit_balance4;
                        this.form.p_clubflextype4_ = val.fit_balance4;
                        this.form.p_clubflextype5 = val.fit_balance5;
                        this.form.p_clubflextype5_ = val.fit_balance5;
                        val.ansclublist.forEach((val_) => {
                          this.form.p_clubnotype.push(val_.fit_club_nm);
                          this.form.p_clubloft.push(val_.fit_club_loft);
                          this.form.p_clublie.push(val_.fit_club_lie);
                          this.form.p_clubheadvolume.push(
                            val_.fit_club_headvolume
                          );
                          this.form.p_clubsole.push(val_.fit_club_sol);
                          this.form.p_clubfp.push(val_.fit_club_fp);
                          this.form.p_clublength.push(val_.fit_club_length);
                          this.form.p_clubweight.push(val_.fit_club_weight);
                          this.form.p_clubflextitle1.push(
                            val_.fit_club_balance1
                          );
                          this.form.p_clubflextitle2.push(
                            val_.fit_club_balance2
                          );
                          this.form.p_clubflextitle3.push(
                            val_.fit_club_balance3
                          );
                          this.form.p_clubflextitle4.push(
                            val_.fit_club_balance4
                          );
                          this.form.p_clubflextitle5.push(
                            val_.fit_club_balance5
                          );
                        });
                      }

                      if (val.fit_goods_grade === "C") {
                        this.form.c_goodsno = val.fit_goods_fk;

                        this.form.c_shaftno = val.fit_goods_shaft_fk;
                        this.form.c_goodsflextype = val.fit_goods_balance;
                        this.form.c_gripno = val.fit_goods_grip_fk;
                        this.form.c_goodsprice = val.fit_sale_price;
                        this.form.c_goodsxgolfprice = val.fit_supply_price;
                        this.form.c_goodsshopprice = val.fit_shop_price;

                        this.form.c_clubflextype1 = val.fit_balance1;
                        this.form.c_clubflextype1_ = val.fit_balance1;
                        this.form.c_clubflextype2 = val.fit_balance2;
                        this.form.c_clubflextype2_ = val.fit_balance2;
                        this.form.c_clubflextype3 = val.fit_balance3;
                        this.form.c_clubflextype3_ = val.fit_balance3;
                        this.form.c_clubflextype4 = val.fit_balance4;
                        this.form.c_clubflextype4_ = val.fit_balance4;
                        this.form.c_clubflextype5 = val.fit_balance5;
                        this.form.c_clubflextype5_ = val.fit_balance5;

                        val.ansclublist.forEach((val_) => {
                          this.form.c_clubnotype.push(val_.fit_club_nm);
                          this.form.c_clubloft.push(val_.fit_club_loft);
                          this.form.c_clublie.push(val_.fit_club_lie);
                          this.form.c_clubheadvolume.push(
                            val_.fit_club_headvolume
                          );
                          this.form.c_clubsole.push(val_.fit_club_sol);
                          this.form.c_clubfp.push(val_.fit_club_fp);
                          this.form.c_clublength.push(val_.fit_club_length);
                          this.form.c_clubweight.push(val_.fit_club_weight);
                          this.form.c_clubflextitle1.push(
                            val_.fit_club_balance1
                          );
                          this.form.c_clubflextitle2.push(
                            val_.fit_club_balance2
                          );
                          this.form.c_clubflextitle3.push(
                            val_.fit_club_balance3
                          );
                          this.form.c_clubflextitle4.push(
                            val_.fit_club_balance4
                          );
                          this.form.c_clubflextitle5.push(
                            val_.fit_club_balance5
                          );
                        });
                      }
                    });
                  } else {
                    this.radardata.datasets[0].data = [
                      this.form.distancegrade,
                      this.form.directiongrade,
                      this.form.accuracygrade,
                      this.form.positiongrade,
                      this.form.impactgrade,
                    ];
                  }
                  this.watching = true;
                } else alert(data.message);
              }
            );
          } else {
            alert(data.message);
          }
        });
      });
    },
    confirm() {
      if (this.setad.adtype == "1") {
        if (this.setad.movieadno == "0") {
          alert(this.$i18n.t("alert.common.choose_ad_movie"));
          return false;
        }

        this.form.movieadno = this.setad.movieadno;

        this.setad.moviead = this.aditems.find(
          (val) => val.adno == this.setad.movieadno
        );

        this.$refs["settingModal"].hide();
      }

      if (this.setad.adtype == "2") {
        if (this.setad.titleadno == "0") {
          alert(this.$i18n.t("alert.common.choose_ad_title"));
          return false;
        }

        this.form.titleadno = this.setad.titleadno;

        this.setad.titlead = this.aditems.find(
          (val) => val.adno == this.setad.titleadno
        );

        this.$refs["settingModal"].hide();
      }

      if (this.setad.adtype == "3") {
        if (this.setad.goodsadno == "0") {
          alert(this.$i18n.t("alert.common.choose_ad_goods"));
          return false;
        }

        this.form.goodsadno1 = this.setad.goodsadno1;

        this.setad.goodsad1 = this.aditems.find(
          (val) => val.adno == this.setad.goodsadno1
        );

        this.$refs["settingModal"].hide();
      }
      if (this.setad.adtype == "4") {
        if (this.setad.goodsadno2 == "0") {
          alert(this.$i18n.t("alert.common.choose_ad_goods"));
          return false;
        }

        this.form.goodsadno2 = this.setad.goodsadno2;

        this.setad.goodsad2 = this.aditems.find(
          (val) => val.adno == this.setad.goodsadno2
        );

        this.$refs["settingModal"].hide();
      }
      if (this.setad.adtype == "5") {
        if (this.setad.goodsadno == "0") {
          alert(this.$i18n.t("alert.common.choose_ad_goods"));
          return false;
        }

        this.form.goodsadno3 = this.setad.goodsadno3;

        this.setad.goodsad3 = this.aditems.find(
          (val) => val.adno == this.setad.goodsadno3
        );

        this.$refs["settingModal"].hide();
      }
    },
    fitresultconfirm() {
      if (!this.fitresultform.result_pk) {
        alert(this.$i18n.t("alert.common.choose_item"));
        return false;
      }

      this.form.resulttxt =
        this.$refs["result_txt" + this.fitresultform.result_pk][0].value;

      this.$refs["fitresultModal"].hide();
    },
    fitresulttitlewrite(result_pk) {
      const result_title =
        this.$refs["result_title" + result_pk][0].value || "";
      ax.post_fitresulttitlewrite(result_pk, result_title, (flag, data) => {
        if (flag) {
          alert(data.message);
          // this.$router.push({
          //   path: "/ad/movieadlist/",
          // });
        } else {
          alert(data.message);
        }
      });
    },
    analysisresultconfirm(analysis_type) {
      if (!this.analysisresultform[analysis_type]) {
        alert(this.$i18n.t("alert.common.choose_item"));
        return false;
      }

      this.form.analysisresulttxt[analysis_type] =
        this.$refs[
          "analysisresult_txt" + this.analysisresultform[analysis_type]
        ][0].value;
      this.$refs["analysisresultModal"].hide();
    },
    analysisresulttitlewrite(result_pk, analysis_type) {
      const result_title =
        this.$refs["analysisresult_title" + result_pk][0].value || "";

      ax.post_analysisresulttitlewrite(
        result_pk,
        result_title,
        analysis_type,
        (flag, data) => {
          if (flag) {
            alert(data.message);
            // this.$router.push({
            //   path: "/ad/movieadlist/",
            // });
          } else {
            alert(data.message);
          }
        }
      );
    },
    ansresultDelete() {
      if (!this.ansresultitems.fit_req_pk) {
        alert(this.$i18n.t("text.common.no_data"));
        return false;
      }

      ax.delete_ansresultdelete(
        this.ansresultitems.fit_req_pk,
        (flag, data) => {
          if (flag) {
            alert(data.message);
            this.$refs["ansresultModal"].hide();
          } else {
            alert(data.message);
          }
        }
      );
    },
    ansresultSelect() {
      if (!this.ansresultitems.fit_req_pk) {
        alert(this.$i18n.t("text.common.no_data"));
        return false;
      }

      this.form.fit_req_copy_fk = this.ansresultitems.fit_req_pk;

      this.form.movieadno = this.ansresultitems.fit_ad_no_mov;
      this.setad.moviead = {
        adno: this.ansresultitems.fit_ad_no_mov,
        adimagepath: this.ansresultitems.fit_ad_no_mov_info.fit_ad_img,
      };

      this.form.titleadno = this.ansresultitems.fit_ad_no_title;
      this.setad.titlead = {
        adno: this.ansresultitems.fit_ad_no_title,
        adimagepath: this.ansresultitems.fit_ad_no_title_info.fit_ad_img,
      };

      this.form.goodsadno1 = this.ansresultitems.fit_ad_no_img1;
      this.setad.goodsad1 = {
        adno: this.ansresultitems.fit_ad_no_img1,
        adimagepath: this.ansresultitems.fit_ad_no_img1_info.fit_ad_img,
      };

      this.form.goodsadno2 = this.ansresultitems.fit_ad_no_img2;
      this.setad.goodsad2 = {
        adno: this.ansresultitems.fit_ad_no_img2,
        adimagepath: this.ansresultitems.fit_ad_no_img2_info.fit_ad_img,
      };

      this.form.goodsadno3 = this.ansresultitems.fit_ad_no_img3;
      this.setad.goodsad3 = {
        adno: this.ansresultitems.fit_ad_no_img3,
        adimagepath: this.ansresultitems.fit_ad_no_img3_info.fit_ad_img,
      };

      this.form.resulttxt = this.ansresultitems.fit_ans_txt;
      // this.form._resultfilename = this.ansresultitems.fit_ans_file;
      this.form.impactgrade = this.ansresultitems.grade_impact;

      this.form.distancegrade = this.ansresultitems.grade_distance;
      this.form.directiongrade = this.ansresultitems.grade_direction;
      this.form.accuracygrade = this.ansresultitems.grade_accuracy;
      this.form.positiongrade = this.ansresultitems.grade_position;

      this.form.headvolume = this.ansresultitems.club_headvolume;
      this.form.sole = this.ansresultitems.club_sol;
      this.form.loft = this.ansresultitems.club_loft;
      this.form.fp = this.ansresultitems.club_fp;
      this.form.bounce = this.ansresultitems.club_bounce;
      this.form.lie = this.ansresultitems.club_lie;

      this.form.analysisresulttxt.lesson1 = this.ansresultitems.lesson1;
      this.form.analysisresulttxt.lesson2 = this.ansresultitems.lesson2;
      this.form.analysisresulttxt.lesson3 = this.ansresultitems.lesson3;
      this.form.analysisresulttxt.lesson4 = this.ansresultitems.lesson4;
      this.form.analysisresulttxt.lesson5 = this.ansresultitems.lesson5;
      this.form.analysisresulttxt.lesson6 = this.ansresultitems.lesson6;
      this.form.analysisresulttxt.lesson7 = this.ansresultitems.lesson7;
      this.form.analysisresulttxt.lessontotal = this.ansresultitems.lessontotal;

      this.form.analysisresultgrade.lessonlevel1 =
        this.ansresultitems.lessonlevel1;
      this.form.analysisresultgrade.lessonlevel2 =
        this.ansresultitems.lessonlevel2;
      this.form.analysisresultgrade.lessonlevel3 =
        this.ansresultitems.lessonlevel3;
      this.form.analysisresultgrade.lessonlevel4 =
        this.ansresultitems.lessonlevel4;
      this.form.analysisresultgrade.lessonlevel5 =
        this.ansresultitems.lessonlevel5;
      this.form.analysisresultgrade.lessonlevel6 =
        this.ansresultitems.lessonlevel6;
      this.form.analysisresultgrade.lessonlevel7 =
        this.ansresultitems.lessonlevel7;

      this.form.p_clubnotype = [];
      this.form.p_clubloft = [];
      this.form.p_clublie = [];
      this.form.p_clubheadvolume = [];
      this.form.p_clubsole = [];
      this.form.p_clubfp = [];
      this.form.p_clublength = [];
      this.form.p_clubweight = [];
      this.form.p_clubflextype1 = 0;
      this.form.p_clubflextype1_ = 0;
      this.form.p_clubflextitle1 = [];
      this.form.p_clubflextype2 = 0;
      this.form.p_clubflextype2_ = 0;
      this.form.p_clubflextitle2 = [];
      this.form.p_clubflextype3 = 0;
      this.form.p_clubflextype3_ = 0;
      this.form.p_clubflextitle3 = [];
      this.form.p_clubflextype4 = 0;
      this.form.p_clubflextype4_ = 0;
      this.form.p_clubflextitle4 = [];
      this.form.p_clubflextype5 = 0;
      this.form.p_clubflextype5_ = 0;
      this.form.p_clubflextitle5 = [];

      this.form.c_clubnotype = [];
      this.form.c_clubloft = [];
      this.form.c_clublie = [];
      this.form.c_clubheadvolume = [];
      this.form.c_clubsole = [];
      this.form.c_clubfp = [];
      this.form.c_clublength = [];
      this.form.c_clubweight = [];
      this.form.c_clubflextype1 = 0;
      this.form.c_clubflextype1_ = 0;
      this.form.c_clubflextitle1 = [];
      this.form.c_clubflextype2 = 0;
      this.form.c_clubflextype2_ = 0;
      this.form.c_clubflextitle2 = [];
      this.form.c_clubflextype3 = 0;
      this.form.c_clubflextype3_ = 0;
      this.form.c_clubflextitle3 = [];
      this.form.c_clubflextype4 = 0;
      this.form.c_clubflextype4_ = 0;
      this.form.c_clubflextitle4 = [];
      this.form.c_clubflextype5 = 0;
      this.form.c_clubflextype5_ = 0;
      this.form.c_clubflextitle5 = [];

      this.ansresultitems.ansclubinfo.forEach((val) => {
        if (val.fit_goods_grade === "P") {
          this.form.p_goodsno = val.fit_goods_fk;
          this.form.p_shaftno = val.fit_goods_shaft_fk;
          this.form.p_goodsflextype = val.fit_goods_balance;
          this.form.p_gripno = val.fit_goods_grip_fk;
          this.form.p_goodsprice = val.fit_sale_price;
          this.form.p_goodsxgolfprice = val.fit_supply_price;
          this.form.p_goodsshopprice = val.fit_shop_price;

          this.form.p_clubflextype1 = val.fit_balance1;
          this.form.p_clubflextype1_ = val.fit_balance1;
          this.form.p_clubflextype2 = val.fit_balance2;
          this.form.p_clubflextype2_ = val.fit_balance2;
          this.form.p_clubflextype3 = val.fit_balance3;
          this.form.p_clubflextype3_ = val.fit_balance3;
          this.form.p_clubflextype4 = val.fit_balance4;
          this.form.p_clubflextype4_ = val.fit_balance4;
          this.form.p_clubflextype5 = val.fit_balance5;
          this.form.p_clubflextype5_ = val.fit_balance5;

          val.ansclublist.forEach((val_) => {
            this.form.p_clubnotype.push(val_.fit_club_nm);
            this.form.p_clubloft.push(val_.fit_club_loft);
            this.form.p_clublie.push(val_.fit_club_lie);
            this.form.p_clubheadvolume.push(val_.fit_club_headvolume);
            this.form.p_clubsole.push(val_.fit_club_sol);
            this.form.p_clubfp.push(val_.fit_club_fp);
            this.form.p_clublength.push(val_.fit_club_length);
            this.form.p_clubweight.push(val_.fit_club_weight);
            this.form.p_clubflextitle1.push(val_.fit_club_balance1);
            this.form.p_clubflextitle2.push(val_.fit_club_balance2);
            this.form.p_clubflextitle3.push(val_.fit_club_balance3);
            this.form.p_clubflextitle4.push(val_.fit_club_balance4);
            this.form.p_clubflextitle5.push(val_.fit_club_balance5);
          });
        }

        if (val.fit_goods_grade === "C") {
          this.form.c_goodsno = val.fit_goods_fk;
          this.form.c_shaftno = val.fit_goods_shaft_fk;
          this.form.c_goodsflextype = val.fit_goods_balance;
          this.form.c_gripno = val.fit_goods_grip_fk;
          this.form.c_goodsprice = val.fit_sale_price;
          this.form.c_goodsxgolfprice = val.fit_supply_price;
          this.form.c_goodsshopprice = val.fit_shop_price;

          this.form.c_clubflextype1 = val.fit_balance1;
          this.form.c_clubflextype1_ = val.fit_balance1;
          this.form.c_clubflextype2 = val.fit_balance2;
          this.form.c_clubflextype2_ = val.fit_balance2;
          this.form.c_clubflextype3 = val.fit_balance3;
          this.form.c_clubflextype3_ = val.fit_balance3;
          this.form.c_clubflextype4 = val.fit_balance4;
          this.form.c_clubflextype4_ = val.fit_balance4;
          this.form.c_clubflextype5 = val.fit_balance5;
          this.form.c_clubflextype5_ = val.fit_balance5;

          val.ansclublist.forEach((val_) => {
            this.form.c_clubnotype.push(val_.fit_club_nm);
            this.form.c_clubloft.push(val_.fit_club_loft);
            this.form.c_clublie.push(val_.fit_club_lie);
            this.form.c_clubheadvolume.push(val_.fit_club_headvolume);
            this.form.c_clubsole.push(val_.fit_club_sol);
            this.form.c_clubfp.push(val_.fit_club_fp);
            this.form.c_clublength.push(val_.fit_club_length);
            this.form.c_clubweight.push(val_.fit_club_weight);
            this.form.c_clubflextitle1.push(val_.fit_club_balance1);
            this.form.c_clubflextitle2.push(val_.fit_club_balance2);
            this.form.c_clubflextitle3.push(val_.fit_club_balance3);
            this.form.c_clubflextitle4.push(val_.fit_club_balance4);
            this.form.c_clubflextitle5.push(val_.fit_club_balance5);
          });
        }
      });

      this.$refs["ansresultModal"].hide();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    print() {
      window.print();
    },
  },
  watch: {
    "setad.goodstype": function () {
      this.get_adlist();
    },
    "setad.title": function () {
      this.get_adlist();
    },

    "form.distancegrade": function () {
      this.radardata.datasets[0].data = [
        this.form.distancegrade,
        this.form.directiongrade,
        this.form.accuracygrade,
        this.form.positiongrade,
        this.form.impactgrade,
      ];
    },
    "form.directiongrade": function () {
      this.radardata.datasets[0].data = [
        this.form.distancegrade,
        this.form.directiongrade,
        this.form.accuracygrade,
        this.form.positiongrade,
        this.form.impactgrade,
      ];
    },
    "form.accuracygrade": function () {
      this.radardata.datasets[0].data = [
        this.form.distancegrade,
        this.form.directiongrade,
        this.form.accuracygrade,
        this.form.positiongrade,
        this.form.impactgrade,
      ];
    },
    "form.positiongrade": function () {
      this.radardata.datasets[0].data = [
        this.form.distancegrade,
        this.form.directiongrade,
        this.form.accuracygrade,
        this.form.positiongrade,
        this.form.impactgrade,
      ];
    },
    "form.impactgrade": function () {
      this.radardata.datasets[0].data = [
        this.form.distancegrade,
        this.form.directiongrade,
        this.form.accuracygrade,
        this.form.positiongrade,
        this.form.impactgrade,
      ];
    },

    "$route.query.page": function () {
      this.ansresult.pageno = parseInt(this.$route.query.page) || 1;
      this.get_ansresultlist();
    },

    "form.p_goodsno": function (newValue, oldValue) {
      console.log("newValue", newValue);
      console.log("oldValue", oldValue);

      if (this.watching) {
        console.log(
          "this.fittingreqinfo.state_cd",
          this.fittingreqinfo.state_cd
        );

        // this.form.p_goodsflextype = "";

        ax.get_goodssublist(this.form.p_goodsno, (flag, data) => {
          if (flag) {
            this.p_shaftitems = [];
            this.p_gripitems = [];
            this.p_clubitems = [];
            this.p_item = {};

            this.p_shaftitems = data.shaftlist;
            this.p_gripitems = data.griplist;
            this.p_clubitems = data.clublist;

            if (
              this.fittingreqinfo.state_cd != 0 &&
              oldValue &&
              oldValue == 0
            ) {
              return false;
            }

            this.form.p_clubnotype = [];
            this.form.p_clubloft = [];
            this.form.p_clublie = [];
            this.form.p_clubheadvolume = [];
            this.form.p_clubsole = [];
            this.form.p_clubfp = [];
            this.form.p_clublength = [];
            this.form.p_clubweight = [];
            this.form.p_clubflextype1 = 0;
            this.form.p_clubflextype1_ = 0;
            this.form.p_clubflextitle1 = [];
            this.form.p_clubflextype2 = 0;
            this.form.p_clubflextype2_ = 0;
            this.form.p_clubflextitle2 = [];
            this.form.p_clubflextype3 = 0;
            this.form.p_clubflextype3_ = 0;
            this.form.p_clubflextitle3 = [];
            this.form.p_clubflextype4 = 0;
            this.form.p_clubflextype4_ = 0;
            this.form.p_clubflextitle4 = [];
            this.form.p_clubflextype5 = 0;
            this.form.p_clubflextype5_ = 0;
            this.form.p_clubflextitle5 = [];

            this.form.p_shaftno = 0;
            this.form.p_gripno = 0;
            this.form.p_goodsprice = 0;
            this.form.p_goodsxgolfprice = 0;
            this.form.p_goodsshopprice = 0;

            // this.form.p_goodsflextype = "";
            this.p_item = this.goodsitems.find(
              (v) => v.fit_goods_pk == this.form.p_goodsno
            );

            this.p_clubitems.forEach((val) => {
              this.form.p_clubnotype.push(val.fit_club_nm);
              this.form.p_clubloft.push(val.fit_club_loft);
              this.form.p_clublie.push(val.fit_club_lie);
              this.form.p_clubheadvolume.push(val.fit_club_headvolume);
              this.form.p_clubsole.push(val.fit_club_sol);
              this.form.p_clubfp.push(val.fit_club_fp);
              this.form.p_clublength.push(val.fit_club_length);
              this.form.p_clubweight.push(val.fit_club_weight);
              this.form.p_clubflextype1 = this.p_item.fit_balance1 || 0;
              this.form.p_clubflextype1_ = this.p_item.fit_balance1 || 0;
              this.form.p_clubflextitle1.push(val.fit_club_balance1);
              this.form.p_clubflextype2 = this.p_item.fit_balance2 || 0;
              this.form.p_clubflextype2_ = this.p_item.fit_balance2 || 0;
              this.form.p_clubflextitle2.push(val.fit_club_balance2);
              this.form.p_clubflextype3 = this.p_item.fit_balance3 || 0;
              this.form.p_clubflextype3_ = this.p_item.fit_balance3 || 0;
              this.form.p_clubflextitle3.push(val.fit_club_balance3);
              this.form.p_clubflextype4 = this.p_item.fit_balance4 || 0;
              this.form.p_clubflextype4_ = this.p_item.fit_balance4 || 0;
              this.form.p_clubflextitle4.push(val.fit_club_balance4);
              this.form.p_clubflextype5 = this.p_item.fit_balance5 || 0;
              this.form.p_clubflextype5_ = this.p_item.fit_balance5 || 0;
              this.form.p_clubflextitle5.push(val.fit_club_balance5);
            });

            this.form.p_shaftno = this.p_item.fit_goods_shaft_fk;
            this.form.p_gripno = this.p_item.fit_goods_grip_fk;
            this.form.p_goodsprice =
              parseInt(this.p_item.fit_sale_price || 0) +
              parseInt(
                (this.p_shaftitems.find(
                  (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
                ) &&
                  this.p_shaftitems.find(
                    (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
                  ).fit_shaft_price) ||
                  0
              ) +
              parseInt(
                (this.p_gripitems.find(
                  (v) => v.fit_goods_grip_pk == this.form.p_gripno
                ) &&
                  this.p_gripitems.find(
                    (v) => v.fit_goods_grip_pk == this.form.p_gripno
                  ).fit_grip_price) ||
                  0
              );

            this.form.p_goodsxgolfprice =
              parseInt(this.p_item.fit_supply_price || 0) +
              parseInt(
                (this.p_shaftitems.find(
                  (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
                ) &&
                  this.p_shaftitems.find(
                    (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
                  ).fit_shaft_price) ||
                  0
              ) +
              parseInt(
                (this.p_gripitems.find(
                  (v) => v.fit_goods_grip_pk == this.form.p_gripno
                ) &&
                  this.p_gripitems.find(
                    (v) => v.fit_goods_grip_pk == this.form.p_gripno
                  ).fit_grip_price) ||
                  0
              );

            this.form.p_goodsshopprice =
              parseInt(this.p_item.fit_shop_price || 0) +
              parseInt(
                (this.p_shaftitems.find(
                  (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
                ) &&
                  this.p_shaftitems.find(
                    (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
                  ).fit_shaft_price) ||
                  0
              ) +
              parseInt(
                (this.p_gripitems.find(
                  (v) => v.fit_goods_grip_pk == this.form.p_gripno
                ) &&
                  this.p_gripitems.find(
                    (v) => v.fit_goods_grip_pk == this.form.p_gripno
                  ).fit_grip_price) ||
                  0
              );

            // this.form.p_goodsflextype = this.p_shaftitems.find(
            //   (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
            // ).fit_goods_shaft_balance;
          }
        });
      }
    },
    "form.p_shaftno": function (newValue, oldValue) {
      if (this.fittingreqinfo.state_cd != 0 && oldValue && oldValue == 0) {
        return false;
      }
      const newItem = this.p_shaftitems.find(
        (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
      );

      // this.form.p_goodsflextype =
      //   (newItem && newItem.fit_goods_shaft_balance) || "";
      // console.log("aaaa", this.form.p_goodsflextype);
      this.form.p_goodsprice =
        parseInt(this.p_item.fit_sale_price) +
        parseInt((newItem && newItem.fit_shaft_price) || 0) +
        parseInt(
          (this.p_gripitems.find(
            (v) => v.fit_goods_grip_pk == this.form.p_gripno
          ) &&
            this.p_gripitems.find(
              (v) => v.fit_goods_grip_pk == this.form.p_gripno
            ).fit_grip_price) ||
            0
        );

      this.form.p_goodsxgolfprice =
        parseInt(this.p_item.fit_supply_price) +
        parseInt((newItem && newItem.fit_shaft_price) || 0) +
        parseInt(
          (this.p_gripitems.find(
            (v) => v.fit_goods_grip_pk == this.form.p_gripno
          ) &&
            this.p_gripitems.find(
              (v) => v.fit_goods_grip_pk == this.form.p_gripno
            ).fit_grip_price) ||
            0
        );

      this.form.p_goodsshopprice =
        parseInt(this.p_item.fit_shop_price) +
        parseInt((newItem && newItem.fit_shaft_price) || 0) +
        parseInt(
          (this.p_gripitems.find(
            (v) => v.fit_goods_grip_pk == this.form.p_gripno
          ) &&
            this.p_gripitems.find(
              (v) => v.fit_goods_grip_pk == this.form.p_gripno
            ).fit_grip_price) ||
            0
        );
    },
    "form.p_gripno": function (newValue, oldValue) {
      if (this.fittingreqinfo.state_cd != 0 && oldValue && oldValue == 0) {
        return false;
      }

      const newItem = this.p_gripitems.find(
        (v) => v.fit_goods_grip_pk == this.form.p_gripno
      );

      this.form.p_goodsprice =
        parseInt(this.p_item.fit_sale_price || 0) +
        parseInt(
          (this.p_shaftitems.find(
            (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
          ) &&
            this.p_shaftitems.find(
              (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
            ).fit_shaft_price) ||
            0
        ) +
        parseInt((newItem && newItem.fit_grip_price) || 0);

      this.form.p_goodsxgolfprice =
        parseInt(this.p_item.fit_supply_price || 0) +
        parseInt(
          (this.p_shaftitems.find(
            (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
          ) &&
            this.p_shaftitems.find(
              (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
            ).fit_shaft_price) ||
            0
        ) +
        parseInt((newItem && newItem.fit_grip_price) || 0);

      this.form.p_goodsshopprice =
        parseInt(this.p_item.fit_shop_price || 0) +
        parseInt(
          (this.p_shaftitems.find(
            (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
          ) &&
            this.p_shaftitems.find(
              (v) => v.fit_goods_shaft_pk == this.form.p_shaftno
            ).fit_shaft_price) ||
            0
        ) +
        parseInt((newItem && newItem.fit_grip_price) || 0);
    },

    "form.c_goodsno": function (newValue, oldValue) {
      if (this.watching) {
        ax.get_goodssublist(this.form.c_goodsno, (flag, data) => {
          if (flag) {
            this.c_shaftitems = [];
            this.c_gripitems = [];
            this.c_clubitems = [];
            this.c_item = {};
            this.c_shaftitems = data.shaftlist;
            this.c_gripitems = data.griplist;
            this.c_clubitems = data.clublist;

            if (
              this.fittingreqinfo.state_cd != 0 &&
              oldValue &&
              oldValue == 0
            ) {
              return false;
            }
            this.form.c_clubnotype = [];
            this.form.c_clubloft = [];
            this.form.c_clublie = [];
            this.form.c_clubheadvolume = [];
            this.form.c_clubsole = [];
            this.form.c_clubfp = [];
            this.form.c_clublength = [];
            this.form.c_clubweight = [];
            this.form.c_clubflextype1 = 0;
            this.form.c_clubflextype1_ = 0;
            this.form.c_clubflextitle1 = [];
            this.form.c_clubflextype2 = 0;
            this.form.c_clubflextype2_ = 0;
            this.form.c_clubflextitle2 = [];
            this.form.c_clubflextype3 = 0;
            this.form.c_clubflextype3_ = 0;
            this.form.c_clubflextitle3 = [];
            this.form.c_clubflextype4 = 0;
            this.form.c_clubflextype4_ = 0;
            this.form.c_clubflextitle4 = [];
            this.form.c_clubflextype5 = 0;
            this.form.c_clubflextype5_ = 0;
            this.form.c_clubflextitle5 = [];

            this.form.c_shaftno = 0;
            this.form.c_gripno = 0;
            this.form.c_goodsprice = 0;
            this.form.c_goodsxgolfprice = 0;
            this.form.c_goodsshopprice = 0;

            // this.form.c_goodsflextype = "";
            this.c_item = this.goodsitems.find(
              (v) => v.fit_goods_pk == this.form.c_goodsno
            );

            this.c_clubitems.forEach((val) => {
              this.form.c_clubnotype.push(val.fit_club_nm);
              this.form.c_clubloft.push(val.fit_club_loft);
              this.form.c_clublie.push(val.fit_club_lie);
              this.form.c_clubheadvolume.push(val.fit_club_headvolume);
              this.form.c_clubsole.push(val.fit_club_sol);
              this.form.c_clubfp.push(val.fit_club_fp);
              this.form.c_clublength.push(val.fit_club_length);
              this.form.c_clubweight.push(val.fit_club_weight);
              this.form.c_clubflextype1 = this.c_item.fit_balance1 || 0;
              this.form.c_clubflextype1_ = this.c_item.fit_balance1 || 0;
              this.form.c_clubflextitle1.push(val.fit_club_balance1);
              this.form.c_clubflextype2 = this.c_item.fit_balance2 || 0;
              this.form.c_clubflextype2_ = this.c_item.fit_balance2 || 0;
              this.form.c_clubflextitle2.push(val.fit_club_balance2);
              this.form.c_clubflextype3 = this.c_item.fit_balance3 || 0;
              this.form.c_clubflextype3_ = this.c_item.fit_balance3 || 0;
              this.form.c_clubflextitle3.push(val.fit_club_balance3);
              this.form.c_clubflextype4 = this.c_item.fit_balance4 || 0;
              this.form.c_clubflextype4_ = this.c_item.fit_balance4 || 0;
              this.form.c_clubflextitle4.push(val.fit_club_balance4);
              this.form.c_clubflextype5 = this.c_item.fit_balance5 || 0;
              this.form.c_clubflextype5_ = this.c_item.fit_balance5 || 0;
              this.form.c_clubflextitle5.push(val.fit_club_balance5);
            });

            this.form.c_shaftno = this.c_item.fit_goods_shaft_fk;
            this.form.c_gripno = this.c_item.fit_goods_grip_fk;
            this.form.c_goodsprice =
              parseInt(this.c_item.fit_sale_price) +
              parseInt(
                (this.c_shaftitems.find(
                  (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
                ) &&
                  this.c_shaftitems.find(
                    (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
                  ).fit_shaft_price) ||
                  0
              ) +
              parseInt(
                (this.c_gripitems.find(
                  (v) => v.fit_goods_grip_pk == this.form.c_gripno
                ) &&
                  this.c_gripitems.find(
                    (v) => v.fit_goods_grip_pk == this.form.c_gripno
                  ).fit_grip_price) ||
                  0
              );

            this.form.c_goodsxgolfprice =
              parseInt(this.c_item.fit_supply_price) +
              parseInt(
                (this.c_shaftitems.find(
                  (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
                ) &&
                  this.c_shaftitems.find(
                    (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
                  ).fit_shaft_price) ||
                  0
              ) +
              parseInt(
                (this.c_gripitems.find(
                  (v) => v.fit_goods_grip_pk == this.form.c_gripno
                ) &&
                  this.c_gripitems.find(
                    (v) => v.fit_goods_grip_pk == this.form.c_gripno
                  ).fit_grip_price) ||
                  0
              );

            this.form.c_goodsshopprice =
              parseInt(this.c_item.fit_shop_price) +
              parseInt(
                (this.c_shaftitems.find(
                  (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
                ) &&
                  this.c_shaftitems.find(
                    (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
                  ).fit_shaft_price) ||
                  0
              ) +
              parseInt(
                (this.c_gripitems.find(
                  (v) => v.fit_goods_grip_pk == this.form.c_gripno
                ) &&
                  this.c_gripitems.find(
                    (v) => v.fit_goods_grip_pk == this.form.c_gripno
                  ).fit_grip_price) ||
                  0
              );

            // this.form.c_goodsflextype = this.c_shaftitems.find(
            //   (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
            // ).fit_shaft_material;
          }
        });
      }
    },
    "form.c_shaftno": function (newValue, oldValue) {
      if (this.fittingreqinfo.state_cd != 0 && oldValue && oldValue == 0) {
        return false;
      }
      const newItem = this.c_shaftitems.find(
        (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
      );

      // this.form.c_goodsflextype =
      //   (newItem && newItem.fit_shaft_material) || "";
      this.form.c_goodsprice =
        parseInt(this.c_item.fit_sale_price || 0) +
        parseInt((newItem && newItem.fit_shaft_price) || 0) +
        parseInt(
          (this.c_gripitems.find(
            (v) => v.fit_goods_grip_pk == this.form.c_gripno
          ) &&
            this.c_gripitems.find(
              (v) => v.fit_goods_grip_pk == this.form.c_gripno
            ).fit_grip_price) ||
            0
        );

      this.form.c_goodsxgolfprice =
        parseInt(this.c_item.fit_supply_price || 0) +
        parseInt((newItem && newItem.fit_shaft_price) || 0) +
        parseInt(
          (this.c_gripitems.find(
            (v) => v.fit_goods_grip_pk == this.form.c_gripno
          ) &&
            this.c_gripitems.find(
              (v) => v.fit_goods_grip_pk == this.form.c_gripno
            ).fit_grip_price) ||
            0
        );

      this.form.c_goodsshopprice =
        parseInt(this.c_item.fit_shop_price || 0) +
        parseInt((newItem && newItem.fit_shaft_price) || 0) +
        parseInt(
          (this.c_gripitems.find(
            (v) => v.fit_goods_grip_pk == this.form.c_gripno
          ) &&
            this.c_gripitems.find(
              (v) => v.fit_goods_grip_pk == this.form.c_gripno
            ).fit_grip_price) ||
            0
        );
    },
    "form.c_gripno": function (newValue, oldValue) {
      if (this.fittingreqinfo.state_cd != 0 && oldValue && oldValue == 0) {
        return false;
      }
      const newItem = this.c_gripitems.find(
        (v) => v.fit_goods_grip_pk == this.form.c_gripno
      );

      this.form.c_goodsprice =
        parseInt(this.c_item.fit_sale_price || 0) +
        parseInt(
          (this.c_shaftitems.find(
            (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
          ) &&
            this.c_shaftitems.find(
              (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
            ).fit_shaft_price) ||
            0
        ) +
        parseInt((newItem && newItem.fit_grip_price) || 0);

      this.form.c_goodsxgolfprice =
        parseInt(this.c_item.fit_supply_price || 0) +
        parseInt(
          (this.c_shaftitems.find(
            (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
          ) &&
            this.c_shaftitems.find(
              (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
            ).fit_shaft_price) ||
            0
        ) +
        parseInt((newItem && newItem.fit_grip_price) || 0);

      this.form.c_goodsshopprice =
        parseInt(this.c_item.fit_shop_price || 0) +
        parseInt(
          (this.c_shaftitems.find(
            (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
          ) &&
            this.c_shaftitems.find(
              (v) => v.fit_goods_shaft_pk == this.form.c_shaftno
            ).fit_shaft_price) ||
            0
        ) +
        parseInt((newItem && newItem.fit_grip_price) || 0);
    },
  },
};
</script>
