var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-main"},[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: 0,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab == '0' }},[_c('div',{on:{"click":function($event){_vm.$router
            .push({
              name: 'FittingSaleList',
              query: { goodstype: '0' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.total"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 1 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push({
              name: 'FittingSaleList',
              query: { goodstype: 1 },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.driver"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 2 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push({
              name: 'FittingSaleList',
              query: { goodstype: 2 },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.wood"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 3 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push({
              name: 'FittingSaleList',
              query: { goodstype: 3 },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.utility"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 4 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push({
              name: 'FittingSaleList',
              query: { goodstype: 4 },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.iron"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 5 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push({
              name: 'FittingSaleList',
              query: { goodstype: 5 },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.wedge"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 6 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push({
              name: 'FittingSaleList',
              query: { goodstype: 6 },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.putter"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }